import { IdentifierDTO } from '@zazume/zzm-base';

export const InvestmentOpportunityKeys = {
  base: ['investmentOpportunity'],
  byPagination: (minAmount?: string, maxAmount?: string, minProfitability?: string, cityId?: IdentifierDTO) => [
    ...InvestmentOpportunityKeys.base,
    minAmount ? `min_a_${minAmount}` : '',
    maxAmount ? `max_a_${maxAmount}` : '',
    minProfitability ? `mp_${minProfitability}` : '',
    cityId ? `c_${cityId}` : ''
  ].filter(Boolean) ?? [],
  byId: (valuationId: IdentifierDTO) => [...InvestmentOpportunityKeys.base, valuationId],
  activeBooking: () =>
    [...InvestmentOpportunityKeys.base, 'service-type-booking']
};
