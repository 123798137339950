export const isProductionEnvironment = import.meta.env.REACT_APP_ENV === 'production';
const isStageEnvironment = import.meta.env.REACT_APP_VERSION === 'stage';
const isTestEnvironment = import.meta.env.REACT_APP_VERSION === 'test' || import.meta.env.TEST;
export const isLocalEnvironment = !isProductionEnvironment && !isStageEnvironment && !isTestEnvironment;

type Environment = 'stage' | 'local' | 'sandbox' | 'production' | 'test';

export const isSegmentEnabled = () => 'production' === getEnvironment();
export const isGoogleAnalyticsEnabled = () => 'production' === getEnvironment();
export const isSentryEnabled = () => ['stage', 'production'].includes(getEnvironment());
export const isHotjarEnabled = () => 'production' === getEnvironment();
export const isAlgoliaEnabled = () => ['local', 'stage', 'production'].includes(getEnvironment());

const getAPIBaseURL = (): string => {
  if (import.meta.env.REACT_APP_PUBLIC_API_URL) {
    return import.meta.env.REACT_APP_PUBLIC_API_URL;
  }

  if (import.meta.env.REACT_APP_VERSION === 'production') {
    return 'https://api.zazume.com';
  }

  if (import.meta.env.REACT_APP_VERSION === 'development') {
    return 'https://api-staging.zazume.com';
  }

  return 'http://localhost:5000';
};

const getGoogleMapsApiKey = (): string => {
  return import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
};

export const getEnvironment = (): Environment => {
  const env = import.meta.env.REACT_APP_ENV || 'local';
  switch (env) {
    case 'development':
      return 'stage';
    case 'production':
      return 'production';
    case 'sandbox':
      return 'sandbox';
    case 'local':
    case 'test':
      return 'local';
    default:
      console.error('Unexpected REACT_APP_ENV', env);
      return 'local';
  }
};

export const Config = {
  API_URL: getAPIBaseURL(),
  GOOGLE_MAPS_API_KEY: getGoogleMapsApiKey(),
  SEGMENT_KEY: import.meta.env.REACT_APP_SEGMENT_DASHBOARD_KEY as string,
  TINYMCE_KEY: import.meta.env.REACT_APP_TINYMCE_KEY as string,
  ZAZUME_BRAND_ID: '64394709d16c6a2c00210ea7',
  PLATFORM: {
    ACTOR_ID: '000000000000000000000000'
  },
  UNIT_ID_WILDCARD: import.meta.env.REACT_APP_UNIT_ID_WILDCARD as string || '6734c156b76c82000f94f6ec',
  PRODUCTS_EXTERNAL_ID: {
    PEER_TO_PEER_PLAN: import.meta.env.REACT_APP_PEER_TO_PEER_PLAN_PRICE_ID as string || 'price_1N8s9VG9NH1yPruxmNuCtxJy',
    REAL_ESTATE_OPPORTUNITIES_PRICE_ID: import.meta.env.REACT_APP_REAL_ESTATE_OPPORTUNITIES_PRICE_ID as string || 'price_1QK1ciG9NH1yPruxtQCW8AAN'
  },
  OWNERS_TOP_BANNER_LINK: import.meta.env.REACT_APP_OWNERS_TOP_BANNER_LINK
};
