export default {
  accounting: {
    actions: {
      sendReminder: 'Send reminder',
      sendReminderSuccess: 'Reminder sent with success'
    },
    amount: {
      paid: 'Paid',
      pending: 'Pending',
      pendingLong: 'Amount pending to pay'
    },
    attachments: {
      help: 'Here you can attach a bank transfer slip, invoice or any other files refered to this expense.'
    },
    beneficiary: 'Beneficiary',
    beneficiaryPlaceholder: 'Chose who will receive the money',
    beneficiaryTypes: {
      organization: 'Organization',
      owner: 'Owner',
      platform: 'Platform',
      supplier: 'Provider',
      tenant: 'Tenant'
    },
    breakdown: {
      amount: 'Amount',
      discount: 'Discount {{discountConcept}} ({{discount}})',
      title: 'Breakdown'
    },
    delete: {
      expense: {
        generic: 'This expense cannot be deleted.',
        linkedToInvoice: 'This expense cannot be deleted because there is a linked invoice.',
        linkedToPayment: 'This expense cannot be deleted because there is a reconciliation payment linked. To delete this expense you should remove all the reconciliations. No worries, they won\'t dissapear from your bank account, you will be able to attach them again.'
      },
      income: {
        attributed: 'This income comes from a attributed expense. If you want to delete the income you must go to the expense details to delete both.',
        generic: 'This income cannot be delete.',
        linkedToInvoice: 'This income cannot be deleted because there is a linked invoice.',
        recurrent: 'This income cannot be deleted because there is a reconciliation payment linked. To delete this income you should remove all the reconciliations. No worries, they won\'t dissapear from your bank account, you will be able to attach them again.'
      },
      linkedPayoutWarning: 'This {{accountingType}} cannot be deleted because it has been included in a pay-out.',
      recurrentLeaseWarning: 'This {{accountingType}} cannot be deleted because it\'s an automatic {{accountingType}} created from a contract. The only way to delete it is by breaking the contract.',
      recurrentWarning: 'This {{accountingType}} cannot be deleted because it has been created from a recurrent {{accountingType}}. To delete it you must modify the order on the recurrent {{accountingType}} page.'
    },
    details: {
      excluded: 'Excluded',
      ownerVisibility: 'Owner visibility'
    },
    discount: {
      addDiscount: 'Add discount',
      addModal: {
        acceptButton: 'Apply discount',
        amount: 'Select and specify the discount amount',
        howManyTimes: 'How many times do you want to apply this discount?',
        messages: {
          add: 'There is no discount applied, you can add it from the recurrence detail options',
          remove: 'There is a discount applied, you can eliminate it from the recurrence detail options'
        },
        numberOfTimes: 'Select a custom number of times',
        startAt: 'Choose in which payment this discount should start to be applied',
        title: 'Add discount',
        toast: 'Discount added successfully'
      },
      discount: 'Discounts',
      nextChargeModified: 'This amount has been modified from the "Next charge" section of the recurrence edition',
      removeConfirmationModal: {
        actionButton: 'Yes, remove discount',
        description: 'By removing the discount, the next charges where the discount is applied will be modified. This action can not be undone.',
        title: 'Are you sure you want to remove this discount?',
        toast: 'Discount removed successfully'
      },
      removeDiscount: 'Remove discount',
      subtitleBreakdown: 'Rest of {{time}}',
      timesOptions: {
        always: 'Always',
        custom: 'Custom times',
        once: 'Only once',
        thrice: 'Three times',
        twice: 'Twice'
      },
      withDiscount: 'With discount',
      withoutDiscount: 'Without discount'
    },
    edit: {
      expense: {
        generic: 'This income cannot be edited.'
      },
      income: {
        attributed: 'This income comes from a attributed expense. If you want to edit the income you must go to the expense details to edit both.',
        cancelledState: 'This income cannot be edited because it has been cancelled.',
        generic: 'This income cannot be edited.',
        recurrent: 'This income cannot be edited because there is a reconciliation payment linked. To edit this payment you should remove all the reconciliations. No worries, they won’t disappear from your bank account, you will be able to attach them again.'
      },
      linkedPayoutWarning: 'This {{accountingType}} cannot be edited because it has been included in a pay-out.',
      recurrentLeaseWarning: 'This {{accountingType}} cannot be edited because it\'s an automatic {{accountingType}} created from a contract. The only way to edit it is by creating a new contract.',
      recurrentWarning: 'This {{accountingType}} cannot be edited because it has been created from a recurrent {{accountingType}}. To edit it you must modify the order on the recurrent {{accountingType}} page.'
    },
    expenses: {
      delete: {
        impossibleToDeleteModal: {
          description: 'This expense cannot be deleted because there is created by a recurrent expense.',
          descriptionLinkedIncome: 'There is an associated income that has already been paid. If you want to delete the expense you must first delete the income reconciliation to be able to delete it.',
          title: 'The expense could not be eliminated'
        },
        linkedIncomeWarning: {
          description: 'There is a not paid income associated to this expense. Deleting this expense, the associated income will be also deteled.  We will also cancel the payment request sending an email to the payer. This action can\'t be undone.',
          title: 'Delete expense and income associated'
        }
      }
    },
    forms: {
      chooseExistentProvider: 'Choose an existing provider',
      chooseProvider: 'Choose a provider',
      discount: 'Discount',
      errors: {
        discountPercentage: 'The discount percentage must be between 0 and 100',
        discountTooHigh: 'Discount cannot be higher than the Amount value'

      },
      excludedFromPayout: 'Exclude this {{entity}} from pay-outs',
      ownerVisibilityLabel: 'Exclude this {{entity}} from owner accounting',
      searchProvider: 'Search provider',
      whichProvider: 'Which provider?',
      whichTenant: 'Which tenant?'
    },
    payer: 'Payer',
    payerPlaceholder: 'Choose who will pay this expense',
    payerTypes: {
      organization: 'Organization',
      owner: 'Owner',
      platform: 'Platform'
    },
    tabs: {
      expenses: 'Expenses',
      income: 'Incomes',
      payouts: 'Pay-outs',
      rentAdvance: 'Rent advance'
    },
    title: 'Accounting'
  },
  activityAndComments: {
    activity: 'Activity',
    comments: 'Comments',
    inputPlaceholder: 'Add a comment',
    title: 'Updates'
  },
  agents: {
    common: {
      agentCommission: 'Agent commission',
      billingData: 'Billing data',
      company: 'Company',
      details: 'Personal details',
      idNumber: 'CIF / ID Number',
      invitationAccepted: 'Invitation accepted',
      invitationPending: 'Invitation pending',
      invitationResend: 'Resend invite',
      invitationSent: 'Invitation sent',
      publicName: 'Public name',
      serviceArea: 'Service area',
      serviceAreaHelper: 'Add service area by pressing enter or using commas'
    },
    detail: {
      edit: 'Edit agent',
      title: 'Agent details'
    },
    modals: {
      add: {
        addButton: 'Add agent',
        services: 'Services offered',
        title: 'Add new agent'
      },
      remove: {
        description: 'This will remove the selected agent permanently. This action can\'t be undone.',
        successMessage: 'Agent removed successfully',
        title: 'Remove agent?'
      }
    },
    table: {
      add: 'Add agent',
      emptyState: 'No agents added yet',
      filters: {
        serviceArea: 'Service Area'
      },
      headers: {
        agent: 'Agent',
        email: 'Email address',
        phoneNumber: 'Phone number',
        serviceArea: 'Service area'
      }
    },
    title: 'Agents'
  },
  application: {
    endingDate: 'Ending date of the contract',
    maxMonthsExtension: 'Max extension (months)',
    monthsExtension: 'Extension (months)',
    notifyEndLeaseExtensionMonths: 'End of extension (months)',
    notifyEndLeaseMonths: 'End of contract (months)',
    startingDate: 'Starting date of the contract',
    withEndLeaseNotifications: 'Notify end of contract (and extension)',
    withLeaseExtension: 'Add extension to the contract'
  },
  archived: {
    detail: {
      title: 'Application details'
    },
    option: 'Archive',
    title: 'Archived Applications',
    toast: {
      archive: 'Application archived successfully',
      delete: 'Application deleted successfully'
    }
  },
  asideNavigation: {
    admin: 'Admin',
    bookings: 'Bookings',
    buildings: 'Buildings',
    calendar: 'Calendar',
    commercialisation: 'Commercialisation',
    dashboard: 'Home',
    documents: 'Documents',
    facilities: 'Facilities',
    incidents: 'Incidents',
    investment: 'Investment',
    management: 'Management',
    marketPlace: 'Marketplace',
    myProperties: 'My properties',
    onboarding: 'Applications',
    organization: 'Organization',
    organizationServices: 'Organization services',
    organizationSettings: 'Organization settings',
    ownerAccounts: 'Owner Accounts',
    ownerInvitations: 'Earn 100€',
    people: 'People',
    portfolio: 'Portfolio',
    properties: 'Properties',
    services: 'Services',
    settings: 'Settings',
    staff: 'Staff',
    taskManager: 'Task manager',
    tasks: 'Tasks',
    team: 'Team',
    templates: 'Templates',
    tenants: 'Tenants',
    units: 'Units',
    valuations: 'Valuations',
    workspaces: 'Workspaces'
  },
  assignation: {
    chooseRole: 'Choose a role',
    whichRole: 'Which role?'
  },
  auth: {
    backLogin: 'Back to Login',
    confirmPassword: 'Confirm password',
    createPassword: 'Create a password',
    forgot: 'Forgot password?',
    forgotPage: {
      success: 'We have sent a link to reset your password to {{email}}',
      title: 'Enter your email address and we will send you a password reset link'
    },
    newPassword: 'New password',
    password: 'Minimum 8 characters with uppercase and lowercase letters, and at least one number',
    privacy: 'the Privacy Policy',
    resetPassword: 'Reset your password',
    resetPasswordSuccess: 'Your password has been updated',
    signIn: 'Sign in',
    termsAgree: 'I\'ve read and accept the',
    termsAndConditions: 'Terms and Conditions'
  },
  autoReconciliations: {
    cancel: 'Cancel auto-reconciliation',
    cancelDescription: 'We\'ll stop auto-reconciling this bank movement pattern from now on. This won\'t unlink already reconciled payments.',
    canceled: 'Auto-reconciliation canceled with success',
    debtor: 'Debtor',
    emptyState: 'No auto-reconciliations created yet.',
    pageTitle: 'Auto-reconcilied payments setted up',
    title: 'Auto-reconciliation'
  },
  availability: {
    add: 'Add availability',
    agent: {
      title: 'Who will make the visit'
    },
    applicantsRequirement: 'Viewings will be only available for...',
    applicantsRequirementDescriptions: {
      everyone: 'Receive all requests from people interested in your home. We recommend that you first publish with this option and then, if necessary, activate one of the following filters.',
      onlyQualified: 'Receive only visits from people who match your preferences. 70% of interested people complete this information before the visits.',
      onlyWithDocumentation: 'Only receive visits from people who has uploaded their documentation to verify their solvency before the visit. 40% of interested people complete this information before the visits.'
    },
    applicantsRequirementTypes: {
      everyone: 'Anyone interested in the property',
      onlyQualified: 'Applicants who meet the prequalification requirements',
      onlyWithDocumentation: 'Applicants who upload their solvency documents before viewing'
    },
    availableDays: 'Available days',
    availableSlots: 'Available slots',
    calendarRange: 'Calendar range',
    chooseDate: 'Choose a date',
    created: 'Availability created',
    duration: 'Duration',
    edit: 'Edit availability',
    errorModal: {
      message: 'Availability cannot be created for a published unit without first setting up the applicant\'s qualification data. Please fill out this information in the \'About\' tab to create availability',
      title: 'Availability cannot be created'
    },
    errors: {
      invalid: 'Invalid period',
      overlap: 'Period overlaps with another',
      short: 'Period is shorter than duration'
    },
    forms: {
      selectSlot: 'Select an empty slot to schedule your viewing.',
      slotsCount: '{{count}} slot',
      slotsCount_other: '{{count}} slots',
      whichDay: 'Which day?',
      whichHour: 'At which hour?'
    },
    hideAvailability: 'Hide availability',
    isOnlyEnableForQualifiedTenants: {
      description: 'Only qualified tenants are allowed to schedule a viewing',
      title: 'Only available for qualified tenants'
    },
    noticePeriod: 'Notice period',
    period: {
      from: 'From',
      to: 'To'
    },
    periodRange: 'Which period do you want to show?',
    saveDetails: 'Save details',
    showAvailability: 'Show availability',
    simultaneousViewings: 'Simultaneous viewings',
    startingOn: 'Starting on',
    unavailable: 'Unavailable',
    unitTimeZone: 'Unit timezone:',
    updateDetails: 'Update details',
    updated: 'Availability updated',
    videoCalls: 'Video calls',
    viewingDetails: 'Viewing details',
    viewingsAvailability: 'Viewings availability',
    viewingsAvailabilityUpdate: 'Update viewings availability',
    viewingsSetClick: 'To set up availability for viewings',
    weekDays: {
      friday: 'Friday',
      monday: 'Monday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      thursday: 'Thursday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday'
    }
  },
  bankAccounts: {
    bankAccount: 'Bank account',
    bulkActions: {
      createReconciledIncomes: 'Create reconciled incomes',
      createReconciledIncomesModal: {
        notifications: {
          success: 'Reconciled incomes created successfully'
        },
        steps: {
          bankTransactions: {
            information: 'Select the bank transactions you want to convert into reconciled incomes and indicate which property they belong to.',
            title: 'Bank transactions'
          },
          incomeDetails: {
            excludedFromPayoutLabel: 'Exclude this expense from pay-outs',
            incomeTypeLabel: 'Income typology',
            incomeTypePlaceholder: 'Select a income type',
            information: 'In order to create the reconciled income from the selected bank transactions, you must fill in some details of the income to apply to everyone.',
            ownerVisibilityLabel: 'Exclude this income from owner accounting',
            providerLabel: 'What provider is the beneficiary of the income?',
            providerPlaceholder: 'Select an provider',
            title: 'Income details'
          },
          verification: {
            finishButton: 'Create {{total}} reconciled payments',
            information: 'Once you confirm the bank transactions and income details, incomes reconciled with the information you have completed will automatically be created.',
            table: {
              incomeType: 'Income type',
              provider: 'Beneficiary - Provider',
              title: 'Selected bank movements'
            },
            title: 'Verification'
          }
        }
      },
      createReconciledItems: 'Create reconciled payments',
      createReconciledPayment: 'Create reconciled expenses',
      createReconciledPaymentModal: {
        notifications: {
          success: 'Reconciled expenses created successfully'
        },
        steps: {
          bankTransactions: {
            errors: {
              notAllBankTransactionsSelected: 'In order to continue, each banking transaction must be associated with a property. If you can\'t find it or don\'t want to include it, you just have to deselect the bank transaction and you can continue.'
            },
            information: 'Select the bank transactions you want to convert into reconciled expenses and indicate which property they belong to.',
            table: {
              concept: 'Concept and date',
              selectAUnitPlaceholder: 'Select a unit'
            },
            title: 'Bank transactions'
          },
          expenseDetails: {
            excludedFromPayoutLabel: 'Exclude this expense from pay-outs',
            expenseTypeLabel: 'Expense typology',
            expenseTypePlaceholder: 'Select a payment type',
            information: 'In order to create the reconciled expense from the selected bank transactions, you must fill in some expense details to apply to everyone',
            ownerVisibilityLabel: 'Exclude this expense from owner accounting',
            providerLabel: 'What provider is the beneficiary of the expenses?',
            providerPlaceholder: 'Select an provider',
            title: 'Expense details'
          },

          verification: {
            finishButton: 'Create {{total}} reconciled payments',
            information: 'Once you confirm the bank transactions and expense details, expenses reconciled with the information you have completed will automatically be created.',
            table: {
              expenseType: 'Expense type',
              provider: 'Beneficiary - Provider',
              title: 'Selected bank movements'
            },
            title: 'Verification'
          }
        }
      },
      createReconciledPaymentTooltip: 'To create reconciled expenses or incomes you should use the payment type filter first',
      discardAll: 'Discard all'
    },
    concept: 'Concept',
    debtorOrCreditor: 'Debtor / Creditor',
    discardAllModal: {
      body: 'You are about to discard <1>{{count}} bank transaction</1>',
      body_other: 'You are about to discard <1>{{count}} bank transactions</1>',
      inputLabel: 'Write "{{count}}" to confirm',
      notificationSuccess: 'Bank transactions discarded successfully',
      title: 'Do you want to discard these bank transactions?'
    },
    discardModal: {
      actionButton: 'Discard',
      description: 'Are you sure you want to discard this bank transaction? This action can be undone.',
      title: 'Discard bank transaction'
    },
    discardToast: 'Bank transaction deleted successfully',
    emptyState: 'No transactions yet.',
    enableModal: {
      actionButton: 'Enable',
      description: 'Are you sure you want to enabled this bank transaction? This action can be undone.',
      title: 'Enable bank transaction'
    },
    enableToast: 'Bank transaction enabled successfully',
    messages: {
      emptyState: {
        admin1: 'There is no linked bank account. Connect it in',
        admin2: 'to view your bank movements.',
        pm: 'There is no linked bank account. Ask your admin to connect it to view your bank movements.'
      },
      expired: {
        admin1: '{{accountName}} credentials have expired. Please, go to',
        admin2: 'to resync them. Otherwise we cannot show you an updated view of your bank movements.',
        pm: '{{accountName}} credentials have expired. Please, contact with your admin to resync them. Otherwise we cannot show you an updated view of your bank movements.'
      },
      expiring: {
        admin1: '{{accountName}} credentials expire on {{daysCount}} days. Please, go to',
        admin2: 'to resync them. Otherwise we cannot show you an updated view of your bank movements.',
        pm: '{{accountName}} credentials expire on {{daysCount}} days. Please, contact with your admin to resync them. Otherwise we cannot show you an updated view of your bank movements.'
      }
    },
    reconciliation: {
      autoReconciliation: {
        title: 'Auto-reconcile future payments',
        tooltip: 'Payments like this with same characteristics (amount, concept, payment type, emisor) will be automatically reconciliated'
      },
      checkboxLabel: 'I confirm the partial reconciliation of the transaction. This will imply a mismatch between the platform\'s accounting and the bank balance',
      coveredByInsuranceTooltip: 'Mark this option to confirm that the payment has been covered by the insurance company',
      cta: 'Reconcile payment',
      descriptionStep1: 'Select a payment to link to the chosen bank movement',
      descriptionStep2: {
        line1: 'Do you want to link this bank movement...',
        line2: '... to this payment?',
        line2_plural: '... to these payments?'
      },
      filters: {
        endDate: 'End date',
        startDate: 'Start date'
      },
      messages: {
        bankTransacionPartiallyPaid: 'Bank movement doesn\'t fit with the total amounts of this payment. Complete it adding more payments or confirm the action selecting the checkbox.',
        exceedAmount: 'Bank movement is not enough to cover all the payments. Deselect some payments to proceed.',
        itemsBelongToDifferentUnits: 'Payments selected belong to different units. They must belong to the same unit.',
        paid: 'Congrats!  This bank movement cover the total amount of this payment. Your status will be paid ',
        partiallyPaid: 'Bank movement doesn\'t fit with the total amount of this payment. Your status will be partially paid',
        rightPartiallyPaid: 'Bank movement doesn\'t fit with the total amount of this payment. Your status will be partially paid',
        wrongPartiallyPaid: 'Bank movement doesn\'t fit with the total amounts of these payments. Reconcile it manually.'
      },
      table: {
        headers: {
          amount: 'Amount',
          concept: 'Concept',
          date: 'Date',
          status: 'Status',
          unit: 'Unit ID'
        },
        selectedRows: {
          plural: 'pending payments selected',
          singular: 'pending payment selected'
        }
      },
      titleStep1: 'Pending payments',
      titleStep2: 'Reconcile payments',
      types: {
        expense: 'Expense',
        payment: 'Income',
        payout: 'Pay-out'
      }
    },
    statusFilter: {
      pending: 'Pending',
      reconciled: 'Reconciled'
    },
    table: {
      action: 'Reconcile payment',
      createExpense: 'Create expense',
      createIncome: 'Create income',
      discardAction: 'Discard',
      enableAction: 'Set enable again'
    },
    title: 'Bank accounts'
  },
  bookings: {
    detail: {
      amountDiscount: '{{amount}} Discount',
      breakdown: {
        day: 'day',
        days: 'days',
        hour: 'hour',
        hours: 'hours',
        kg: 'kg',
        kgs: 'kgs',
        month: 'month',
        months: 'months',
        unit: 'unit',
        units: 'units'
      },
      cancel: 'Cancel service',
      instructions: 'Costumer instructions',
      modal: {
        cancel: 'No, go back',
        confirm: 'Yes, cancel',
        description: 'This will send an automatic message to the tenant and a refund will need to be performed manually. This action can’t be undone.',
        title: 'Cancel service?'
      },
      note: 'Costumer note',
      percentDiscount: '{{amount}}% Discount',
      priceBreakdown: 'Price breakdown',
      product: 'Product selected',
      service: 'Service details',
      slot: 'Scheduled for',
      task: 'Linked task',
      title: 'Booking detail',
      total: 'Total',
      vat: 'VAT'
    },
    table: {
      empty: 'No bookings yet :)',
      filterActive: 'Active',
      filterPassed: 'Passed',
      price: 'Price',
      scheduled: 'Scheduled for',
      service: 'Service type',
      status: 'Status',
      tags: {
        all: 'All status',
        cancel_req: 'Cancelation req',
        canceled: 'Canceled',
        done: 'Done',
        pendingPayment: 'Pending Payment',
        scheduled: 'Scheduled',
        unknown: 'Unknown'
      },
      today: 'Today',
      tomorrow: 'Tomorrow',
      unit: 'Unit'
    },
    title: 'Bookings'
  },
  brands: {
    content: {
      accentColor: 'Accent color',
      contactEmail: 'Contact email',
      contactPhone: 'Contact phone number',
      dashboardURL: 'Dashboard URL',
      domains: 'Other domains',
      modifySignaturitAccessToken: 'Modify Signaturit access token',
      modifySignaturitPassword: 'Modify Signaturit password',
      portalURL: 'Portal URL',
      primaryColor: 'Primary color',
      privacyURL: 'Privacy URL',
      signaturit: 'Signaturit',
      signaturitAccessToken: 'Access token',
      signaturitBrandingId: 'Branding Identifier',
      signaturitPassword: 'Password',
      termsURL: 'Terms URL',
      whatsapp: 'Whatsapp',
      whatsappSender: 'Whatsapp sender'
    },
    modals: {
      addDomain: 'Add domain',
      createTitle: 'Create brand',
      editTitle: 'Edit brand'
    },
    title: 'Brands'
  },
  buildings: {
    acquisition: 'Residents Acquisition Portal',
    acquisitionHint: 'Configure your residents acquisition landing page for this building',
    add: 'Add a new building',
    addBuildingCardLabel: 'Add a building',
    addCta: 'Add building',
    address: 'Address',
    all: 'See all buildings',
    confirmationModal: {
      description: 'This will remove the selected building permanently, unassigning all tenants related to it. This action can’t be undone.',
      title: 'Remove building?',
      'toast': 'This building has units assigned and cannot be removed'
    },
    delete: 'Delete Building',
    edit: 'Edit Building',
    editAcquisition: 'Edit resident acquisition',
    editCta: 'Save changes',
    featureFlags: 'Feature flags',
    featureFlagsHint: 'Control which features or sections appear in you tenant’s app.',
    image: 'Upload an image',
    name: 'Give it a name',
    propertyManagerLogo: 'Upload the property manager logo',
    settings: 'Building settings',
    slug: 'Acquisition URL',
    title: 'Buildings',
    units: 'units',
    workspaceForLanding: 'Workspace'
  },
  buttons: {
    accept: 'Accept',
    accountingPage: 'Go to Accounting page',
    add: 'Add',
    back: 'Go back',
    cancel: 'Cancel',
    changeStaff: 'Change staff',
    changeUnit: 'Change unit',
    changeZazu: 'Change Zazu',
    close: 'Close',
    copyLink: 'Copy link',
    delete: 'Delete',
    deleteBtn: 'Delete',
    discard: 'Discard',
    done: 'Done',
    finishAndSend: 'Finish and send',
    loadMore: 'Load more',
    next: 'Next',
    noMore: 'Nothing more to load',
    ok: 'Ok',
    onBack: 'Back',
    pay: 'Pay',
    remove: 'Remove',
    tryItAgain: 'Try it again',
    unitDetailsPage: 'Go to Unit Details',
    unitLeasingDetailsPage: 'Go to Unit Leasing',
    update: 'Update'
  },
  calendar: {
    buttons: {
      day: 'Day',
      list: 'List',
      month: 'Month',
      today: 'Today',
      week: 'Week',
      year: 'Year'
    },
    event: {
      allDay: 'All day',
      from: 'From:',
      timezoneInfo: 'This event will take place in the {{eventTimezone}} time zone:',
      to: 'To:',
      viewDetails: 'View details'
    },
    eventTypes: {
      all: 'All',
      checkout: 'Checkout',
      keyDelivery: 'Key delivery',
      tasks: 'Tasks',
      unitAvailability: 'Unit availability',
      viewings: 'Viewings'
    },
    filters: {
      eventType: {
        label: 'Event type'
      },
      staff: {
        label: 'Assigned to',
        removeSelection: 'Remove selection'
      }
    },
    title: 'Calendar'
  },
  checkout: {
    content: 'Making some changes in your dashboard to unlock your new service...',
    headline: 'Service successfully purchased',
    title: 'Checkout'
  },
  components: {
    dropdown: {
      search: {
        placeholder: 'Search'
      }
    },
    textEditor: {
      paragraph: 'Paragraph',
      title: 'Title {{number}}'
    }
  },
  contracts: {
    contractSelect: {
      noContracts: 'There are no contracts associated with this property.'
    }
  },
  copilot: {
    created: 'Created at {{date}}',
    emptyConversation: 'Start a conversation sending a message.',
    emptystate: 'Send a message to Zazume Copilot and start a conversation',
    remove: {
      description: 'This will remove the selected conversation permanently. This action cannot be undone.',
      title: 'Remove conversation?',
      toast: 'Conversation removed'
    },
    systemUser: 'Zazume Copilot'
  },
  dashboard: {
    accounting: {
      invoicedTotal: 'Invoiced total',
      latePayments: 'Late payments'
    },
    average: 'Average',
    balance: {
      title: 'Balance',
      yearly: 'Yearly balance'
    },
    commercialisation: {
      published: 'Published',
      publishedValue: 'Published value',
      title: 'Commercialisation'
    },
    currency: {
      euros: '€ Euros',
      eurosMonth: '€ Euros/month'
    },
    emptyState: 'Nothing to show',
    expenses: {
      title: 'Expenses'
    },
    incidents: {
      hint: 'Open incidents',
      lastIncidents: 'Last incidents',
      openIncidents: 'Open incidents',
      title: 'Incidents'
    },
    incomes: {
      title: 'Incomes'
    },
    management: {
      departureProgress: 'Departures in progress',
      departures: 'Departures',
      managedUnits: 'Managed units',
      myComecializatingUnit: 'Units in commercialisation ({{count}})',
      title: 'Management'
    },
    payments: {
      passDuePayments: 'Pass due payments'
    },
    reserved: {
      title: 'Reserved'
    },
    units: {
      occupancyRate: 'Rental rate',
      occupied: 'Rented',
      published: 'Published',
      rented: 'Rented',
      title: 'Units',
      total: 'Total units'
    },
    viewings: {
      nextWeekCount: 'Next 7 days ({{count}})',
      scheduledViewings: 'Scheduled viewings',
      todayCount: 'Today ({{count}})',
      total: 'Total viewings',
      viewingsCount: '{{count}} viewing',
      viewingsCount_other: '{{count}} viewings',
      yesterdayCount: 'Yesterday ({{count}})'
    }
  },
  documentUploadLink: {
    create: {
      description: 'If you want you can add some extra information about which documents users should upload you can complete it on the fields below.',
      filesDescription: 'Files description',
      submitAction: 'Generate and copy link',
      success: 'New files upload link created succesfully',
      title: 'Create new upload document link'
    },
    detail: {
      action: 'Create new link',
      description: 'Those are the links created to upload documents',
      title: 'Upload document links'
    },
    emptyState: 'To create an upload documents links <1>click here</1>',
    fields: {
      createdBy: 'Created by',
      expiresOn: 'Expires on',
      link: 'Link',
      status: 'Status',
      subcategory: 'Subcategory'
    },
    statuses: {
      available: 'Available',
      expired: 'Expired'
    }
  },
  documents: {
    agent: {
      categories: {
        agent: 'Agent'
      }
    },
    clickHere: 'click here',
    common: {
      categories: {
        other: 'Other'
      }
    },
    confirmRejectModal: {
      confirmButton: 'Reject',
      description: 'This will reject the document signatures permanently. This action can\'t be undone.',
      rejectLabel: 'Why do you want to reject this document signatures?',
      rejectTitle: 'Reject document'
    },
    created: 'Document created successfully',
    downloadAuditTrail: 'Download signature certificate',
    emptyState: 'To add documents to this section',
    emptyStateNotEditable: 'No documents uploaded in this section',
    expirableDocumentsLength: 'You can only upload one document at a time when it has a expiration date.',
    expirationDate: 'Expiration date',
    expired: 'Expired on',
    expiredDetail: 'It is expired',
    expiresIn: 'It expires in {{count}} day',
    expiresIn_other: 'It expires in {{count}} days',
    expiring: 'It expires on',
    incident: {
      categories: {
        incident: 'Incident'
      }
    },
    isHiddenFromOwner: 'Hidden from owner',
    isHiddenFromOwnerToggle: {
      active: 'Owners won’t see this document',
      disabled: 'Owners will see this document',
      label: 'Hide visibility to owners'
    },
    manageLinks: 'Manage upload files link',
    newDocument: 'New document',
    nonPayment: {
      categories: {
        nonPayment: 'Non-payment'
      }
    },
    oneDocumentPerUploadError: 'Only one document at a time can be uploaded if it has an expiration date or signing request',
    onlyPdfSigningFilesError: 'Only PDF files are accepted for signing requests',
    ownerAccount: {
      categories: {
        idCards: 'ID Cards',
        others: 'Others'
      }
    },
    ownerServiceContract: {
      categories: {
        other: 'Other',
        ownerServiceCommercialisationContract: 'Owner service contract',
        ownerServiceContract: 'Owner service contract',
        ownerServiceManagementContract: 'Owner service contract'
      }
    },
    provider: {
      categories: {
        provider: 'Provider'
      }
    },
    rejectDocument: 'Reject document',
    removed: 'Document removed successfully',
    signature: {
      description: 'Choose where you want to place the signature in the document',
      options: {
        free: 'Free signature',
        pageEnd: 'On a new single page at the end'
      },
      title: 'Signature'
    },
    signatures: 'Signatures',
    signingDocumentStates: {
      draft: 'Waiting',
      expired_signature: 'Expired signature',
      rejected: 'Rejected',
      signed: 'Signed'
    },
    size: 'Size',
    tenant: {
      categories: {
        backgroundCheck: 'Background check',
        idCards: 'ID Cards',
        insurance: 'Insurance',
        invoices: 'Invoices',
        others: 'Others',
        rentalContract: 'Rental contract',
        rentalInsurance: 'Rental insurance'
      }
    },
    type: 'Type',
    types: {
      IdBackSide: 'Parte trasera de identificación',
      IdFrontSide: 'Lado frontal de identificación',
      agent: 'Agent',
      breakContract: 'Break a contract',
      budget: 'Budget',
      cadastralReport: 'Cadastral report',
      commercialisationFeeContract: 'Commercialization fee contract',
      commercializationOwnerLease: 'Commercialization lease',
      contraInventory: 'Contra inventory',
      contract: 'Contract',
      document: 'Document',
      electricityCupsCIEInvoice: 'Electricity CUPS & CIE',
      employmentContract: 'Employment contract',
      energyPerformanceCertificate: 'Energy performance certificate',
      expenseAttachment: 'Expense attachment',
      freelanceInvoices: 'Freelance invoices',
      gasCupsInvoice: 'Gas CUPS & CIE',
      holdingDepositContract: 'Holding deposit contract',
      homeInsurance: 'Home insurance',
      idBack: 'ID back side',
      idFront: 'ID front side',
      internetConnectionInvoice: 'Internet connection invoice',
      inventory: 'Inventory',
      invoice: 'Invoice',
      managementOwnerLease: 'Property management lease',
      occupancyCertificate: 'Occupancy certificate',
      other: 'Other',
      otherContract: 'Other contract',
      otherInsurance: 'Other insurance',
      otherOwnerLease: 'Other kind of lease',
      ownerServiceContract_breakContract: 'Break contract',
      ownerServiceContract_commercialisationContract: 'Commercialisation Contract',
      ownerServiceContract_contract: 'Rent Advance Contract',
      ownerServiceContract_managementContract: 'Management Contract',
      partnershipContract: 'Partnership contract',
      passport: 'Passport',
      paymentAttachment: 'Income attachment',
      payoutReceipt: 'Payout receipt',
      payslip: 'Payslip',
      pensionForm: 'Pensioner form',
      powerOfAttorney: 'Power of attorney',
      propertyValuationReport: 'Property valuation report',
      provider: 'Provider',
      remodelingGeneral: 'General remodeling',
      rentReceipt: 'Rent receipt',
      sepa: 'SEPA',
      sepaMandate: 'SEPA mandate',
      severance: 'Severance',
      signedContract: 'Signed contract',
      signedContractAttachment: 'Signed contract',
      taxForm100: 'Tax form 100',
      taxForm200: 'Tax form 200',
      unsignedContract: 'Unsigned contract',
      unsignedContractAttachment: 'Unsigned contract',
      viewingsContract: 'Viewings contract',
      waterSupplyInvoice: 'Water supply invoice',
      workingLifeReport: 'Working Life Report'
    },
    unit: {
      categories: {
        building: 'About the building',
        commercialisationContract: 'Commercialisation contract',
        insurance: 'Insurance',
        inventory: 'Inventory',
        invoices: 'Invoices',
        management: 'Management',
        other: 'Other',
        remodeling: 'Remodeling'
      }
    },
    updated: 'Changes saved successfully'
  },
  documentsModals: {
    category: 'Category',
    create: {
      description: 'Select the document you want to upload to this unit.',
      title: 'Upload new document'
    },
    documentSpecs: 'Only PDF, JPG, PNG, and all office files with max size of {{maxFileSizeMB}}MB',
    documentType: 'Document type',
    downloadDocument: 'Download document',
    edit: {
      description: 'Edit the document fields you want to update.',
      title: 'Edit document'
    },
    fileTooBig: 'File max size limit is {{maxFileSizeMB}}MB',
    filesToUpload: 'Files permitted: ',
    filesTooBig: 'Files max size limit is {{maxBunchFilesSizeMB}}MB',
    isExpirable: 'This document has an expiration date',
    isSignable: 'This document must be signed',
    loadingError: 'An error occurred while trying to load the document',
    remove: {
      description: 'This will remove the selected document permanently. This action can not be undone.',
      title: 'Remove document?'
    },
    selectCategory: 'Select a category',
    selectDate: 'Select a date',
    selectDocumentType: 'Select a type',
    uploadDocument: 'Upload your document',
    when: 'When?',
    wrongFile: 'File not supported'
  },
  downloadApp: {
    text: 'To start getting all the benefits of your community please download any of our apps using the links below.',
    title: 'Download the Zazume app'
  },
  drafts: {
    documents: {
      addNewDocument: {
        documentTitle: 'Document title',
        linkedTo: 'Document linked to',
        success: 'Document created successfully',
        table: {
          nameTitle: 'Document name',
          options: {
            delete: 'Delete',
            download: 'Download',
            duplicate: 'Duplicate',
            edit: 'Edit'
          },
          templateName: 'Template',
          typeTitle: 'Document type'
        },
        template: 'Template',
        templatePlaceholder: 'Select template',
        title: 'Add new document',
        whichAgent: 'Which agent?',
        whichApplication: 'Which application?',
        whichContract: 'Which contract?',
        whichUnit: 'Which unit?'
      },
      editDocuments: {
        success: 'Document updated successfully',
        title: 'Edit document'
      },
      filters: {
        author: 'Created by'
      },
      generatingDocument: 'Generating the document',
      previewModal: {
        changesToUpdate: 'There are outdated variables. Press the button to update them',
        cta: 'Download',
        isDraft: 'Is a draft document?',
        refreshCompleted: 'Update completed! Remember to save the changes',
        title: 'Preview document'
      },
      saveAndDownload: 'Save and download draft',
      table: {
        options: {
          delete: {
            description: 'This will remove the selected document permanently. This action can\'t be undone.',
            title: 'Delete document'
          }
        }
      },
      template: {
        noVariables: 'Some information is missing. Review all the added information labels and fill them with the appropriate information.'
      },
      title: 'Documents'
    },
    title: 'Drafts'
  },
  errorCodes: {
    1000: 'Invoice not found',
    100000: 'IBAN already exists',
    100001: 'Alias already exists',
    100002: 'Some bank transactions are linked to incomes or expenses. You need to unreconcile them if you want to delete the bank account',
    10002: 'No organization IBAN provided',
    10003: 'No management service contract found',
    1001: 'Cannot create invoice',
    1002: 'Invoice already generated',
    1003: 'Movement does not include fees',
    1004: 'The transaction has not been paid',
    1005: 'The movement type is invalid',
    1006: 'The movement creditor is not valid',
    1007: 'The unit is not associated with an owners account',
    1008: 'The organization does not have billing information',
    1009: 'Owner has no billing address',
    1010: 'Customer has no billing address',
    1011: 'An error occurred while generating the document',
    1012: 'The organization has no invoice series',
    1013: 'The tenant does not have currentAddress or idNumber',
    1014: 'The owner does not have billing information',
    1015: 'No tenant or provider is assigned to income',
    1016: 'Income payer does not have an email address',
    1017: 'The provider has no billing data assigned',
    1018: 'The movement is cancelled',
    11: 'User does not have a valid phone number',
    110001: 'We do not have enough information to offer you a valuation of this property.',
    111: 'Owner account has one or more units assigned',
    112: 'You cannot perform this action. If you wish to modify your iban, please contact help@zazume.com.',
    13: 'User already exists',
    1301: 'Address provided is not available for valuation',
    14: 'User unauthorized',
    15: 'User not found',
    16: 'User does not have the required role',
    1601: 'Provider not found',
    1602: 'This provider cannot be deleted because it is linked to a payment. To delete this provider you should edit and unlink the provider or remove those payments.',
    17: 'Token expired',
    1900: 'Brand not found',
    1901: 'Brand already exists',
    1902: 'Some domains are already in use by another brand.',
    20: 'User does not have valid ID card data',
    20010: 'A unit cannot be without an owner when there’s a recurrent payout',
    202: 'Invalid document type provided',
    203: 'Document no found',
    204: 'No file uploaded',
    2200: 'User already invited',
    2201: 'Invitation already accepted',
    2300: 'Contact not found. You can try later or create it directly on the provider',
    25: 'User does not have a valid telephone',
    3000: 'Couldn\'t connect with external provider, please try again later',
    304: 'Cannot request document signature with duplicated signer emails',
    400: 'Review for this booking already created',
    40001: 'Key delivery overlaps with viewing availability assigned to selected agent',
    412: 'No comparables found for this property',
    423: 'No avm found for this property',
    50: 'Invalid field',
    500001: 'Non payment cannot be deleted because it has linked incomes',
    50001: 'XML export cannot be deleted because it is linked to a processed payout',
    502: 'Applicant linked with same unit already exists',
    505: 'Application already completed or declined',
    51: 'Invalid ID provided',
    52: 'Invalid Date',
    522: 'Viewing is already completed by the agent',
    53: 'Invalid password',
    55: 'Invalid address provided',
    600001: 'User already exists with agent role',
    600002: 'User already exists with owner role',
    601: 'Payment is already cancelled or completed',
    605: 'Payment is already paid or partially paid. Remove reconciliations to proceed with declination',
    611: 'Can\'t create the XML. There\'s missing information in some payments',
    612: 'The tenant {{tenant}} hasn\'t provided the IBAN number',
    613: 'The owner is missing some billing information',
    614: 'The organization is missing some billing information',
    615: 'The tenant {{tenant}} hasn\'t signed the SEPA mandate',
    70000: 'Document not ready to cancel. Try again later',
    70001: 'The document is not ready to be deleted. It\'s being used by some documents. Delete the documents first',
    70002: 'Signer is not present on requested document',
    70003: 'Email is already in use for another signer on requested document',
    73: 'Unit not found',
    74: 'Building has units assigned',
    75: 'Tenant already assigned',
    76: 'Workspace has tenants assigned',
    77: 'Workspace has units assigned',
    79: 'Unit has applications in progress and cannot be deleted',
    791: 'Unit has a current contract and cannot be deleted',
    794: 'Unit has a future contract and cannot be deleted',
    80: 'Schedule viewing URL already exists',
    801: 'Tenant is in an active application. Reject the application before deleting it',
    802: 'Tenant is living in a unit. Remove it from the unit before deleting it',
    83: 'Unit is published and cannot be deleted, call your manager to unpublish it',
    84: 'Unit has tenants assigned and cannot be deleted, unsign them to delete it',
    85: 'Unit does not have an assigned owner',
    86: 'Unit does not have a complete address',
    90000: 'Permission denied',
    90001: 'Bad request',
    90002: 'Not found',
    903: 'Cannot create SEPA, it has a negative payout',
    904: 'Cannot create SEPA, some selected payouts were not found, please return to payouts selection',
    905: 'Cannot create SEPA with payments already paid or in the process of validation',
    91: 'Unexpected error'
  },
  errorMessages: {
    clientError: 'An error has occurred in the browser',
    length: 'This field must be at least {{length}} characters long',
    mandatory: 'Field mandatory',
    network: 'Oops, it looks like you have no internet connection. For that reason, this action could not be completed.'
  },
  errorPage: {
    buttonHome: 'Go back home',
    buttonLogin: 'Go login',
    headline: '404',
    subtitle: 'Hey! Looks like something went wrong. We\'re working on it.',
    title: 'Error'
  },
  expenses: {
    addExpense: 'Add expense',
    attachments: {
      addAttachment: 'Add attachment',
      title: 'Attachments'
    },
    attributable: 'Attributable to tenant',
    attributableExpenseMessage: 'In order to attribute an expense to a tenant, it is mandatory to attach the invoice',
    attributed: 'Attributed to income',
    baseAmount: 'Base amount',
    chooseAnExpense: 'Choose an expense',
    concept: 'Concept',
    conceptPlaceholder: 'E.g. Unexpected expense',
    createInvoice: 'Create invoice',
    deleteModal: {
      description: 'Are you sure you want to delete this expense? This action can\'t be undone.',
      title: 'Delete Expense'
    },
    deleteToast: 'Expense deleted successfully',
    detail: {
      edit: 'Edit expense',
      title: 'Expense detail'
    },
    editExpense: 'Edit expense',
    editModal: {
      attributedWarning1: 'There is income associated with this expense. When you modify some fields of this expense such as the amount, the income will automatically update to also reflect the changes.',
      attributedWarning2: 'There is income associated with this expense that has already been paid, so some fields cannot be modified. If you need to, delete the income reconciliation and you can modify more fields in both payments.',
      infoModal: {
        description: 'Changes in expense will also be reflected in the associated income.',
        priceModifiedDescription: 'We will notify the payer that the amount has been modified and send them a payment request with the current amount.',
        priceModifiedTitle: 'Expense and income have been updated. We have notified the payer to pay the new amount.',
        title: 'The associated expense and income have been updated'
      },
      warningPart1: 'This expense is {{stateName}}',
      warningPart2: ', so some inputs cannot be edited. If you need to edit them, you should remove the reconciliations first.'
    },
    expense: 'Expense',
    expenseDate: 'Expense date',
    expenseDetail: 'Expense detail',
    expenseType: 'Expense type',
    expense_other: 'Expenses',
    export: {
      button: 'Export expenses',
      description: 'Select a date range',
      error: 'An error has occurred when exporting expenses',
      from: 'From',
      success: 'In a few minutes you will receive an email with expenses file.',
      title: 'Export expenses',
      to: 'To'
    },
    import: {
      button: 'Import expenses',
      description: 'Please follow these simple steps:<br><br><strong>1. Download the template:</strong> <CustomLink>Click here</CustomLink> to download the template in Excel format<br><br><strong>2. Complete the template:</strong> Fill in all the necessary fields with your expenses information.<br><br><strong>3. Upload the template:</strong> Once completed, export it as CSV and return here to upload the file.<br><br>If you have any trouble, you can send an email to help@zazume.com with your file filled and we will help you.',
      error: 'An error has occurred when importing expenses',
      success: 'In a few minutes you will receive a notification with the results of importing',
      title: 'Import expenses'
    },
    linkExistingExpense: 'To link an existing expense',
    linkExpense: 'Link expense',
    linkExpenseModalDescription: 'Select an existing expense from {{unitId}}:',
    linkExpenseSectionTooltip: 'Only team members can see this information',
    linkMultipleExpensesError: 'Please, select only one expense to link',
    linkServiceCost: 'Link service cost',
    linkWithExistingExpense: 'Link with an existing expense',
    linkedExpense: 'Linked expense',
    linkedExpenseSuccess: 'Expense linked with success',
    listingEmptyMessage: 'No expenses created yet',
    messages: {
      addExpense: {
        success: 'Expense added'
      },
      addRecurrentExpense: {
        success: 'Recurrent expense added'
      }
    },
    payments: {
      addPayment: 'Add payment',
      addedPayment: 'Payment added',
      deletedMessage: 'Payment deleted',
      help: 'To add a new payment',
      help2: 'No reconciliations yet'
    },
    recurrentExpense: 'Recurrent expense',
    requestPayment: 'Request payment from the tenant',
    singleExpense: 'Single expense',
    split: {
      deleteConfirmation: {
        all: 'all',
        one: 'one',
        writeAll: 'Write “all” to confirm',
        writeOne: 'Write “one” to confirm'
      },
      detail: {
        description: 'Monthly - {{times}} payments',
        title: 'Payment Split'
      },
      howManyTimes: 'How many times?',
      options: 'In {{number}} payments',
      removeModal: {
        deleteAllWarning: {
          description: 'This is because there are expenses with reconciliations, which are included in a settlement and/or have an invoice.',
          title: 'It is not possible to eliminate all related expenses.'
        },
        options: {
          all: 'Delete all expenses',
          one: 'Delete only this expense'
        },
        title: 'Do you want to delete this expense?',
        warning: {
          description: 'Note that if you delete only this expense the other associated expenses will not be updated, you will have to edit them manually. This action cannot be undone.',
          title: 'This expense is part of a payment divided into more payments.'
        }
      },
      toggle: 'Split the payment',
      warning: {
        description: 'If you edit only this payment the changes will not be applied to the other expenses, you must do it manually. Note that if you edit the amount, the total expense will be affected.',
        title: 'This expense is part of a payment divided into more payments.'
      }
    },
    statuses: {
      not_paid: 'Not paid',
      paid: 'Paid',
      partially_paid: 'Partially paid'
    },
    toBeSettled: {
      deleteModal: {
        actionButtonText: 'Yes, I want to uncheck',
        description: 'Doing so will remove the previous configuration and it is likely that this expense will be included by default in the next pay-out.',
        title: 'Are you sure you want to uncheck this “to be settled” expense?'
      },
      editModal: {
        acceptButton: 'Mark to be settled',
        datePicker: {
          label: 'Select a date',
          placeholder: 'Select a date'
        },
        description: 'In order to mark the payment as to be settled, it is necessary to indicate a date from which this expense will be automatically included in the pay-out.',
        title: 'Mark expense as to be settled'
      },
      mark: 'Mark to be settled',
      tooltip: 'It will be added to a payout starting from {{date}}',
      unmark: 'Unmark to be settled',
      updated: 'The expense was updated successfully'
    },
    totalAmount: 'Total amount',
    types: {
      additionalGuarantee: 'Additional guarantee',
      additional_guarantees: 'Additional Guarantees',
      alarm: 'Alarm',
      application_payment: 'Application payment',
      cleaning: 'Cleaning',
      commercialization_fees: 'Commercialization fees',
      community: 'Community',
      default: 'Default',
      deposit: 'Deposit',
      electricity: 'Electricity',
      expansionAndImprovement: 'Expansion and improvement',
      fees: 'Fees',
      furniture: 'Furniture',
      gas: 'Gas',
      general: 'General',
      heating: 'Heating',
      holding_deposit: 'Holding deposit',
      incident: 'Incident',
      insurance: 'Insurance',
      internet: 'Internet',
      legalDefense: 'Legal defense',
      loan: 'Loan',
      maintenance: 'Maintenance',
      management: 'Management',
      marketing: 'Marketing',
      monthly_rent: 'Monthly rent',
      mortgage: 'Mortgage',
      mortgage_interests: 'Mortgage interests',
      other: 'Other',
      phone: 'Phone',
      provisions: 'Provisions',
      rental: 'Rental',
      repairAndMaintenance: 'Repair and maintenance',
      security: 'Security',
      services: 'Services',
      supplies: 'Supplies',
      supply: 'Supply',
      taxes: 'Taxes',
      trash: 'Trash',
      water: 'Water'
    },
    unlinkExpense: 'Unlink expense',
    unlinkRecurrentExpense: 'Unlink recurrent expense',
    unlinkServiceCost: 'Unlink service cost',
    unlinkedExpenseSuccess: 'Expense unlinked with success',
    viewDetails: 'View expense details',
    wasExpensePaid: 'Was this expense paid?'
  },
  general: {
    about: 'About',
    active: 'Active',
    add: 'Add',
    addOwner: 'Add owner',
    address: 'Address',
    administrator: 'Administrator',
    agent: 'Agent',
    agents: 'Agents',
    all: 'All',
    all_female: 'All',
    allright: 'All right',
    amortization: 'Capital amortization',
    amount: 'Amount',
    and: 'and',
    applicant: 'Applicant',
    applicantAdmin: 'Applicant Admin',
    applicantCount: '{{count}} applicant',
    applicantCount_other: '{{count}} applicants',
    applicant_plural: 'Applicants',
    application: 'Application',
    applicationManager: 'Application Manager',
    applications: 'Applications',
    assign: 'Assign',
    assignableEntities: {
      accountManager: 'Account manager',
      agent: 'Agent',
      applicant: 'Applicant',
      applicationManager: 'Application manager',
      incidentsManager: 'Incident manager',
      ownerAccount: 'Owner Account',
      ownerLead: 'Owner Lead',
      staff: 'Staff',
      tenant: 'Tenant',
      unitManager: 'Unit manager',
      zazume: 'Zazume'
    },
    avatarTypes: {
      accountManager: 'Account manager',
      agent: 'Agent',
      applicationManager: 'Application manager',
      guarantor: 'Guarantor',
      incidentsManager: 'Incidents manager',
      owner: 'Owner',
      ownerAccount: 'Owner Account',
      ownerLead: 'Owner Lead',
      sharer: 'Sharer',
      staff: 'Staff',
      tenant: 'Tenant',
      unitManager: 'Unit manager'
    },
    back: 'Back',
    bank: 'Bank',
    board: 'Board',
    bookings: 'Bookings',
    breakdown: 'Breakdown',
    broken: 'Broken',
    building: 'Building',
    cancel: 'Cancel',
    category: 'Category',
    city: 'City',
    clickHere: 'click here',
    clickingHere: 'clicking here',
    close: 'Close',
    closedBy: 'Closed by',
    coTenant: 'Co-tenant',
    commissionFee: 'Commission fee',
    completed: 'Completed',
    confirm: 'Confirm',
    contactNumber: 'Contact number',
    continue: 'Continue',
    contracts: 'Contracts',
    copiedToClipboard: 'Copied to clipboard',
    created: 'Created',
    createdBy: 'Created by',
    creationDate: 'Creation date',
    creditor: 'Creditor',
    creditorId: 'Creditor ID',
    creditorName: 'Creditor name',
    currentAddress: 'Current address',
    date: 'Date',
    day: '{{count}} day',
    day_other: '{{count}} days',
    decline: 'Decline',
    declined: 'Declined',
    defaultInsurance: 'Default Insurance',
    delete: 'Delete',
    deleteEntity: 'Delete {{entity}}',
    deleteEntityError: 'The {{entity}} could not be deleted',
    description: 'Description',
    deselectAll: 'Deselect all',
    details: 'Details',
    disabled: 'Disabled',
    discount: 'Discount',
    docs: 'Docs',
    document: 'Document',
    documentation: 'Documentation',
    documents: 'Documents',
    download: 'Download',
    downloadImage: 'Download image',
    draft: 'Draft',
    dueDate: 'Due date',
    duplicate: 'Duplicate',
    duration: 'Duration',
    edit: 'Edit',
    editEntity: 'Edit {{entity}}',
    editEntityError: 'The {{entity}} could not be edited',
    email: 'Email',
    employmentSituation: 'Employment situation',
    empty: 'Empty',
    emptyTable: 'No data',
    enabled: 'Enabled',
    entities: 'Entities',
    entity: 'Entity',
    error: 'Error',
    errorCount: '{{count}} error',
    errorCount_other: '{{count}} errors',
    errorOcurred: 'An error has occurred',
    expenses: 'Expenses',
    expirationDate: 'Expiration date',
    expired: 'Expired',
    fee: 'Fee',
    filters: {
      amount: {
        maximum: 'Maximum amount',
        minimum: 'Minimum amount'
      },
      datesFilter: {
        deleteButton: 'Delete',
        endDateLabel: 'To',
        endDatePreposition: 'To',
        label: 'Dates',
        placeholder: 'All',
        startDateLabel: 'From',
        startDatePreposition: 'From',
        submitButton: 'Apply filter'
      },
      title: 'Filters',
      titles: {
        applications: 'Applications filters',
        autoReconciliations: 'Auto-reconciliation filters',
        bookings: 'Bookings filters',
        expenses: 'Expenses filters',
        incidents: 'Incidents filters',
        invoices: 'Invoice filters',
        payments: 'Payments filters',
        rentAdvance: 'Rent advance filters',
        tenants: 'Tenants filters',
        units: 'Units filters'
      }
    },
    finish: 'Finish',
    firstName: 'First name',
    fiscalName: 'Fiscal name',
    flatmate: 'Flatmate',
    flatmates: 'Flatmates',
    forms: {
      addFile: 'Add file',
      addressNumberMandatory: 'Address must contain a number',
      attachmentsAdded: 'Attachments added',
      attachmentsRemoved: 'Attachments removed',
      automatic: 'Automatic',
      browse: 'browse',
      changesSaved: 'Changes saved successfully',
      chooseADate: 'Choose a date',
      chooseAnOption: 'Choose an option',
      chooseSubcategory: 'Choose a subcategory',
      commercialMandatory: 'Please select a commercial',
      creditorIdentifierIsMandatory: 'Creditor identifiers is mandatory when there is ID Number',
      discountConcept: 'Discount concept',
      dragDropOrBrowse: 'Drag and drop or browse',
      dragNdropTitle: 'Drag & Drop your file here',
      dragnDrogOr: 'Drag and drop or',
      emailError: 'Not a valid email: name@email.com',
      emailExisting: 'Email already in use',
      exists: 'already exists!',
      extraIformation: 'Add some extra information here',
      fileInput: 'Select or Drop a file',
      fileTooBig: 'File max size limit is {{maxFileSizeMB}}MB',
      fileTypeCSV: 'Only CSV files with max size of {{maxFileSizeMB}}MB',
      fileTypeN43: 'Only Norma 43 (.n43) files with max size of {{maxFileSizeMB}}MB',
      fileTypePDF: 'Only PDF files with max size of {{maxFileSizeMB}}MB',
      filesTooBig: 'Files max size limit is {{maxBunchFilesSizeMB}}MB',
      ibanNotValid: 'IBAN number not valid',
      idNotDefinedType: 'ID type not provided',
      idNotValid: 'ID card number not valid',
      imageOptional: 'Optional',
      invalid: 'Invalid value',
      invalidDate: 'Not a valid date',
      invalidGooglePlacesSelection: 'Invalid address',
      invalidIntegerNumber: 'Number must be integer',
      invalidNegativeNumber: 'Number must be higher than 0',
      invalidNumber: 'Not a valid number',
      invalidToken: 'Invalid/expired token provided',
      invalidUrl: 'Invalid URL',
      mandatory: 'Mandatory field',
      mandatoryGooglePlacesSelection: 'Select one of the options below',
      missingBillingOrganization: 'The organization is missing some billing information, make sure to complete it before creating the income',
      missingBillingOwner: 'Your account is missing some billing information, make sure to complete it before proceeding',
      missingBillingOwner_pm: 'The owner is missing some billing information, make sure to complete it before proceeding',
      noFile: 'No file selected',
      noPastDates: 'Invalid date. We cannot travel to the past, yet',
      onlyImages: 'Only images',
      passwordRules: 'It must contain at least one character of the following: uppercase, lowercase, number.',
      passwordsIdentical: 'Passwords must be identical',
      placeholders: {
        address: 'Type here and select an option',
        cups: 'Ex. ES0397956973059057TM',
        datePicker: 'dd/mm/yyyy',
        datePickerWithOur: 'dd/mm/yyyy - hh:mm',
        discountConcept: 'Write a short title for this discount',
        email: 'john@mail.com',
        example: 'E.g. {{value}}',
        insuranceCompany: 'Enter the name of the insurer',
        insurancePolicy: 'Enter your policy number',
        name: 'Enter name',
        phone: 'Enter phone number',
        publicationName: 'Contact name to show on the publication page',
        textarea: 'Click here to start writing'
      },
      saveChanges: 'Save changes',
      searcher: {
        recentlyAdded: 'Recently added'
      },
      selectAnOption: 'Select an option',
      setAPercentage: 'Set a percentage',
      setAnAmount: 'Set an amount',
      short: 'Field too short',
      typeAndSelect: 'Type here and select an option',
      unitHasNoOwner: 'Unit has no owner assigned',
      updatingMessage: 'Updating data...',
      uploadImage: 'Upload an image',
      whichAmount: 'Which amount?',
      workspaceInput: 'Select a workspace',
      writeHere: 'Write here',
      wrongFile: 'File not supported'
    },
    from: 'From',
    general: 'General',
    guarantor: 'Guarantor',
    guarantor_plural: 'Guarantors',
    hidden: 'Hidden',
    hour: '{{count}} hour',
    hourTitle: 'Hour',
    hour_other: '{{count}} hours',
    iban: 'IBAN',
    idCard: 'Identity card',
    idNumber: 'ID number',
    idType: 'ID type',
    import: 'Import',
    information: 'Information',
    insurance: 'Home Insurance',
    integration: 'Integration',
    interests: 'Interests',
    language: 'Language',
    lessor: 'Lessor',
    linkedToLease: 'Linked to a lease',
    list: 'List',
    living: 'Living',
    loading: 'Loading...',
    marketPlace: 'Marketplace',
    me: 'Me',
    min: '{{count}} min',
    month: {
      long0: 'January',
      long1: 'February',
      long10: 'November',
      long11: 'December',
      long2: 'March',
      long3: 'April',
      long4: 'May',
      long5: 'June',
      long6: 'July',
      long7: 'August',
      long8: 'September',
      long9: 'October',
      short0: 'Jan',
      short1: 'Feb',
      short10: 'Nov',
      short11: 'Dec',
      short2: 'Mar',
      short3: 'Apr',
      short4: 'May',
      short5: 'Jun',
      short6: 'Jul',
      short7: 'Aug',
      short8: 'Sep',
      short9: 'Oct'
    },
    monthly: 'Monthly',
    monthlyIncome: 'Monthly income',
    months: 'Months',
    name: 'Name',
    news: 'News!',
    next: 'Next',
    no: 'No',
    noResults: 'No results',
    none: 'None',
    notifications: {
      deleteSuccess: 'Deleted with success',
      emailNotifications: 'Email notifications',
      label: 'Notifications',
      pushNotifications: 'Push notifications',
      zazumeInvited: 'Zazume invitation send!'
    },
    occupied: 'Rented',
    optional: 'Optional',
    organization: 'Organization',
    other: 'Other',
    owner: 'Owner',
    ownerLeads: 'Owner Leads',
    owners: 'Owners',
    paid: 'Paid',
    password: 'Password',
    payments: 'Payments',
    pending: 'Pending',
    pendingAmount: 'Pending amount',
    personalDetails: 'Personal details',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    photos: 'Photos',
    platform: 'The platform',
    play: 'Play',
    portal: 'Portal',
    prequalification: {
      bad: 'Bad prequalified',
      badIncomes: 'Bad prequalified - Incomes',
      badPets: 'Bad prequalified - Pets',
      good: 'Well prequalified',
      no: 'No prequalified',
      noSuitableSchedule: 'No schedule fit',
      prequalified: 'Prequalified',
      title: 'Prequalification'
    },
    preview: 'Preview',
    property: 'Property',
    propertyManager: 'Property Manager',
    propertyManageras: 'Property Managers',
    propertyType: 'Property type',
    provider: 'Proveedor',
    providers: 'Providers',
    publication: 'Publication',
    referenceNumber: 'Reference Number',
    remove: 'Remove',
    removeSelection: 'Remove selection',
    request: 'Request',
    required: {
      information: 'Required information'
    },
    role: 'Role',
    room: 'Room',
    rooms: 'Rooms',
    save: 'Save',
    saveChanges: 'Save changes',
    search: 'Search',
    selectAll: 'Select all',
    selectUnit: 'Select a property',
    send: 'Send',
    sendViaWhatsApp: 'Send via WhatsApp',
    sent: 'Sent',
    service: 'Service',
    services: 'Services',
    setUp: 'Set up workspace',
    showLess: 'Show less',
    showMore: 'Show more',
    signature: 'Signature',
    signed: 'Signed',
    sort: {
      label: 'Sort by',
      placeholder: 'Select one'
    },
    start: 'Start',
    startedAt: 'Since',
    state: 'State',
    status: 'Status',
    step: 'Step',
    supplies: 'Supplies',
    surnames: 'Surnames',
    tenant: 'Tenant',
    tenants: 'Tenants',
    time: 'at',
    timezone: 'Timezone',
    title: 'Title',
    toBeSettled: 'To be settled',
    today: 'Today',
    tomorrow: 'Tomorrow',
    total: 'Total',
    type: 'Type',
    unassigned: 'Unassigned',
    unit: 'Unit',
    unitAvailability: 'Unit availability',
    unitId: 'Unit ID',
    unit_plural: 'Units',
    unlink: 'Unlink',
    untitled: 'Untitled',
    update: 'Update',
    upload: 'Upload',
    user: 'user',
    userCapital: 'User',
    video: 'Video',
    view: 'View',
    viewDocument: 'View document',
    warningCount: '{{count}} warning',
    warningCount_other: '{{count}} warnings',
    welcome: 'Welcome {{name}},',
    whatsAppSent: 'WhatsApp sent!',
    workspace: 'Workspace',
    year: 'Year',
    yearly: 'Yearly',
    years: 'Years',
    yes: 'Yes',
    yesterday: 'Yesterday',
    you: 'You'
  },
  globalSearch: {
    placeholder: 'Search Units, Tenants or Applications',
    placeholderAgent: 'Search Units'
  },
  help: 'Help Center',
  helpHub: {
    overlay: {
      email: 'Report a problem',
      helpCenter: 'Go to help center',
      newFeature: 'Request a feature',
      news: 'Platform updates',
      title: 'Help Center',
      tutorials: 'View tutorials',
      whatsapp: 'Contact via whatsapp'
    }
  },
  incidents: {
    categories: {
      appliance: 'Appliance',
      blinds_and_awnings: 'Blinds and awnings',
      brickwork: 'Brickwork',
      carpentry: 'Carpentry',
      cleaning: 'Cleaning',
      consulting: 'Consulting',
      electrician: 'Electrician',
      glass: 'Glass',
      handyman: 'Handyman',
      locksmith: 'Locksmith',
      move_in: 'Move in',
      others: 'Others',
      painting: 'Painting',
      plumbing: 'Plumbing',
      reforms: 'Reforms',
      supplies: 'Supplies'
    },
    category: 'Category',
    createIncident: 'New incident',
    createIncidentPage: {
      chooseOption: 'Choose an option',
      options: {
        incident: 'I have an <strong>incident</strong> (tenants will have visibility)',
        inquiry: 'I have an <strong>inquiry</strong> (tenants will not have visibility)'
      },
      saveButton: 'Create incident',
      title: 'New incident'
    },
    created: 'Incident successfully created',
    dashboard: {
      'view': 'View all incidents'
    },
    detail: {
      assign: 'Assign to staff',
      attachments: 'Attachments',
      createTask: 'Create a task',
      description: 'Description',
      modal: {
        assignTitle: 'Assign staff',
        description: 'This will remove the selected staff from the incident. You can reassign them at any time.',
        removeTitle: 'Remove staff?'
      },
      priority: {
        0: 'Low priority',
        1: 'High priority',
        all: 'All priorities',
        short: {
          0: 'Low',
          1: 'High'
        }
      },
      stateError: 'Impossible to update the state. ',
      states: {
        all: 'All status',
        closed: 'Closed',
        inProgress: 'In progress',
        in_progress: 'In progress',
        open: 'Open',
        rejected: 'Rejected'
      },
      title: 'Incident detail',
      unit: 'Unit'
    },
    details: 'Incident details',
    edit: 'Edit incident',
    empty: 'No incidents reported yet :)',
    emptyWithFilters: 'No incidents with those filters applied',
    inquiryModal: {
      description: 'To submit your inquiry, you must write an email to <1>{{email}}</1>',
      description2: 'Our team will answer you as quicly as possible',
      sendEmail: 'Send email',
      title: 'Send an inquiry'
    },
    isFromCheckIn: 'Check in',
    isFromCheckInLabel: 'This incident falls within the property check in',
    isManagedByOrganization: 'Managed by {{brandName}}',
    isManagedByOrganizationLabel: 'This incident is managed by {{brandName}}',
    isManagedByOwner: 'Managed by the owner',
    isManagedByOwnerLabel: 'This incident is managed by the owner',
    managedBy: {
      organization: 'Organization',
      owner: 'Owner'
    },
    managedByLabel: 'Managed by',
    new: {
      title: 'New incident'
    },
    newIncident: 'New incident',
    priorities: {
      high: 'High priority',
      low: 'Low priority'
    },
    priority: 'Priority',
    roles: {
      admin: 'Admin',
      agent: 'Agent',
      buildingManager: 'Building manager',
      commercial: 'Sales',
      managerOwner: 'Owner',
      managerOwnerBasic: 'Owner',
      managerOwnerMiddle: 'Owner',
      owner: 'Owner',
      ownerBasic: 'Owner',
      ownerManager: 'Owner',
      ownerMiddle: 'Owner',
      smallOwner: 'Owner',
      staff: 'Staff',
      tenant: 'Tenant',
      viewer: 'Viewer',
      zazume: 'Zazume'
    },
    selectOneYourUnits: 'Choose one of your properties',
    table: {
      headers: {
        priority: 'Priority',
        status: 'Status',
        submitted: 'Submitted',
        tenant: 'Tenant',
        title: 'Title',
        unit: 'Unit'
      },
      view: 'View incident'
    },
    title: 'Incidents',
    updates: {
      closed: 'Incident closed',
      closedBy: 'Incident closed by {{name}}',
      inProgress: 'Set to In progress',
      opened: 'Incident opened',
      photoUpload: 'New images were added',
      placeholder: 'Send a message',
      rejected: 'Incident rejected',
      rejectedBy: 'Incident rejected by {{actorName}}',
      roles: {
        buildingManager: 'Building manager',
        tenant: 'Tenant'
      },
      self: 'You',
      title: 'Updates'
    },
    whichUnit: 'In which unit?'
  },
  incomes: {
    form: {
      conceptPlaceholder: 'Write a short title for this income',
      payerPlaceholder: 'Choose who will pay this income',
      providerEmailError: 'Provider has no email to request payment. To add it, go to Provider Details page and click on edit to add the email',
      unitNotSelectedMessage: 'Please, first select a unit to fill this field'
    },
    income: 'income',
    payerTypes: {
      supplier: 'Provider',
      user: 'Tenant'
    },
    types: {
      additional_guarantees: 'Additional Guarantees',
      application_payment: 'Application payment',
      attributed_to_expense: 'Attributed to expense',
      commercialization_fees: 'Commercialization fees',
      delay_interest: 'Delay interests',
      deposit: 'Deposit',
      holding_deposit: 'Holding deposit',
      insurance: 'Insurance',
      monthly_rent: 'Monthly rent',
      other: 'Other',
      rent_advance: 'Rent advance',
      supplies: 'Supplies'
    }
  },
  investment: {
    alertModal: {
      deleteSuccess: 'Alert deleted successfully',
      error: 'An error occurred while creating the alert',
      form: {
        advertiser: 'Advertiser',
        all: 'All',
        both: 'Both',
        condition: 'Condition',
        elevator: 'Elevator',
        email: 'Email',
        errors: {
          maxBudget: 'Enter a valid maximum budget',
          minRentPrice: 'Enter a valid minimum monthly rental price',
          profitability: 'Enter a valid profitability'
        },
        goodCondition: 'Good condition',
        isActive: 'Active',
        location: 'City or Town',
        maxBudget: 'Maximum purchase budget',
        minRentPrice: 'Minimum monthly rental price',
        needsRenovation: 'Needs renovation',
        newConstruction: 'New construction',
        noPreference: 'No preference',
        notificationPreferences: 'Notification preferences',
        privateListingsOnly: 'Private listings only',
        profitability: 'Profitability',
        rooms: 'Rooms',
        status: 'Status',
        statusInfo: 'If you no longer wish to receive notifications with the results of this alert, you can deactivate it and/or activate it again if you want to resume your search.',
        whatsapp: 'WhatsApp',
        whereReceiveAlerts: 'Where would you like to receive alerts?',
        withElevator: 'With elevator',
        withoutElevator: 'Without elevator'
      },
      success: 'Alert created successfully',
      title: 'Set up your alert',
      updateSuccess: 'Alert updated successfully'
    },
    detail: {
      aside: {
        cashOnCash: 'Cash on cash',
        downPayment: 'Down payment',
        grossProfitability: "Gross profitability",
        monthlyCashFlow: 'Monthly cash flow',
        monthlyExpenses: 'Monthly expenses',
        monthlyMortgagePayment: 'Monthly mortgage payment',
        monthlyRent: 'Monthly rent',
        mortgageAmount: 'Mortgage amount',
        netProfitability: "Net profitability",
        propertyPrice: "Property price",
        renovationCost: "Renovation cost",
        seeFinancialData: 'See financial data',
        seePublication: 'See publication',
        taxesAndExpenses: "Taxes and expenses",
        title: "Financial data",
        totalPropertyCost: "Total property cost",
        yearlyCashFlow: "Yearly cash flow"
      },
      form: {
        averageRent: 'Average rent (from units around)',
        averageRentPriceSqrMtr: 'Average price/m2',
        community: 'Community',
        economicAnalysis: 'Economic analysis',
        estimatedAnnualExpenses: 'Estimated annual expenses',
        estimatedMonthlyRent: 'Estimated monthly rent',
        ibi: 'IBI',
        insurance: 'Insurance',
        location: 'Location',
        mortgage: 'Mortgage',
        mortgageInterest: 'Interest rate',
        mortgageYears: 'Term in years',
        otherExpenses: 'Other expenses',
        rentAnalysis: 'Rent analysis of the area',
        rentalPrice: 'Rental price',
      },
      goBack: 'Go back to the list',
      title: '{{typeOfProperty}} for sale in {{address}}',
      unitInfo: {
        baths: 'Baths',
        beds: 'Beds',
      },
    },
    emptyFilters: 'Use the area finder to find investment opportunities where you are most interested',
    emptyResults: 'There are no results in this area with the selected filters. Try changing filters or area.',
    listing: {
      card: {
        bathsCount: '{{count}} Bath',
        bathsCount_other: '{{count}} Baths',
        contactType: {
          private: 'Private',
          professional: 'Professional'
        },
        estimatedRent: 'Estimated rent',
        portalMarket: {
          fotocasa: 'Fotocasa',
          idealista: 'Idealista',
          pisosCom: 'Pisos.com'
        },
        profitability: 'Profitability',
        roomsCount: '{{count}} Room',
        roomsCount_other: '{{count}} Rooms',
        title: '{{typeOfProperty}} on {{address}}'
      },
      filter: {
        minProfitability: 'Minimum profitability',
        salePrice: 'Sale price'
      },
      newAlert: 'New alert'
    },
    myAlerts: {
      table: {
        channel: {
          both: 'WhatsApp, Email',
          email: 'Email',
          whatsapp: 'WhatsApp'
        },
        editAlert: 'Edit alert',
        emptyState: {
          description: 'You have not created any alerts yet. Create an alert to receive notifications of new properties that meet your criteria.',
        },
        header: {
          channel: 'Channel',
          location: 'Place',
          maxBudget: 'Max. Budget',
          profitability: 'Profitability',
          status: 'Status'
        },
        status: {
          active: 'Active',
          disabled: 'Disabled'
        }
      },
      title: 'My alerts'
    },
    title: 'Investment'
  },
  invitations: {
    detail: {
      description: 'You will participate in raising awareness of Zazume, the leading residential rental platform and <strong>help other owners to secure their rental</strong> before, during and after the lease.\n\n<strong>You will earn €100 for each landlord</strong> you invite and they decide to rent their apartment with our team or €25 if your guests already have tenants and decide to manage their rental with Zazume as long as the monthly rent is at least €600.\n\n<strong>Your guests will also</strong> get the same benefit as you, either €100 if they rent or €25 if they manage their rental with Zazume.',
      termsLink: 'Read the terms and conditions',
      title: 'Share Zazume and earn €100 for each owner',
      yourInvitations: {
        empty: 'No invitations to display',
        received: 'Received',
        sent: 'Sent',
        title: 'Your invitations'
      }
    },
    pageTitle: 'Invite an owner to try Zazume',
    sendByEmail: {
      button: 'Send invitation',
      label: 'Write the owner\'s email address',
      placeholder: 'Ex: name@mail.com',
      success: 'Invitation sent successfully!',
      title: 'Send invitation by e-mail'
    },
    shareByLink: {
      button: 'Copy link',
      title: 'Share your invitation link'
    },
    title: 'Invitations'
  },
  invoices: {
    export: {
      button: 'Export invoices',
      description: 'Select a date range',
      error: 'An error has occurred when exporting invoices',
      from: 'From',
      success: 'In a few minutes you will receive an email with the invoices.',
      title: 'Export invoices',
      to: 'To'
    },
    invoiceNumber: 'Invoice number',
    modal: {
      cancelButton: 'No, thanks',
      description: 'This payment has been already paid, do you want to create an invoice?',
      descriptionUnpaid: 'This payment has not been paid yet. Are you sure you want to create an invoice?',
      submitButton: 'Yes, create invoice',
      submitButton_other: 'Yes, create invoices',
      successMessage: 'Invoice created successfully',
      successMessage_other: 'Invoices created successfully',
      title: 'Do you want to create an invoice?',
      title_other: 'Do you want to create invoices?',
      updateButton: 'Yes, overwrite invoice',
      updateDescription: 'There is an existing invoice created, do you want to overwrite it?',
      updateDescription2: 'The series number will remain unchanged.',
      updateTitle: 'Do you want to overwrite the existing invoice?',
      updatedMessage: 'Invoice updated successfully',
      updatedMessage_other: 'Invoices updated successfully'
    },
    modalInTransactions: {
      description: 'A reconciled payment has been fully paid, do you want to create its invoice?',
      description_other: 'The reconciled payments have been fully paid, do you want to create their invoices?'
    },
    sendEmail: {
      description: 'Write an email and we will send you the invoice.',
      error: 'An error occurred while sending the email',
      success: 'Email sent successfully',
      title: 'Send invoice by email'
    },
    table: {
      client: 'Client',
      date: 'Date',
      empty: 'No invoices have been generated yet',
      goToClient: 'Go to client details',
      goToPayment: 'Go to payment details',
      number: 'Number',
      sendEmail: 'Send by email',
      show: 'Show invoice',
      typeFilter: {
        issued: 'Issued',
        name: 'Invoice type',
        received: 'Received'
      }
    },
    title: 'Invoices',
    update: 'Update invoice'
  },
  keyDelivery: {
    agentUpdated: 'Agent updated with success',
    cancelDescription: 'This action cannot be undone. You can schedule a new key delivery after canceling it.',
    cancelTitle: 'Cancel key delivery?',
    canceled: 'Key delivery canceled with success',
    changeAgent: 'Change',
    changeKeyDeliver: 'Change who will deliver the keys',
    changeKeyReceiver: 'Change who will receive the keys',
    dateLabel: 'Choose when will be the key\'s delivery',
    deliverFromLabel: 'Who will deliver the keys?',
    deliverToLabel: 'Who will receive the keys?',
    disabledWarning: 'Background Check step must be completed',
    forcedCreate: {
      description: 'Choosen date overlaps with viewing availability assigned to the selected agent, are you sure you want to proceed?',
      title: 'Key delivery overlaps viewings'
    },
    scheduled: 'Key delivery scheduled with success',
    title: 'Key delivery'
  },
  language: {
    default: 'Choose a language',
    en: 'English',
    es: 'Spanish',
    field: 'Language'
  },
  link: {
    goTo: 'Go to {{target}}',
    routes: {
      accounting: 'Accounting',
      application: 'Application',
      calendar: 'Calendar',
      dashboard: 'Dashboard',
      incidents: 'Incidents',
      onboarding: 'Applications',
      'owner-account-settings': 'Owner account settings',
      people: 'People',
      portfolio: 'Portfolio',
      settings: 'Settings',
      tasks: 'Tasks',
      valuations: 'Valuations'
    }
  },
  modals: {
    assign: {
      incidentPlaceholder: 'Search incidents',
      incidentRecents: 'Recents',
      incidentTitle: 'Link to an incident',
      staffHint: 'Recents',
      staffPlaceholder: 'Search staff',
      staffTitle: 'Assign staff',
      unitHint: 'Empty units',
      unitPlaceholder: 'Search units',
      unitRecentHint: 'Recent units',
      unitTitle: 'Assign unit',
      zazuHint: 'Recents',
      zazuPlaceholder: 'Search Zazu',
      zazuTitle: 'Assign Zazu'
    },
    close: {
      taskDescription: 'This action will close the task. It can be opened again at any time.',
      taskTitle: 'Close task?'
    },
    remove: {
      staffDescription: 'This will remove the selected staff from the task. You can reassign them at any time.',
      staffTitle: 'Remove staff?',
      standardDescription: 'This will remove the element and won’t be accessible anymore. This action can’t be undone.',
      standardTitle: 'Delete?',
      taskDescription: 'This will remove it form the task list and won’t be accessible anymore. This action can’t be undone.',
      taskTitle: 'Delete task?',
      unitDescription: 'This will remove the selected unit from the task. You can reassign it at any time.',
      unitTitle: 'Remove unit?',
      zazuDescription: 'This will remove the selected Zazu from the task. You can reassign them at any time.',
      zazuTitle: 'Remove Zazu?'
    },
    requestServiceUpgrade: {
      description: 'This property is currently under the <1>{{service}}</1> service. The price for this service is <1>{{price}}</1>. You can request an upgrade by selecting one of the following options.',
      pricing: '{{percentage}}% + VAT ({{amount}}) of your monthly rent',
      selectProperty: 'Select the property you\'d like to upgrade',
      selectService: 'Select one service',
      selectServiceHelper: 'You must select a property first',
      successMessage: 'You request has been been sent successfully',
      title: 'Upgrade plan'
    },
    uploadDocuments: {
      description: 'Upload document'
    },
    uploadImages: {
      description: 'Upload your images'
    },
    uploadVideos: {
      description: 'Upload your videos'
    }
  },
  nonPayment: {
    claimNumber: 'Claim number',
    coveredByInsurance: 'Covered by insurance',
    coveredByInsuranceDescription: 'This payment has been covered by the insurance company',
    debt: 'Debt',
    defaultPayments: 'Default payments',
    defaultUnitPayments: 'Unit default payments',
    deleted: 'Non-payment deleted with success',
    detail: {
      detailsTab: {
        title: 'Payment insurance details'
      },
      title: 'Non-payment'
    },
    editModal: {
      button: 'Edit non-payment',
      title: 'Edit non-payment'
    },
    form: {
      contract: {
        label: 'Select a contract',
        placeholder: 'Select a contract'
      },
      details: 'Non-payments details',
      insuranceDetails: 'Payment insurance details',
      unit: {
        label: 'Select a unit',
        placeholder: 'Select a unit'
      }
    },
    insuranceCompany: 'Insurance company',
    linkPayment: 'Link payment',
    linkedTag: 'Non-payment',
    markAsPaidByInsurance: 'Mark as paid by insurance',
    new: {
      button: 'New non-payment'
    },
    newModal: {
      button: 'Create non-payment',
      title: 'New non-payment'
    },
    paidByInsurance: 'Paid by insurance',
    paidDebt: 'Debt Settled',
    policyNumber: 'Policy number',
    statusUpdated: 'Status updated with success',
    statuses: {
      opening: 'Opening',
      pendingCollection: 'Pending collection',
      referred: 'Referred',
      resolved: 'Resolved',
      toBeProcessed: 'To be processed'
    },
    table: {
      emptyState: 'No non-payments to display'
    },
    title: 'Non-payments',
    totalDebt: 'Total debt',
    unlinkModal: {
      description: 'If you unlink this payment you will still be able to link it again in the future.',
      title: 'Are you sure you want to unlink this payment?'
    },
    updated: 'Non-payment updated with success'
  },
  notifications: {
    markAllRead: 'Mark all as read',
    noNotifications: 'Nothing to notify yet :)',
    reportCreated: 'Report successfully created',
    reportSent: 'Report was successfully sent',
    reportUpdated: 'Report successfully updated',
    seeAll: 'See all notifications',
    title: 'Notifications'
  },
  onboarding: {
    allBuildings: 'All',
    applicationDeniedBy: 'This application has been declined by',
    backgroundCheck: {
      acceptTenant: 'Accept tenant',
      acceptedTenant: 'Accepted tenant',
      completedRate: '({{rate}}% Completed)',
      contractDates: {
        button: 'Request information',
        disabledButton: 'Information resquested',
        endingDate: 'Finish rental contract',
        instructions: 'Fill the contract dates and request information to the tenant. Then, when they send all the information you would see their score and take a decision.',
        startingDate: 'Start rental contract',
        title: 'Contract Dates'
      },
      declinedTenant: 'Declined tenant',
      directAccessLink: {
        copied: 'Copied to clipboard',
        cta: 'Generate direct link',
        description: 'Send this link to tenants so they can directly access to fill their details. This link expires after 24 hours, so you will have to copy and send the link again after that time. Don\'t worry, the filled information will not disappear.',
        descriptionp2p: 'Automatically send this link to the tenants so that they can access it directly and complete their data. This link expires after 24 hours, so you will have to resend it again after that time. Don\'t worry, the complete information will not disappear.',
        expiration: '(It last 24h)',
        title: 'Directly access to fill the information'
      },
      employmentSituation: {
        business: 'Business',
        employee: 'Employee',
        erte: 'ERTE',
        freelance: 'Freelance',
        official: 'Official',
        pensions: 'Pensions',
        retired: 'Retired',
        student: 'Student',
        widowhood: 'Widowhood pension',
        withoutIncomes: 'Without incomes'
      },
      frequency: 'yearly',
      idDocumentOptions: {
        DNI: 'DNI',
        NIE: 'NIE',
        other: 'Other'
      },
      insurance: {
        button: 'Tenant accepted',
        descrition: 'Rental protection insurance covers the property’s income if their tenant falls into arrears with their monthly rent.',
        information: 'When you get the owner’s reply, you can make reflect their decision here.',
        price: 'Total: {{price}}',
        title: 'Make a decision',
        toggle: 'Contract a Rental protection Insurance'
      },
      insuranceContract: {
        button: 'Upload contract',
        information: 'Once the insurance has been contracted, remember to update the status and upload the signed contract.',
        modalDescription: 'Upload insurance protection contract',
        modalTitle: 'Upload contract',
        placeholder: 'Rental protection insurance',
        title: 'Zazume Admin'
      },
      linkToDocument: {
        description: 'To see attached documentation go to',
        tab: ' tab.',
        title: 'Rental protection Insurance has been contracted successfully'
      },
      makeDecision: 'Make a decision',
      makeDecisionSubtitle: 'When you get the applicant\'s reply, you can make reflect your decision here.',
      ownerEmail: {
        button: 'Send information',
        disabledButton: 'Information sent',
        information: 'You will send the tenant scoring and their information to the owner.',
        title: 'Send tenant’s information to owner'
      },
      participants: {
        guarantor: 'Guarantor',
        sharer: 'Sharer',
        tenant: 'Tenant'
      },
      services: {
        contracted: '{{zazumeService}} has been selected successfully',
        description: 'Please, choose one of our services before accept or decline the applicant.',
        title: 'Services'
      },
      tenantInformation: {
        title: 'Tenant information'
      },
      tenantScoring: {
        information: 'Our tenant score assesses risk by predicting the likelihood a tenant will fulfill a lease obligation. The score summarizes the potential risk of the applicant compared to others.',
        rentalInsurancePrice: {
          description: 'Rental insurance price:',
          weak: 'You cannot add a Rental protection insurance'
        },
        score: {
          strong: 'STRONG',
          weak: 'WEAK'
        },
        scoringCalculate: 'Calculate Scoring',
        scoringCalculated: 'Scoring calculated with success',
        scoringOutdatedDescription: 'The applicant has updated some information. If you want, you can recalculate the background check scoring.',
        scoringRecalculate: 'Recalculate Scoring',
        thisApplicationIs: 'This application is',
        title: 'Tenant scoring'
      },
      title: 'Background Check',
      whatsApp: {
        p2pDirectLink: 'Here you have a link to, if you want to follow the rental process, move forward with the necessary documentation: {{link}}.'
      }
    },
    because: 'because',
    boardStates: {
      backgroundCheck: 'Background Check',
      contractSigning: 'Contract Signing',
      deposit: 'Holding deposit',
      invited: 'Invited',
      leads: 'Leads',
      living: 'Living',
      moveIn: 'Move In',
      states: {
        completed: 'Completed',
        living: 'Living',
        notStarted: 'Not started',
        pendingSend: 'Pending to send',
        rejected: 'Rejected',
        started: 'Started',
        toComplete: 'To complete',
        waiting: 'Waiting',
        waitingForSigners: 'Waiting for signers',
        waitingForTenant: 'Waiting for tenant'
      },
      viewings: 'Viewings'
    },
    bulkAction: {
      archive: {
        button: 'Archive',
        description: 'You are about to archive <1>{{value}} applications.</1> This action cannot be undone.\n\nWe will only archive those requests that are rejected.\n\nYou will receive a notification once this action is completed.',
        success: 'Application archiving request sent',
        title: 'Do you want to archive these applications?'
      },
      assignApplicationManager: {
        actionTitle: 'Assign application manager',
        description: 'You are about to assign {{managerName}} as an Application Manager in <1>{{count}} application</1>.',
        description_other: 'You are about to assign {{managerName}} as an Application Manager in <1>{{count}} applications</1>.',
        success: 'Application manager assignation request sent',
        title: 'Do you want to assign {{managerName}} as an Application Manager on these applications?'
      },
      confirmationError: 'You must enter the information provided.',
      confirmationLabel: 'Write "{{value}}" to confirm',
      confirmationPlaceholder: 'Write here',
      decline: {
        button: 'Decline',
        declineAndArchive: 'Once declined, archive these applications',
        description: 'You are about to decline <1>{{value}} applications.</1> This action cannot be undone.\n\nYou will receive a notification once this action is completed.',
        reasonTitle: 'Give a reason to decline these applications:',
        success: 'Application declining request sent',
        title: 'Do you want to decline these applications?'
      },
      notificationInfo: 'You will receive a notification once this action is completed.',
      selected: 'selected',
      sendViewingReminder: {
        button: 'Send viewing reminder',
        description: 'You are about to send a viewing reminder to <1>{{value}} applications.</1>',
        errorDescription: 'In order to send a viewing reminder, it is needed that selected property has the availability of viewings configured.',
        errorTitle: 'This property does not have a viewing calendar configured.',
        success: 'Viewing reminder request sent',
        title: 'Do you want to send a viewing reminder on these applications?',
        tooltip: 'In order to send a viewing reminder, you must filter by property and step filter set to "Leads".'
      },
      unselect: 'Unselect'
    },
    comments: {
      placeholder: 'Write a comment',
      title: 'Comments'
    },
    commercialisationFee: {
      contractRejected: 'The commercialisation fee contract was rejected, before proceeding to send it again, please, cancel the requested payment.',
      description: 'Set the commercialsation fee and attach any documentation that has to be signed by tenant.',
      documentation: {
        addDocuments: 'Add documents to be signed',
        description: 'Upload the document ready to sign. We will send it to the tenant with the commercialisation fee payment request.',
        title: 'Documentation'
      },
      errorMessage: 'There is no commercialisation contract. To request payment of commercialisation fees, you must first create a contract.',
      infoMessage: 'There is a commercialisation contract pending signing, but you can proceed with the request for payment of commercialisation fees.',
      infoMessageNoContractDocument: 'There is no commercialisation contract document attached to existing contract, but you can proceed with the request for payment of commercialisation fees.',
      priceDescription: 'Commercialisation fee',
      sendSuccessContract: 'Commercialisation fee contract has been sent',
      title: 'Commercialisation fee',
      tooltips: {
        cancelled: 'Commercialisation fee cancelled',
        default: 'Commercialisation fee default',
        exported: 'Commercialisation fee exported',
        not_paid: 'Commercialization fee not paid',
        paid: 'Commercialisation fee paid',
        partially_paid: 'Commercialisation fee partially paid',
        pending_approval: 'Commercialisation fee pending for approval',
        to_validate: 'Commercialisation fee to validate',
        waiting: 'Waiting for commercialisation fee'
      }
    },
    conditionsModal: {
      title: 'Set rental conditions payments',
      total: 'Total: {{amount}}',
      updatedMessage: 'Changes updated successfully'
    },
    contactDetails: {
      editForm: {
        contactPreference: 'Contact preference',
        save: 'Save changes',
        status: 'Status',
        title: 'Edit contact preference'
      },
      filter: {
        label: 'Lead status'
      },
      form: {
        contactPreference: 'Contact preference',
        status: 'Status',
        title: 'Contact preference'
      },
      leadSection: {
        contactPreference: 'Contact preference',
        description: 'In this section you can add through which way the applicant prefers to be contacted. And if you have already been contacted or not.',
        edit: 'Edit contact preference',
        status: 'Status',
        title: 'Contact preference'
      },
      preferenceOptions: {
        phone: 'Phone',
        portalChat: 'Portal chat',
        whatsapp: 'WhatsApp'
      },
      statusOptions: {
        contactAgain: 'Contact again',
        contacted: 'Contacted',
        pending: 'Pending'
      }
    },
    contractSigning: {
      addPM: 'Add PM / owner',
      addSigner: 'Add new signer',
      addTenant: 'Add new tenant',
      andInventory: 'and the inventory ',
      applicantAdmin: 'Applicant admin',
      attachDocument: 'Attach document',
      confirmModal: {
        propertyManagerDescription: 'This will remove the Property manager / Owner for signing the contract. You can re-include it before the contract has been submitted.',
        propertyManagerTitle: 'Remove Property manager / Owner?',
        rejectBtn: 'Reject',
        rejectDescription: 'This will reject the contract permanently. This action can’t be undone.',
        rejectTextAreaTitle: 'Why do you want to reject this contract?',
        rejectTitle: 'Reject contract',
        tenantDescription: 'This will remove the Tenant for signing the contract. You can re-include it before the contract has been submitted.',
        tenantTitle: 'Remove Tenant?'
      },
      contractDates: 'Contract dates',
      contractEnd: 'Finish rental contract',
      contractInfo: 'Upload the rental contract ready to sign, set up its availability dates and designated signers. Once ready, the contract will be sent to the recipients via email in order to sign it.',
      contractSettings: {
        contractExtension: 'Extension details',
        extensionMonths: 'Months in extension: {{months}} months',
        maxExtensionMonths: 'Máx months in extension: {{months}} months',
        notificationEndContractMonths: 'Notification end contract: {{months}} months',
        notificationEndExtensionMonths: 'Notification end extensions: {{months}} months'
      },
      contractSignedInfo: 'Here you can find for a full signed copy of the lease {{inventory}}for download.',
      contractStart: 'Start rental contract',
      documentSpecs: 'Only PDF files with max size of 2MB',
      download: 'Download',
      fileTooBig: 'File max size limit is 2MB',
      filesTooBig: 'Files max size limit is 50MB',
      hasRejected: 'has rejected the contract',
      initialGraceMonthLabel: 'Initial grace period (The recurrence of incomes will begin two months after the start month of the contract)',
      inventory: {
        collapser: 'item will be attached',
        collapserCompleted: 'item has been attached',
        collapserCompleted_plural: 'items have been attached',
        collapser_plural: 'items will be attached',
        title: 'Inventory',
        toggle: 'Import the unit’s inventory as an attachment'
      },
      managementContractMessages: {
        error: {
          message1: 'The application is set up so that the monthly rental payment is directed to the Organization, however there is no management contract created. Edit to whom the rental is directed or upload a management contract to continue.'
        },
        info: {
          message1: 'There is a management contract created for this unit that will be applied, with a service of <1>{{serviceName}}</1> and a fee of <2>{{managementFee}}</2>'
        },
        warning: {
          message1: 'The application is set up so that the monthly rental payment is directed to the owner, however there is a management contract created. Edit who the rental is directed to if necessary or, if not, ignore this message.',
          message2: 'This property has a draft management contract, remember that the contract must be uploaded and signed by all parties.',
          message3: 'This property has a management contract pending signature, remember that the contract must be uploaded and signed by all parties.'
        }
      },
      mandatoryOwner: 'You must add an owner to the property to continue with the signing of the contract.',
      notifications: {
        resend: 'A reminder has been send'
      },
      recurrencesOfPayments: 'Recurrences of payments',
      reject: 'Reject',
      rentalContract: 'Rental contract',
      resend: 'Re-send',
      saveSigner: 'Save signer',
      seeContractDetails: 'See contract details',
      send: 'Send contract',
      signatureBiometrics: 'Signature with biometrics',
      signatureBiometricsInfo: 'Done with a pen stroke, just like signing on paper. It\'s fast and easy to use, and enables the signatory to be uniquely identified.',
      signatureCertificate: 'Signature with digital certificate',
      signatureCertificateInfo: 'The signatory uses their digital certificate, issued by third parties. A digital certificate is the only method that technically and legally guarantees a person\'s identity on the Internet.',
      signer: 'Signer',
      signerTitle: {
        propertyManager: 'Lessor',
        renter: 'Lessor',
        tenant: 'Signer'
      },
      signerTypes: {
        propertyManager: 'PM/Owner',
        renter: 'Lessor',
        tenant: 'Tenant'
      },
      signers: 'Signers',
      states: {
        broken: 'Broken',
        expired: 'Expired signature',
        not_signed: 'Waiting',
        partially_signed: 'Signed',
        rejected: 'Rejected',
        signed: 'Signed'
      },
      title: 'Contract signing',
      unremovableSigner: 'This signer cannot be deleted or edited',
      wrongFile: 'Only PDF'
    },
    declineApplication: 'Decline application',
    declineModal: {
      addNote: 'Add a personal note',
      alreadyLiving: 'I\'m already living in another unit',
      confirmation: 'This will decline the selected application permanently. This action cannot be undone.',
      declineTenantBtn: 'Decline tenant',
      declinedSuccess: 'Application declined successfully',
      dislikeUnit: 'I don\'t like this unit',
      explainTenantTitle: 'Explain your reasons to the tenant',
      explainTitle: 'Give a reason to decline this application:',
      infoMessage: 'Application with associated payments paid or partially paid. This is just a warning, you can continue with the operation.',
      noApplicantResponse: 'The applicant does not respond',
      notAcceptPets: 'The property doesn\'t accept pets',
      notEconomic: 'The applicant does not meet the necessary economic requirements',
      notFulfilRequirements: 'The applicant does not meet property requirements',
      notInterested: 'The applicant is not really interested',
      noteHelp: 'This is a personal note for your organization team members. Tenants cannot see it.',
      noteLabel: 'Personal note',
      otherReason: 'Other reason:',
      paymentPending: 'A partially paid payment is linked to this application. Refund it and remove reconciliations to proceed with declination.',
      paymentPendingClickable: 'View payment',
      removeNote: 'Remove personal note',
      scoringWeak: 'The scoring is too weak',
      sendNotification: 'We will send an email to the applicant to inform them their application have been declined.',
      sendNotificationLabel: 'Notify applicant by email',
      tenantTitle: 'Do you want to decline this tenant?',
      title: 'Do you want to decline this application?',
      tooShort: 'The term is too short',
      unitRented: 'The unit has already been rented',
      wrongContactDetails: 'Contact details are wrong'
    },
    delete: 'Delete',
    deleteModal: {
      description: 'Are you sure you want to delete this application? This action can\'t be undone.',
      title: 'Delete Application'
    },
    detail: {
      people: {
        addApplicationManager: 'Add App. manager',
        applicationManager: 'Application manager',
        applicationManagerAssignSuccess: 'Application manager assigned successfully',
        applicationManagerUnassignSuccess: 'Application manager unassigned successfully',
        ownerLeadTitle: 'Owner Lead',
        ownerTitle: 'Owners',
        participantsTitle: 'Participants'
      },
      searchServiceTag: 'Search service',
      tabs: {
        documentation: 'Documentation',
        onboardingSteps: 'Application steps',
        people: 'People'
      },
      tenantDetails: 'Tenant Details',
      title: 'Application details',
      unitDetails: 'Unit Details'
    },
    documentation: {
      contract: 'Contract',
      documentType: 'Document type',
      electricityCupsCIEInvoice: 'Electricity CUPS & CIE',
      gasCupsInvoice: 'Gas CUPS & CIE',
      idCard: 'ID Card',
      internetConnectionInvoice: 'Internet connection invoice',
      invoice: 'Invoice',
      other: 'Other',
      remove: 'Remove document?',
      removeDescription: 'This will remove the document permanently. This action can’t be undone.',
      selectType: 'Select a type',
      title: 'Documentation',
      uploadFile: 'Upload',
      waterSupplyInvoice: 'Water supply invoice'
    },
    documents: 'Some documents are present in this application',
    editDatesModal: {
      title: 'Update contract details'
    },
    editRentalConditions: {
      feeSectionDescription: 'This section shows the service chosen by the owner, however, if you need it, you can change it.',
      percentageFeeTip: '(over monthly rate)',
      serviceToContract: 'Service to contract:'
    },
    emptyStateIcon: '👀',
    emptyStateMessage: {
      backgroundCheck: {
        emptyStateIcon: '💯',
        item1: 'No tenants to score',
        item2: 'Well done!'
      },
      contractSigning: {
        emptyStateIcon: '👏🏻',
        item1: 'All contracts signed',
        item2: 'Well done!'
      },
      deposit: {
        emptyStateIcon: '💰',
        item1: 'Nothing more to collect',
        item2: ''
      },
      invited: {
        emptyStateIcon: '👀',
        item1: 'Your "invited" stage is empty.',
        item2: 'Invited tenants will be shown here until they accept your invite.'
      },
      leads: {
        emptyStateIcon: '📥',
        item1: 'No applicants caught yet',
        item2: ''
      },
      living: {
        emptyStateIcon: '👀',
        item1: 'Cards last 30 days after last stage has been completed.',
        item2: 'Then, they will disapear'
      },
      moveIn: {
        emptyStateIcon: '👀',
        item1: 'Your "move in" stage is empty.',
        item2: 'Tenants who want help managing their utilities will be shown here.'
      },
      payments: {
        emptyStateIcon: '💰',
        item1: 'Nothing more to collect.',
        item2: ''
      },
      viewings: {
        emptyStateIcon: '👀',
        item1: 'Your "viewings" stage is empty.',
        item2: 'Scheduled visits on your units will be shown here.'
      }
    },
    emptyStateOwner: 'To start receiving applications from interested tenants in your apartment, it is necessary to contract a tenant search service.',
    extraEmptyStateMessage: {
      invited: {
        item1: 'Add new tenants',
        item2: ' sending them an invitation or remove them clicking on the card.'
      }
    },
    holdingDeposit: {
      addContract: 'Add holding deposit contract',
      asAdditionalGuarantee: 'Use holding deposit amount as an additional guarantee',
      asAdditionalGuaranteeInfo: 'This holding deposit amount will be used as an additional guarantee',
      button: 'Send to tenant',
      contract: 'Contract',
      contractRejected: 'The holding deposit contract was rejected, before proceeding to send it again, please, cancel the requested payment.',
      depositAmount: 'Deposit amount',
      depositValue: 'Deposit value',
      depositsThisMonth: 'Deposits this month',
      errorMessage: 'There is no management contract. If you want to request a payment of holding deposit to a given IBAN, you must first create a contract.',
      excludeFromPayout: 'Exclude the holding deposit (additional guarantee) from the payout to the owner',
      intro: 'Introduce which amount is needed for the applicant to block this unit and continue with the application.',
      priceDescription: 'Holding deposit:',
      rejectContractBlocked: 'You cannot reject this contract because there is a partially or paid linked payment. To reject it, you must cancel it before.',
      sendSuccess: 'Holding deposit has been sent',
      sendSuccessContract: 'Holding deposit contract has been sent',
      title: 'Holding deposit',
      title_other: 'Holding deposits',
      tooltips: {
        cancelled: 'Holding deposit cancelled',
        default: 'Holding deposit default',
        exported: 'Holding deposit exported',
        paid: 'Holding deposit paid',
        partially_paid: 'Holding deposit partially paid',
        pending_approval: 'Holding deposit pending for approval',
        to_validate: 'Holding deposit to validate',
        waiting: 'Waiting for holding deposit'
      },
      total: 'Total: {{price}}',
      transferSlip: 'Bank transfer slip',
      uploadContract: 'Upload the holding deposit contract ready to sign. We will send it to the tenant with the holding deposit payment request.',
      uploadHelp: 'Only PDF files with max size of 2MB'
    },
    invitationSent: 'Invitation sent',
    invite: 'Invite a tenant',
    inviteModal: {
      addTenantBtn: 'Add tenant',
      addressTitle: 'Address and title',
      attach: 'Attach a file',
      buildingSelection: 'This unit belongs to a building?',
      chooseProperty: 'Choose a Property',
      chooseTenant: 'Choose a Tenant',
      chooseUnit: 'Choose a Unit',
      cupsCie: 'CUPS & CIE',
      documentsTitle: 'Unit documentation',
      existingTenant: 'Former tenant',
      existingUnit: 'Existing unit',
      files: {
        electricity: 'Electricity CUPS & CIE',
        gas: 'Gas CUPS & CIE',
        internet: 'Internet connection invoice',
        water: 'Water supply invoice'
      },
      heroImage: 'Hero image',
      internetInvoice: 'Internet connection invoice',
      inviteTenantBtn: 'Invite tenant',
      newTenant: 'New tenant',
      newUnit: 'New unit',
      onboardingEnabed: 'Do you want to include this tenant on the onboarding process?',
      onboardingOnTitle: 'Onboarding',
      scheduleViewingOptional: 'Optional',
      scheduleViewingTitle: 'Schedule a viewing',
      scheduleViewingToggle: 'Is there a viewing?',
      startingDate: 'Starting date',
      submitBtn: 'Invite tenant',
      successfullInvite: 'Tenant successfully invited',
      tenantDetails: 'Tenant details',
      title: 'Add tenant',
      unitLinkSelection: 'Do you want link this tenant to a Unit?',
      unitTitle: 'Unit detail',
      warningMessage: 'This tenant is linked with the unit',
      waterInvoice: 'Water supply invoice'
    },
    leadOrigin: {
      dashboard: 'Added manually trough dashboard',
      noMessage: 'The applicant has not sent a message.',
      title: 'Applicant origin'
    },
    moveIn: {
      adminInstructions: 'These are the services that the tenant has chosen. You can update the status of each of them when the tenant. No service will be shown until the tenant completes the selection.',
      alerts: {
        cups: {
          content: 'To process the change of supplies digitally you need to enter the CUPS data of the property in the property details.',
          cta: 'Go to property details'
        },
        tenantIban: {
          content: 'To process the change of supplies digitally, you need to enter the tenants IBAN to be able to charge the direct debit of the bills.',
          cta: 'Go to tenant details'
        }
      },
      changeModeSelect: {
        auto: 'I want to manage the change automatically',
        manual: 'I would like you to contact and advise my tenant',
        title: 'How would you like to manage the change of supplies?'
      },
      choose: 'Choose on which supplies you want to make the change of supplies',
      documentation: {
        description: 'In order to process the chosen supplies we need you to upload the following documents:',
        uploadHelp: 'Add the last invoice you have'
      },
      externalProvider: {
        errorCodes: {
          11: '• Applicant doesn\'t not have a valid phone number. <1><2>Go to Tenant details and verify it</1></2>.',
          20: '• Applicant doesn\'t have a valid ID. <1><2>Go to Tenant details and verify it</1></2>.',
          3000: '• Couldn\'t connect with external provider, please try again later.',
          86: '• Unit does not have a complete Address. <1><2>Go to Unit details and updated it</1></2>.'
        },
        errorMessage: 'An error has ocurred while trying to send utilities changes for provider:'
      },
      instructions: 'These are the services that the tenant has chosen. We will keep you updated on the status of each of them. If there is any incident, we will inform you.',
      p2p: {
        manager: {
          instructionsNoResponse: 'We have asked the owner and the tenant which utilities they want us to manage. We will notify you once they respond.',
          instructionsResponse: 'These are the services that <strong>the {{role}}</strong> has chosen. You can update the status of each of them.',
          noWantSuppliesManagement: 'The <strong>{{role}}</strong> has chosen not to manage the utilities.'
        },
        owner: {
          helpModal: {
            description: '1. Choose the services you want to contract or transfer with us. <br><br>2. We\'ll call your tenant and offer the best we can find regarding the services you want<br><br>3. We\'ll handle all the paper work and keep you updated through this step.',
            title: 'How it works'
          },
          howDoesThisWorks: 'How does this work?',
          instructionsNoResponse: 'We want to ensure that your tenants settle in as smoothly as possible. To achieve that we offer a painless service to get your property utilities up and running without almost any paperwork. That\'s why both you and your tenants can choose which services you want us to manage.',
          noThanks: 'No, thanks',
          skipped: 'The supplies management has been skipped',
          suppliesRequested: 'Meanwhile, we will keep you informed about the status of each supply. In some cases, this process may take up to a month.'
        }
      },
      preferredTimeToCall: {
        title: 'Preferred time for tenant call'
      },
      supplies: 'Supplies management',
      tenantInfo: '<1>The tenant</1> has decided not to manage the services and supplies. Below you can select the supplies for your property.',
      title: 'Move in'
    },
    newApplication: 'New application',
    newApplicationModal: {
      applicantDetails: 'Applicant details',
      chooseApplicant: 'Choose an applicant',
      create: 'Create application',
      created: 'Application created successfully',
      formerApplicant: 'Former applicant',
      newApplicant: 'New applicant'
    },
    owner: {
      applicantsInformation: {
        applicants: 'Tenants',
        description: 'We are requesting all this information from the applicants. Then, when they send all the information you would see their score and make a decision.',
        employmentSituation: {
          modal: {
            contractType: 'Contract type',
            description: 'Salary and working conditions',
            income: 'Monthly gross income',
            startDate: 'Since'
          }
        },
        title: 'Applicants information'
      }
    },
    payments: {
      backTransferSlip: 'Bank transfer slip',
      errorMessage: 'There is no management contract. To request payments, you must first create a contract.',
      form: {
        defaultMonthlyRent: 'Monthly rent amount ({{value}}{{unit}})',
        firstMonthlyRent: 'First monthly rent payment',
        otherAmount: 'Other amount ({{value}}{{unit}})'
      },
      includeDepositInPayout: 'Include it in pay-outs (for owners who must pay the deposit by themselves)',
      includeInPayout: 'Include it in pay-outs',
      info: {
        deposit: 'Holding deposit request is available on payments tab',
        payments: 'First payment request is available on payments tab'
      },
      ownerWithNoBillingDataError: {
        asOwner: 'You haven\'t completed your billing information. To continue, please, go to <1>your settings</1> and complete it before requesting payment.',
        others: 'This owner doesn\'t have their billing information complete. To proceed, you need to go to <1>the <2>owner\'s profile</2></1> and complete it before requesting payment.'
      },
      paymentCompletedMessage: 'Payment completed.',
      paymentsFormDescription: 'Introduce which amount is needed for the applicant to block this unit and continue with the application.',
      paymentsRequested: 'Payments requested with success',
      paymentsRequestedMessage: 'Payment requested. Once the necessary amount is paid, you will need to validate and reconcile this payment.',
      reconciliatePayment: 'Reconciliate payment',
      registerPayment: 'Register payment',
      requestPayments: 'Request payments',
      tooltips: {
        cancelled: 'Payment cancelled',
        default: 'Payment default',
        exported: 'Payment exported',
        paid: 'Payment paid',
        partially_paid: 'Payment partially paid',
        pending_approval: 'Payment pending for approval',
        to_validate: 'Payment to validate',
        waiting: 'Waiting for payment'
      }
    },
    personalDetails: 'Personal details',
    personalNote: 'Personal note',
    preScoringBadge: 'Applicant qualified',
    preScoringNotMatchedBadge: 'Applicant with low qualification',
    preScoringRejectReasons: {
      contractType: 'Applicant is looking for a different type of contract',
      income: 'The total income doesn\'t fulfill the minimum required',
      pets: 'Applicant has selected a different pets policy',
      tenantProfile: 'The tenant profile doesn\'t match',
      tenantsTotal: 'The total amount of tenants exceed the maximum permitted'
    },
    profileValidated: 'Profile validated',
    searchBuilding: {
      hint: 'Recent building',
      placeholder: 'Search building',
      title: 'Assign a building'
    },
    skipStep: {
      backgroundCheck: {
        button: 'Skip background check step',
        confirmationDescription: 'Are you sure you want to skip background check step? This action can’t be undone.',
        confirmationTitle: 'Skip background check step',
        description: 'Don’t you need to check the background?',
        skippedSection: 'The background check has been skipped'
      },
      commercialisationPayment: {
        button: 'Skip commercialisation fee step',
        confirmationDescription: 'Are you sure you want to skip commercialisation fee step? This action can’t be undone.',
        confirmationTitle: 'Skip lease commercialisation fee step',
        description: 'Don’t you need to request the commercialisation fee?',
        skippedSection: 'The commercialisation fee has been skipped'
      },
      confirmNo: 'No, keep me in it',
      confirmYes: 'Yes, skip it',
      contractSigning: {
        button: 'Skip lease signing step',
        confirmationDescription: 'Are you sure you want to skip lease signing step? This action can’t be undone.',
        confirmationTitle: 'Skip lease signing step',
        description: 'Don’t you need to sign the lease?',
        skippedSection: 'The lease signing has been skipped'
      },
      deposit: {
        button: 'Skip holding deposit step',
        confirmationDescription: 'Are you sure you want to skip the holding deposit step? This action can’t be undone.',
        confirmationTitle: 'Skip holding deposit step',
        description: 'Don’t you need to request a holding deposit?',
        skippedSection: 'The holding deposit has been skipped'
      },
      moveIn: {
        button: 'Skip move in step',
        confirmationDescription: 'Are you sure you want to skip the move in step? This action can’t be undone.',
        confirmationTitle: 'Skip move in step',
        description: 'Don’t you need to move in services?',
        skippedSection: 'The move in has been skipped'
      },
      payments: {
        button: 'Skip payments step',
        confirmationDescription: 'Are you sure you want to skip payments step? This action can’t be undone.',
        confirmationTitle: 'Skip payments step',
        description: 'Don’t you need to request a payment?',
        skippedSection: 'The payments has been skipped'
      },
      viewings: {
        button: 'Skip viewing step',
        confirmationDescription: 'Are you sure you want to skip viewing step? This action can’t be undone.',
        confirmationTitle: 'Skip viewing step',
        description: 'Don’t you need to schedule a viewing?',
        skippedSection: 'The viewing has been skipped'
      }
    },
    starting: 'Starting {{formattedDate}}',
    table: {
      empty: 'No applications yet :)'
    },
    title: 'Applications',
    utilities: {
      alarm: 'Alarm',
      electricity: 'Electricity',
      gas: 'Gas',
      insurance: 'Insurance',
      internet: 'Internet',
      water: 'Water'
    },
    utilityStates: {
      completed: 'Completed',
      dismissed: 'Dismissed',
      inProgress: 'In progress',
      incident: 'Incident',
      pending: 'Pending',
      processed: 'Processed',
      rejected: 'Rejected'
    },
    utilityStatesCaptions: {
      completed: '<1>Completed</1>: Registration completed in the supplier companies',
      inProgress: '<1>In progress</1>: The request has been registered successfully. We start the process.',
      incident: '<1>Incident</1>: There has been a problem, the detail is reported (for example, the new supply holder could not be contacted)',
      processed: '<1>Processed</1>: The procedure has been carried out, in the next few days the definitive registration with the supplier companies will be completed',
      rejected: '<1>Rejected</1>: The procedure has been canceled (for example, the new holder decided to carry out the procedure on his own)'
    },
    utilityStatesCaptionsTitle: 'What does each status mean?',
    viewDetails: 'View details',
    viewings: {
      addSchedulingViewingModal: {
        commercialOption: 'The visit will be carried out by an agent',
        cta: 'Schedule',
        ownerAccountOption: 'The visit will be carried out by an owner',
        title: 'Schedule a viewing'
      },
      address: 'Address',
      agent: 'Agent',
      agentAreaLabel: 'Area where the agent provides service',
      agentFields: {
        whoWillMake: 'Who will make the visit?'
      },
      agentModal: {
        changeAgent: 'Change agent',
        placeholder: 'Search agent',
        suggestedResultsHint: 'Recent agents',
        title: 'Assign agent'
      },
      agentName: 'Agent name',
      agentType: {
        commercial: 'Agent',
        ownerAccount: 'Owner'
      },
      availabilityFields: {
        addressVisibility: 'Address visibility',
        howMuchTime: 'How much time for each viewing?',
        includeVideoCall: 'Include viewings through video calls',
        schedulingThreshold: 'How far in advance can visits be scheduled?',
        slotCapacity: 'How many applicants at the same time?',
        withSlotCapacity: 'Allow simultaneous viewings (more than one applicant at the same time)'
      },
      cancelViewingModal: {
        cancelLabel: 'Go back',
        confirmationLabel: 'Yes, cancel viewing',
        description: 'Are you sure you want to cancel this viewing?',
        notification: 'Viewing has been cancelled',
        title: 'Cancel viewing'
      },
      cancelledViewing: {
        applicant: 'applicant',
        by: 'by the',
        cancelled: 'Cancelled',
        cancelledBy: 'Cancelled by',
        organization: 'organization',
        owner: 'owner',
        rescheduleViewing: 'Reschedule viewing'
      },
      commercial: 'Commercial',
      commercialModal: {
        changeCommercial: 'Change commercial',
        placeholder: 'Search commercial',
        suggestedResultsHint: 'Recent commercials',
        title: 'Assign commercial'
      },
      commercialName: 'Commercial Name',
      confirmed: 'Confirmed',
      date: 'Date',
      dateAndHour: 'Date and hour',
      door: 'Door',
      floor: 'Floor',
      hour: 'Hour',
      location: 'Location',
      markedNoSuitableSchedule: 'The tenant has marked that no schedule fits.',
      noShowViewing: {
        admin: 'organization',
        agent: 'agent',
        applicant: 'applicant',
        buildingManager: 'Building manager',
        by: 'by the',
        confirmNo: 'No, keep me in it',
        confirmYes: 'Yes, mark as not shown',
        noShow: 'Marked as not shown',
        owner: 'owner',
        sure: 'Are you sure you want to mark this viewing as not shown? This action can\'t be undone.'
      },
      owner: 'Owner',
      reviewViewing: {
        agent: {
          afterAgentNote: {
            onboardingPromoted: 'The applicant has finished this step, if finally, the applicant will rent this unit we will contact you, thanks.',
            reviewSent: 'It was communicated to the manager, thanks.'
          },
          didTheAgentDoneTheViewing: 'Did the agent done the viewing?',
          didTheOwnerDoneTheViewing: 'Did the owner done the viewing?',
          haveYouDoneThisViewing: 'Have you done the viewing?',
          message: 'Message',
          no: 'No',
          noIDidNot: 'No, I did not do it',
          placeholder: 'Ex. The tenant doesn\'t like a northeast orientation...',
          sendToManager: 'Send feedback',
          sentCTA: 'Go to Zazume.com',
          sentDescription: 'We have received your feedback successfully',
          sentTitle: 'Thank you!',
          tellUsHowItWas: 'Tell us how it was',
          yes: 'Yes',
          yesIDidIt: 'Yes, I did it'
        },
        haveYouDoneThisViewing: 'Have you done the viewing?',
        markAsNoShow: 'Mark as not shown',
        noButton: 'No, reschedule a new viewing',
        pageDescription: 'Thank you for doing the visits, please fill out the next forms that will give us feedback about if the viewings were done and other details about the viewings and the applicants.',
        progress: 'You have completed {{completed}} of {{total}} reviews',
        reviewSent: 'Review sent, waiting for the applicant',
        yesButton: 'Mark as completed'
      },
      reviewedViewing: {
        interested: {
          no: 'No',
          placeholder: 'Select an option',
          question: 'Do you want to go ahead with this unit?',
          yes: 'Yes'
        },
        message: 'Message',
        options: {
          badAppliances: 'Appliances do not suit my needs',
          badBeds: 'Beds old or in bad shape',
          badPlumbing: 'The plumbing is not working well',
          badWalls: 'The walls are in bad shape',
          badWindows: 'Windows don\'t close properly',
          higherExpectations: 'The images did not show the reality',
          noisy: 'It\'s a noisy neighborhood',
          oldFurniture: 'The furniture was old',
          question: 'Was there something you do not like?',
          tooDark: 'It was too dark',
          tooExpensive: 'Too expensive for me'
        },
        pricing: 'What is the right price for you?',
        title: 'has rated the viewing'
      },
      scheduleViewing: {
        button: 'Schedule viewing',
        description: 'Schedule a viewing with the applicant'
      },
      scheduleViewingForOwnerModal: {
        goBack: 'Go back',
        notification: 'Viewing has been scheduled',
        rescheduleDescription: 'Introduce the new date and hour for this viewing.',
        scheduleButton: 'Schedule viewing',
        scheduleDescription: 'Introduce the new date and hour for this viewing.',
        title: 'Schedule a new viewing'
      },
      scheduleViewingModal: {
        goBack: 'Go back',
        howMuchTime: 'How much time for the viewing?',
        notification: 'Viewing has been scheduled',
        rescheduleDescription: 'Introduce the new date and hour for this viewing.',
        scheduleButton: 'Schedule viewing',
        scheduleDescription: 'Introduce the new date, hour and agent for this viewing.',
        title: 'Schedule a new viewing',
        warningMessage: 'Oops! The date you have chosen overlaps with another visit at {{time}}. Choose another date and time or modify the other visit.'
      },
      scheduleViewings: 'Schedule viewings',
      states: {
        reschedule: 'To reschedule'
      },
      title: 'Viewings',
      unitTimezoneInfo: 'This calendar is using the property timezone:',
      viewInMaps: 'View location in Maps',
      viewingDetails: {
        addressVisibility: 'What address information should be visible?',
        cancelButton: 'Cancel viewing',
        face_to_face: 'Face to face',
        rescheduleButton: 'Reschedule viewing',
        scheduleTypeLabels: {
          title: 'How would you want to schedule this viewing?',
          withAvailability: 'Select from unit availability',
          withoutAvailability: 'Select a free date'
        },
        simultaneous: 'simultaneous',
        title: 'Viewing details',
        video_call: 'Video call',
        viewingTypeLabel: 'How would you like to make the viewing?',
        whoMakesVisit: 'Who makes the visit'
      }
    }
  },
  organizationServices: {
    addNew: 'Add a service',
    detail: {
      configuration: {
        instructions: {
          text: 'This will add a field to the app so the customer may be forced to add instructions to the booked service',
          title: 'Instructions required'
        },
        title: 'Service configuration'
      },
      formLabels: {
        availability: 'Availability',
        availabilityEnd: 'To',
        availabilityStart: 'From',
        cancellation: 'Cancellation policy',
        during: 'During',
        imgSupport: 'Supports .jpg and .png 2mb max',
        name: 'Give it a name',
        playbook: 'Playbook',
        price: 'Set its price',
        selectCategory: 'Select a category',
        shortDescription: 'Add a short description',
        uploadImg: 'Upload an image'
      },
      highlight: {
        add: 'Add a highlight',
        description: 'Highlight up to 6 elements of the service to help your customers understand its value.',
        title: 'Service highlights'
      },
      policy: {
        created: 'Cancellation policy created',
        hoursLabel: 'Minimum hours for cancellation',
        save: 'Save policy',
        summaryLabel: 'Summary',
        summaryPlaceholder: 'Summary to display in the app',
        updated: 'Cancellation policy updated',
        write: 'Write cancellation policy'
      },
      product: {
        add: 'Add product',
        addMore: 'Add more',
        addTitle: 'Add a new product',
        added: 'Product added successfully',
        descriptionLabel: 'Description',
        duplicate: 'Duplicate',
        edit: 'Edit product',
        editTitle: 'Edit a product',
        hidden: 'Product hidden successfully',
        hide: 'Hide product',
        imageLabel: 'Upload an image',
        information: 'Product information',
        nameLabel: 'Display name',
        priceLabel: 'Recommended price',
        productURL: 'Product URL',
        productURLHelp: 'The URL of the product checkout or more information page',
        removeTitle: 'Remove product?',
        removeWarning: 'This will remove the selected item permanently from this list and all the published services. This action can’t be undone.',
        removed: 'Product removed successfully',
        save: 'Save product',
        stripePriceIdLabel: 'Stripe price ID',
        unhidden: 'Product unhidden successfully',
        unhide: 'Unhide product',
        updated: 'Product updated successfully'
      },
      service: {
        editTitle: 'Edit a service',
        updated: 'Service updated successfully'
      },
      task: {
        add: 'Add Task',
        addTitle: 'Add a new task',
        added: 'Task added successfully',
        editTitle: 'Edit a task',
        removeTitle: 'Remove task?',
        removeWarning: 'This will remove the selected task permanently from this service. This action can’t be undone.',
        removed: 'Task removed successfully',
        updated: 'Task updated successfully'
      },
      title: 'Service detail'
    },
    new: {
      addHighlight: 'Add highlight',
      highlights: 'Highlights',
      imageHelp: 'Supports .jpg and .png 2mb max',
      instructions: 'Instructions',
      instructionsDescription: 'Enable a mandatory text field, so the user can provide specific instructions for this service.',
      newCategory: 'Select a category',
      newCustomerTarget: 'Select a customer target',
      newDescription: 'Add a short description',
      newImage: 'Upload an image',
      newInternalName: 'Give it an internal name',
      newName: 'Give it a name',
      newTitle: 'Add a new service',

      saveButton: 'Save service',
      serviceInformation: 'Service Information',
      submit: 'Add service'
    },
    noServices: 'No offered services',
    products: 'products',
    setUp: 'Set up service',
    subcategories: {
      addSubcategory: 'New subcategory',
      deleted: 'Subcategory removed successfully',
      hide: 'Hide subcategory',
      newModalSubmit: 'Add subcategory',
      newModalTitle: 'Add a new subcategory',
      removeModal: {
        description: 'This will remove the selected subcategory permanently from this service. This action can’t be undone.',
        title: 'Delete subcategory?'
      },
      rename: 'Rename subcategory',
      renameModal: {
        submitButton: 'Update subcategory',
        title: 'Rename subcategory',
        updated: 'Subcategory updated successfully'
      },
      unhide: 'Unhide subcategory'
    },
    title: 'Services'
  },
  organizations: {
    add: 'Add Organization',
    detail: {
      added: 'Organization created',
      brand: 'Brand',
      cardAllowed: 'Card allowed',
      edit: 'Edit Organization',
      edited: 'Organization edited',
      enableOnBoarding: 'On-Boarding Module Enabled',
      enabledExternalUtilitiesManagement: 'Enable utilities change with external provider',
      incidents: 'Incidents',
      incidentsTutorials: 'Incidents Tutorials',
      information: 'General Information',
      modules: 'Modules',
      onBoarding: 'On-Boarding',
      paymentCommunication: 'Send notifications to tenants',
      save: 'Save Organization',
      searchBrand: 'Search brand',
      selectBrand: 'Select a brand',
      selectZazuHelper: 'No Zazus found',
      selectZazuLabel: 'Select Zazu'
    },
    edit: 'Edit Organization',
    helper: {
      logo: 'Only JPG or PNG files with max size of {{maxFileSizeMB}}MB',
      logoLandscape: 'Only SVG files with max size of {{maxFileSizeMB}}MB',
      zazuNotFound: 'No Zazus found.'
    },
    invitationResend: 'Resend invite',
    onBoarding: {
      backgroundCheck: 'Background Check',
      contractSigning: 'Contract Signing',
      deposit: 'Deposit',
      invited: 'Invited',
      moveIn: 'Move In',
      viewings: 'Viewings'
    },
    table: {
      emptyState: 'No organizations has been created yet.',
      headers: {
        country: 'Country',
        defaultZazu: 'Default Zazu',
        email: 'Admin Email (Owner)',
        logo: 'Brand (Square aspect ratio)',
        logoLandscape: 'Brand (Landscape aspect ratio)',
        name: 'Name',
        onBoarding: 'On-Boarding'
      }
    },
    title: 'Organizations'
  },
  ownerAccount: {
    add: 'Add owner',
    addModal: {
      categories: 'Choose categories',
      currentAddress: 'Current address',
      mandatory: 'Field mandatory',
      paymentDetails: 'Payment details',
      personalDetails: 'Personal details',
      send: 'Send invite',
      source: {
        label: 'Source',
        options: {
          b2b2c: 'B2B2C',
          chat: 'Chat',
          organic: 'Organic',
          phone: 'Phone',
          referral: 'Referral'
        }
      },
      systemSettings: 'System settings',
      tenantLanguage: 'Language',
      title: 'Add new owner',
      workspaces: 'Workspaces Access (Optional)'
    },
    addOwnerAccountAndLiteUnitModal: {
      confirmation: {
        header: 'Great! You are almost done.',
        listDescription: 'Once the form is submitted, the following will happen:',
        listStep1: 'We will review everything and <1>we will contact the owner</1> to inform them of our services.',
        listStep2: 'Once we sign the contract with him or her, we will inform you so that you can receive your fees.',
        listStep3: 'Feel free if you need <1>add docs or photos</1> in the new property created',
        title: 'Confirmation'
      },
      createdMessage: 'Successful operation',
      initialConsent: {
        CTA: 'Start',
        checkboxLabel: 'Yes, I have the owner\'s consent to share their contact information with Zazume',
        description: 'When adding information about third parties on the platform we need to ensure that you have consent to share this information with the Zazume team.',
        title: 'Before starting...'
      },
      ownerData: {
        title: 'Owner data'
      },
      serviceData: {
        commercializationService: 'Rental commercialization with new tenants',
        managementService: 'Rental management with your current tenants',
        moreInformation: 'Additional information',
        notes: 'If you want, you can add any other information that we should know before contacting the owner.',
        notesPlaceholder: 'Write the information here',
        rentAdvanceService: 'Rent advance',
        serviceQuestion: 'What service is the owner interested in?',
        serviceUseDescription: 'Check as many options as you want',
        title: 'Services'
      },
      unitData: {
        address: 'Property address',
        addressQuestion: 'Do you know the address of the house?',
        addressWarning: 'If you include the address of the property, it will be created on your Properties page.',
        monthlyRent: 'Monthly rent',
        monthlyRentQuestion: 'Do you know how much the monthly rent is?',
        title: 'Property data'
      }
    },
    detail: {
      address: 'Current address',
      contactNumber: 'Contact number',
      creditorIdentifier: 'Creditor identifier',
      email: 'Email',
      firstName: 'First name',
      globalNotifications: 'Global notifications',
      iban: 'IBAN',
      idNumber: 'ID number',
      language: 'Language',
      ledgerAccount: 'Ledger Account',
      noNotificationSettings: 'The user has no notification settings',
      notificationSettings: 'Notification settings',
      options: {
        editNotificationSettings: 'Edit notification settings',
        uploadUnitLink: 'Copy upload unit link'
      },
      paymentDetails: 'Payment details',
      personalDetails: 'Personal details',
      role: 'Role',
      surname: 'Surname',
      systemSettings: 'System settings',
      tabs: {
        about: 'About',
        entities: 'Entities',
        ownersUnits: 'Owner\'s units',
        units: 'Units'
      },
      title: 'Owner detail',
      typeOfId: 'Type of ID'
    },
    editModal: {
      ledgerAccount: {
        label: 'Ledger Account',
        placeholder: 'Ledger Account',
        tooltip: 'This field is used to assign an identification number to the accounting account associated with this owner. In turn, an ledger account is a record that allows balancing the company\'s accounting.'
      },
      title: 'Edit owner'
    },
    entity: {
      add: 'Add Entity',
      addModal: {
        addCoOwner: 'Add co-owner',
        entityName: 'Entity name',
        entityNameAndType: 'Entity name and type',
        entityType: 'Entity type',
        entityTypePlaceholder: 'Select a type',
        owners: 'Owners',
        repeatedCoOwners: 'Repeated co-owners',
        selectCoOwner: 'Select co-owner',
        selectOwner: 'Select owner',
        selectOwnerAdmin: 'Select owner admin',
        title: 'Add new Entity'
      },
      detail: {
        edit: 'Edit entity',
        editOwners: 'Edit owners',
        name: 'Entity name',
        ownerAdmin: 'Owner admin',
        tabs: {
          about: 'About',
          units: 'Units'
        },
        title: 'Entity detail',
        type: 'Entity type'
      },
      editParticipantsModal: {
        markAsAdministrator: 'Mark as administrator',
        title: 'Edit owners'
      },
      table: {
        headers: {
          members: 'Members',
          units: 'Units'
        }
      },
      types: {
        communityOfGoods: 'Community Of Goods',
        ltd: 'Ltd'
      }
    },
    invitationAccepted: 'Invitation accepted',
    invitationNotSent: 'Invitation not sent',
    invitationPending: 'Invitation pending',
    invitationResend: 'Resend invite',
    invitationSent: 'Invite sent',
    invite: 'Invite an owner',
    pending: 'Pending invite',
    removeDescription: 'This will remove the selected owner permanently. This action can\'t be undone.',
    removeTitle: 'Remove owner?',
    removed: 'Owner account removed',
    roleHelpMessage: {
      ownerBasic: 'Owners with this role can see metrics and incidents on dashboard. See units. See unit documents. See residents and see inventory.',
      ownerManager: 'Owners with this role can see, edit and decline applications. See metrics and incidents on dashboard. See and edit incidents, send messages on incidents. See, add, edit and remove units. See and edit unit documents. See, add and edit residents, send invites, see and edit tenant documents. See and send messages. See inventory, create, edit and delete inventory items. See payments, request, register, edit, cancel and delete payments',
      ownerMiddle: 'Owners with this role can see applications. See metrics and incidents on dashboard. See incidents. See and add units. See unit documents. See residents, see tenant documents. See inventory and see payments.'
    },
    sendInvite: 'Send invite',
    table: {
      options: {
        remove: 'Remove',
        view: 'View owner account'
      }
    },
    title: 'Owner accounts'
  },
  ownerAccountSettings: {
    title: 'Account settings'
  },
  ownerBooking: {
    ownerBookingDetail: {
      purchaseDate: 'Purchase date',
      selectedService: 'Selected service'
    }
  },
  ownerDashboard: {
    cashflow: 'Cashflow',
    modals: {
      cashOnCash: {
        description: '\'Cash on cash\' is basically the amount of cash you are taking out of a real estate investment each year, compared to the amount of money you initially put in to buy the property. It serves to measure how quickly you are recovering your initial investment.',
        link: 'Find out what cash on cash is and what expenses you must include',
        title: 'Cash on cash'
      },
      confirm: 'Agreed',
      grossProfitability: {
        description: 'Gross profitability is the total income you get from renting a property before deducting all expenses, in other words, the money that comes in before you pay your rent.',
        link: 'Find out how the rental yield is calculated and what expenses should be included in it',
        title: 'Gross profitability'
      },
      missingInfo: {
        acquisition: {
          bold: 'Details of the acquisition of the property',
          link1: 'go to the property editing form.',
          part1: 'to do this you must'
        },
        expenses: {
          bold: 'Property expenses',
          link1: 'go to accounting page',
          part1: 'for this you need',
          part2: 'and record your housing expenses (taxes, maintenance, Zazume services, mortgage bill ....).'
        },
        incomes: {
          bold: 'Annual property income',
          link1: 'go to the Contracts tab',
          link2: 'go to Accounting page',
          part1: 'for this you need',
          part2: 'and register a contract or',
          part3: 'and add the income manually.'
        },
        mortgage: {
          bold: 'The total amount of your mortgage',
          link1: 'go to the property editing form in the acquisition details section, mortgage section.',
          part1: 'to do this you must'
        },
        title: 'In order to calculate it we need you to complete the following information:'
      },
      netProfitability: {
        description: 'The net return is the money you actually have left after subtracting all expenses, such as taxes, maintenance and other rental-related costs.',
        link: 'Find out how the rental yield is calculated and what expenses should be included in it',
        title: 'Net profitability'
      }
    },
    profitAndLoss: 'Profit and Loss',
    rentAdvance: {
      amortization: 'Amortization',
      monthlyRent: 'Monthly rent',
      sectionTitle: 'Property {{unitName}}',
      title: 'Rent advance',
      totalTranfer: 'Total transfer'
    },
    rows: {
      accounting: {
        monthly: {
          cashflow: 'Monthly Cashflow',
          profitAndLoss: 'Monthly Profit and Loss'
        },
        profitability: {
          alert: 'The information shown is an estimate based on the data entered during this year based on the income and expenses of previous months.',
          cashOnCash: {
            hint: 'Profitability',
            title: 'Cash on cash'
          },
          grossProfitability: {
            hint: 'Profitability',
            title: 'Gross profitability'
          },
          missingInfo: 'Missing information to be completed',
          netProfitability: {
            hint: 'Profitability',
            title: 'Net profitability'
          },
          rentIncomes: {
            title: 'Rents'
          },
          title: 'Annual profitability of my properties'
        },
        yearly: {
          cashflow: 'Yearly Cashflow',
          profitAndLoss: 'Yearly Profit and Loss'
        }
      }
    },
    shareProfitability: {
      tweet: {
        description_one: 'I\'ve been renting with @Zazume_es for {{years}} year with:\n🏠rental income of {{rentIncomes}} monthly\n📈a gross rentability of {{grossProfitability}} ({{netProfitability}} net)\n💰a cash on cash of {{cashOnCash}}\n\nJoin the #rental revolution and get up to 100€ through my link!',
        description_other: 'I\'ve been renting with @Zazume_es for {{years}} years with:\n🏠rental income of {{rentIncomes}} monthly\n📈a gross rentability of {{grossProfitability}} ({{netProfitability}} net)\n💰a cash on cash of {{cashOnCash}}\n\nJoin the #rental revolution and get up to 100€ through my link!',
        hashtags: 'rental'
      }
    }
  },
  ownerLead: {
    details: {
      contactNumber: 'Phone',
      name: 'Name',
      source: 'Source',
      title: 'Details',
      unit: 'Unit'
    },
    promoteModal: {
      email: 'Email',
      promote: 'Promote',
      title: 'Promote to Owner'
    },
    promoted: 'Owner lead promoted',
    removed: 'Owner lead removed',
    table: {
      headers: {
        name: 'Name',
        phone: 'Phone',
        source: 'Source',
        unit: 'Unit'
      },
      options: {
        promote: 'Promote to Owner'
      }
    },
    title: 'Owner Leads'
  },
  ownerServiceContracts: {
    broken: {
      alert: 'The contract was broken due to <1>{{brokenReason}}</1>',
      breakContractConfirmation: {
        modal: {
          title: 'Do you want to break the current {{serviceName}} contract?'
        }
      },
      reasons: {
        'custom': 'Other reason:',
        'plan-downgrade': 'Downgrade plan',
        'plan-upgrade': 'Upgrade plan',
        'recommercialisation': 'Unit recommercialisation ',
        'self-management-plan-migration': 'Owner(s) prefers self-management',
        'unsatisfied': 'Owner(s) is not satisfied with the service'
      }
    },
    commercialization: {
      addCommercialization: {
        modal: {
          about: {
            title: 'About the commercialisation service'
          },
          addService: 'Add service',
          addServiceSuccess: 'Service contract added successfully',
          contract: {
            description: 'Update the document',
            title: 'Commercialisation contract'
          },
          title: 'Add commercialisation service'
        },
        name: 'Commercialisation'
      },
      detail: {
        hideOldContracts: 'Hide old contracts',
        modal: {
          basicInformation: 'Basic information',
          monthRent: '{{months}} month rent',
          percentAnnualRent: '{{percentage}}% annual rent',
          taxes: {
            included: 'Included',
            retentions: 'Retentions {{retentions}}%',
            vat: 'VAT {{vat}}%'
          },
          title: 'Commercialization',
          typeOfService: 'Tenant search'
        },
        showOldContracts: 'Show old contracts'
      },
      title: 'Commercialization'
    },
    deleteModal: {
      description: 'Are you sure you want to delete it? This action can’t be undone.'
    },
    documentAttachedSuccessfully: 'Document attached successfully',
    management: {
      addManagement: {
        modal: {
          about: {
            title: 'About the management service'
          },
          addService: 'Add service',
          addServiceSuccess: 'Service contract added successfully',
          contract: {
            description: 'Update the document',
            title: 'Management contract'
          },
          feeSelection: 'Choose a management fee',
          infoMessage: '<1>Organization settings fee information is missing. Please, fill out this data in</1> <2>Organization settings</2> <1>If not, you can custom it in each new unit.</1>',
          paymentMethod: {
            selectBankAccount: 'Select a bank account',
            selectBankAccountPlaceholder: 'Select an organization bank account',
            title: 'Payment method'
          },
          serviceSelection: 'Choose a management service',
          title: 'Add management service'
        },
        name: 'Mangement'
      },
      detail: {
        modal: {
          basicInformation: 'Basic information',
          title: 'Management'
        }
      },
      editManagement: {
        modal: {
          editService: 'Edit service',
          editServiceSuccess: 'Service contract edited successfully',
          title: 'Edit management service'
        }
      },
      title: 'Management'
    },
    rentAdvance: {
      addRentAdvance: {
        modal: {
          about: {
            title: 'About the rental assigment.'
          },
          addService: 'Add service',
          addServiceSuccess: 'Service contract added successfully',
          contract: {
            description: 'Update the document',
            signature: 'Signature',
            signers: 'Signers',
            title: 'Rent advance contract'
          },
          initialCommission: 'Initial commission',
          monthsInAdvance: 'Months in advance',
          noContracts: 'To add a rent advance service, you must have a rent contract.',
          rentAssigment: 'Rent assignment:',
          startedAt: 'Date from which advance payments are computed',
          title: 'Add rental advance service',
          totalFee: 'Total Fee ({{value}}{{unit}}):',
          totalRent: 'Total rent:'
        },
        name: 'Rent Advance'
      },
      breakContract: {
        modal: {
          breakContract: 'Document of break contract',
          breakContractSuccess: 'Contract broke successfully',
          breakDetails: 'Break details',
          cta: 'Broke contract',
          endingContractDate: 'Ending contract date',
          form: {
            contractDescription: 'Upload the contract and select if this document has to be signed. <br/><br/>Once it has been done, this will be sent to the recipients to sign it. It will be attached to the current contract.',
            endingContractDateLabel: 'Choose when will be the last day of this contract',
            monthlyRentPaymentToLabel: 'Where should the tenant pay the monthly rent?'
          },
          monthlyRentPayment: 'Monthly rent payment',
          signature: 'Signature',
          signers: 'Signers',
          title: 'Break contract'
        }
      },
      breakContractConfirmation: {
        modal: {
          confirmationCTA: 'Yes, I want',
          description: 'Once you start the process, this action cannot be undone',
          personalNote: 'Personal note',
          personalNoteHelp: 'This is a personal note for your organization team members. Owners cannot see them.',
          reason: 'Give a reason to break this contract.',
          title: 'Do you want to break the current rent advance contract?'
        }
      },
      detail: {
        modal: {
          basicInformation: 'Basic information',
          title: 'Rent advance'
        }
      },
      listing: {
        empty: 'No rent advances created yet',
        tooltip: {
          fee: 'Fee:',
          main: 'Main:',
          total: 'Total paid:'
        }
      },
      progressIncomes: {
        description: 'Incomes associated to the Rent Advance service.'
      },
      status: {
        active: 'Active',
        activeOn: 'Active on {{date}}',
        activeUntil: 'Active until {{date}}',
        broken: 'Broken',
        draft: 'Draft',
        error: 'Error',
        expiredSignature: 'Expired signature',
        finished: 'Finished',
        pendingSignature: 'Pending signature',
        rejected: 'Rejected',
        signRejected: 'Sign rejected'
      },
      title: 'Rent advance'
    },
    types: {
      Commercialisation: 'Commercialisation',
      Management: 'Management',
      RentAdvance: 'Rent advance'
    }
  },
  ownerServices: {
    contractProductModal: {
      selectAUnit: 'Select a unit'
    },
    emptyList: 'No services available yet',
    productCard: {
      buyButton: 'Buy',
      vatNotIncluded: 'VAT not included'
    },
    productDetail: {
      breakdown: 'Breakdown',
      conditions: 'Service conditions',
      title: 'Service detail'
    }
  },
  ownerTaxes: {
    createSteps: {
      created: 'Taxes report created with success',
      deductibleExpenses: {
        deductingAmountPastYearsTitle: 'Pending deduction amount from past years reports',
        deductingAmountTitle: 'Amount applied in this statement',
        description: 'The deductible expenses of renting a home are all those that are essential to guarantee the habitability of the home. Among these would be the conservation expenses and house repair, the insurance price that protect the house (both home insurance and rental protection), and also the expenses of services or supplies such as water, gas or electricity.',
        expensesToDeductNegative: 'No, I don\'t',
        expensesToDeductPositive: 'Yes, I do',
        expensesToDeductTitle: 'Do you have expenses to deduct from past years reports?',
        title: 'Deductible expenses'
      },
      propertyAmortization: {
        acquisitionAtTitle: 'Date of property acquisition',
        cadastralValueDescription1: 'To complete the information requested in this section you will need to log in with your ID in the',
        cadastralValueDescription2: 'Sede Electronica del Catastro',
        cadastralValueTitle: 'Cadastral Value',
        deductibleExpensesTitle: 'Deductible expenses',
        otherExpensesTitle: 'Expenses inherent to the acquisition',
        taxesTitle: 'Taxes inherent to the acquisition',
        title: 'Property amortization',
        typeInheritance: 'Inheritance, legacy or donation',
        typeSale: 'Sale or exchange',
        typeTitle: 'How did you acquire the property?'
      },
      propertyData: {
        ownershipLabel: 'Percentage of ownership you have on this property',
        ownershipTitle: 'Property ownership',
        title: 'Property data',
        unitTitle: 'Select the property'
      },
      rentingData: {
        taxReductionDescription: 'According to tax law article 23.2, as a landlord, you have the right to apply a 60% reduction on the tax base of the net income from the rental of your home, as long as the lease meets these requirements:',
        taxReductionDescription1: '· The apartment must be rented as the habitual tenant residence, that is, long-term rental (from 12 months)',
        taxReductionDescription2: '· Net profit  must be positive, not negative',
        taxReductionNegative: 'No, it isn\'t',
        taxReductionPositive: 'Yes, it is entitled',
        taxReductionTitle: 'Is the lease entitled to a reduction?',
        title: 'Lease data'
      }
    },
    currentYearWarning: 'This year has not yet ended, so the following data is not reliable until the end of the year.',
    disclaimer: 'Here we show you a summary of the rental of your home(s) so that when filing your income tax return it is easier for you to have all the data and/or you can export this data and pass it on to your accountant.',
    edit: {
      actionButtonText: 'Go to property details',
      description: 'To edit this information you must do so from the details page of the selected property.',
      title: 'Do you want to edit this information?',
      titles: {
        computableIncomes: 'Computable incomes',
        deductibleExpenses: 'Deductible expenses',
        leaseInformation: 'Lease information',
        propertyAmortization: 'Property amortization',
        propertyData: 'Property data'
      },
      warnings: {
        computableIncomes: 'The computable incomes correspond to the yearly sum of monthly rent incomes.',
        deductibleExpenses: 'Some fields cannot be edited because they are an automatic expenses calculation added in the Accounting > Expenses section of the platform. To modify or add any expense, you must do it at the Accounting page.',
        leaseData: {
          1: 'Some fields cannot be edited because they are a reflection of the',
          2: 'leasing contract,',
          3: 'that due to its nature, it cannot be edited.',
          secondMessage: {
            1: 'There is no current',
            2: 'leasing contract',
            3: 'and therefore we cannot complete this section. To add a contract, go to your property details and add it in the contracts tab.'
          }
        },
        propertyDetails: {
          1: 'Some fields cannot be edited because they are a reflection of the information added in',
          2: 'To modify or add information you must go to the Property Details and click on'
        },
        propertyImprovements: {
          1: 'To edit the corresponding amount to the',
          2: 'Improvements made',
          3: 'you must do it at the Accounting page.'
        }
      }
    },
    emptyState: {
      completeButton: 'Complete information',
      description: 'To see your taxes report, please answer the questions below.',
      title: 'Some information is missing'
    },
    generalWarning: 'Some fields need to be filled in. To add them, click on <strong>Edit information</strong> in the corresponding section.',
    statementOfIncomes: {
      export: {
        button: 'Export taxes',
        description: 'Select a year',
        error: 'An error has occurred when exporting taxes',
        success: 'In a few minutes you will receive an email with taxes file.',
        title: 'Export taxes'
      },
      sections: {
        amortization: {
          errors: {
            118: 'No Acquisition Conditions found in property details',
            119: 'No Acquisition Conditions found in property details',
            120: 'No Acquisition Conditions found in property details',
            121: 'No Acquisition Conditions found in property details',
            122: 'No lease has been found for this property',
            123: 'No Cadastral Data found in property details',
            124: 'No Cadastral Data found in property details',
            125: 'No Cadastral Data found in property details',
            126: 'No Acquisition Conditions found in property details',
            127: 'No Acquisition Conditions found in property details',
            130: 'No lease has been found for this property',
            131: 'No lease has been found for this property'
          },
          labels: {
            0: 'Amount of improvements made',
            118: 'Acquisition type',
            119: 'Acquisition type: Lucrative (Inheritance, legacy, donation, etc.)',
            120: 'Property acquisition date',
            121: 'Transfer date of the property',
            122: 'Number of days the property has been leased',
            123: 'Cadastral Value',
            124: 'Cadastral Value of the construction',
            125: 'Cadastral Value of the construction/Cadastral Value x100',
            126: 'Acquisition amount',
            127: 'Expenses and taxes inherent to the acquisition',
            128: 'Amount of improvements made in previous years',
            129: 'Amount of improvements made in 2021',
            130: 'Depreciation base',
            131: 'Amortization of the property and the improvement',
            132: 'Amortization of special cases'
          },
          title: 'Property depreciation',
          tooltip: 'This information comes from the acquisition data added to the property details and the calculation of the property\'s depreciation',
          values: {
            118: {
              inheritance: 'Lucrative (Inheritance, legacy, donation, etc.)',
              sale: 'Onerous (purchase, exchange, etc.)'
            }
          }
        },
        computableGrossIncomes: {
          errors: {
            102: 'No lease has been found for this property'
          },
          labels: {
            102: 'Annual property renting income'
          },
          title: 'Computable gross incomes',
          tooltip: 'This information comes from the total sum obtained from the property monthly rent incomes'
        },
        deductibleExpenses: {
          labels: {
            0: 'Services and supplies (electricity, water and/or gas...)',
            1: 'Mortgage interest',
            103: 'Amount pending deduction from the exercises of other years',
            104: 'Amount applied in this declaration',
            105: 'Interest on the capital invested in the acquisition or improvement of the property and other financing expenses in 2021',
            106: 'Repair and conservation expenses corresponding to the financial year 2021',
            107: 'Interest and expenses of repair and conservation of 2021 that is applied in this declaration',
            108: '2021 amount pending deduction in the following 4 years',
            109: 'Community fees',
            110: 'Contract formalization expenses',
            111: 'Legal defense expenses',
            112: 'Other amounts accrued by third parties or personal services',
            113: 'Services and supplies (electricity, water, internet, gas...)',
            114: 'Premiums for insurance policies',
            115: 'Taxes, surcharges and fees',
            116: 'Doubtful balances',
            2: 'Repair and maintenance costs',
            3: 'Expansion and improvement expenses'
          },
          title: 'Deductible Expenses',
          tooltip: 'This information comes from the total sum of deductible expenses derived from the property.'
        },
        leasing: {
          errors: {
            100: 'No lease has been found for this property',
            101: 'No lease has been found for this property',
            93: 'No lease has been found for this property'
          },
          labels: {
            0: 'Lessee',
            100: 'The lease has the right to reduction (article 23.2 of the Tax Law)',
            101: 'Number of days the property has been leased',
            91: 'Lessee NIF',
            92: 'Is it a NIF from another country?',
            93: 'Lease date',
            94: 'Lessee NIF',
            95: 'Is it a NIF from another country?',
            97: 'Lessee NIF',
            98: 'Is it a NIF from another country?'
          },
          title: 'Use of the property: Leasing',
          tooltip: 'This information is a summary extracted from the lease contract'
        },
        otherData: {
          labels: {
            148: 'Other tax deductible expenses',
            149: 'Net income',
            150: 'Reduction for leasing of real estate for housing',
            151: 'Reduction for returns generated in more than 2 years or obtained in a notoriously irregular manner',
            152: 'Minimum computable yield in case of kinship',
            153: 'Withholdings and payments on account',
            154: 'Reduced Net Return on Equity'
          },
          title: 'Other data'
        },
        propertyData: {
          labels: {
            62: 'Main taxpayer',
            63: 'Ownership (%)',
            64: 'Usufruct (%)',
            66: 'Cadastral reference',
            67: 'Nature of the property',
            69: 'Home address',
            75: 'Uses of the property'
          },
          title: 'Property data',
          tooltip: 'This information comes from a summary of the data entered in the property details',
          values: {
            67: 'Urban',
            75: 'Leasing'
          }
        }
      },
      table: {
        boxNumber: 'Box number',
        concept: 'Concept',
        result: 'Result'
      }
    },
    title: 'Taxes',
    updated: 'Taxes report updated with success'
  },
  payments: {
    addLossModal: {
      lossOn: 'Loss on',
      title: 'Add loss payment'
    },
    baseModal: {
      button: 'Create',
      register: 'Register current payment',
      request: 'Request new payment',
      title: 'Create new payment'
    },
    cancelModal: {
      button: 'Cancel payment',
      footnote: 'This note will be received by the tenants in their email.',
      label: 'Add a note',
      text: 'This action can\'t be undone.',
      title: 'Do you want to cancel this payment?',
      toast: 'Payment cancelled'
    },
    createDelayInterestModal: {
      description: 'A new income will be created of {{percentage}}% of the amount of this income, with a minimum amount of {{minAmount}}, which will be released to the tenant along with the original income.',
      title: 'Create delay interest income'
    },
    delayInterestIncomeCreated: 'Delay interest income successfully created and requested',
    deleteModal: {
      description: 'Are you sure you want to delete this income? This action can\'t be undone.',
      title: 'Delete Income'
    },
    deleteReconciliationConfirmationModal: {
      descriptions: {
        footer: 'By removing this reconciliation any other reconciliated payment that shares the same bank movement will be also removed. But no worries',
        footer2: 'you can reconcile them again trought bank account page.',
        header: 'This bank movement has been used to reconcile other payments:'
      },
      list: {
        dataItem: '{{amount}} payment belong to unit: {{unit}}',
        titleItem: '"{{title}}" payment belong to unit {{unit}}'
      },
      title: 'Are you sure? Other reconciliations will be also removed'
    },
    deleteReconciliationModal: {
      deletedMessage: 'Reconciliated payment deleted',
      text: 'This will remove the selected reconciliated payment permanently. This action can’t be undone.',
      title: 'Delete reconciliated payment'
    },
    deleteToast: 'Income deleted successfully',
    detail: {
      alert: {
        cancel: {
          because: 'because',
          description: 'This payment has been canceled by'
        },
        dueDate: {
          reason: 'The due date has expired'
        },
        title: 'Payment details'
      },
      amount: 'Amount',
      attachments: {
        add: 'Add file',
        help: 'To add files in this field',
        modal: {
          added: 'Attachments added',
          help: 'Only PDF, JPG and PNG files with max size of {{maxFileSizeMB}}MB',
          title: 'Upload attachments'
        },
        remove: 'Remove attachment',
        removeContent: 'This will remove the selected attachment permanently. This action can’t be undone.',
        removeFailed: 'Cannot remove the attachment. Try later.',
        removeSuccessful: 'Attachment removed successfully',
        title: 'Attachments'
      },
      attributed: 'Attributed to expense',
      created: 'Created: {{date}}',
      createdBy: 'Created by: {{author}}',
      createdByPlatform: 'Automatization by platform',
      creditor: {
        organization: 'Organization',
        owner: 'Owner',
        platform: 'Platform'
      },
      creditorLabel: {
        bank_transfer: 'Make transfer to:',
        direct_debit: 'Make direct debit from:'
      },
      delayInterest: 'Delay interest',
      delayInterestOrigen: 'Delay interest origin',
      dropdown: {
        cancel: 'Cancel payment'
      },
      dueDate: 'Due date',
      linkedPaymentsModal: {
        description: 'Payments associated with the bank transfer.',
        details: 'Details',
        title: 'Linked payments'
      },
      n43DocumentUploadingModal: {
        description: 'Enrich the description of bank transaccions by uploading a Norma 43 file. The process may take several minutes once the file is uploaded.',
        placeHolder: 'Select bank account',
        title: 'Upload N43 file'
      },
      reconciliation: {
        add: 'Add reconciliation',
        bankDetails: {
          amount: 'Amount',
          bankAccount: 'Bank account',
          concept: 'Concept',
          date: 'Date',
          title: 'Bank movement details'
        },
        help: 'To reconciliate a payment',
        help2: 'No reconciliations yet',
        table: {
          amount: 'Amount',
          method: 'Method',
          options: {
            delete: 'Delete payment',
            download: 'Download invoice',
            edit: 'Edit payment'
          },
          paidOn: 'Paid on',
          reconciliationType: {
            automatic: 'Automatic',
            linked: 'Linked',
            loss: 'Loss',
            manual: 'Manual'
          },
          viewBankDetails: 'View bank details'
        },
        title: 'Reconciliation',
        types: {
          automatic: {
            description: 'We have reconciled it based on previous reconciliations.',
            title: 'Automatic'
          },
          linked: {
            description: 'It has been reconciled linking the payment with a bank movement.',
            title: 'Linked'
          },
          loss: {
            description: 'It has been reconciled as a loss payment.',
            title: 'Loss'
          },
          manual: {
            description: 'It has been added manually.',
            title: 'Manual'
          }
        }
      },
      referenceNumber: 'Reference Number',
      title: 'Payment detail',
      type: 'Payment type',
      viewLinkedPayments: 'View linked payments'
    },
    directDebit: {
      button: 'Direct debit',
      exports: {
        dueDateLabel: 'Due date',
        dueDatePlaceholder: 'All',
        selectCreditorLabel: 'Select creditor',
        title: 'Exports'
      },
      pendingPayments: {
        createXMLButton: 'Create XML file',
        created: 'SEPA XML created',
        selectCreditorLabel: 'Select creditor',
        subtitle: 'Select those direct debit payments to create the XML files',
        title: 'Pending payments',
        totalAmount: 'Total amount',
        totalPayments: 'Total payments',
        xmlModal: {
          description: 'To create the XML file is necessary to choose when you want that payments previously selected will be paid by direct debit. This date will also be the payment due date',
          dueDateLabel: 'Select a payment due date',
          reminder: 'Be aware that to be able to proceed those direct debit payments you need to add the XML file to the bank account.',
          title: 'Create XML Files'
        }
      },
      title: 'Direct debit orders'
    },
    directDebitData: {
      confirmDataDescription: 'To proceed payment by direct order we will need your approval filling some personal details and signing the direct order debit.',
      currentAddress: 'Current address',
      learnMore: 'Learn more about Zazume',
      signMandate: 'Sign direct debit mandate',
      zazumeInfo: 'Zazume is an app that transforms flats into serviced homes, so you can settle in comfortably and live without worries.',
      zipCode: 'ZIP code'
    },
    editLossModal: {
      editMessage: 'Loss payment edited',
      title: 'Edit loss payment'
    },
    editModal: {
      fromRecurrenceWarnings: {
        linkedToLease: '<1>This payment has been generated from an existing contract.</1> <2>Keep in mind that if you decide to modify this payment you could be breaching the original contract and this could cause legal problems and/or conflicts between the parties involved.</2>',
        warning: '<1>This payment has been generated from a recurrent income order.</1> <2>Some fields cannot be edited, if you need you can modify them in the details of the recurring income order.</2>'
      },
      successMessage: 'Income edited with success',
      title: 'Edit income',
      warningPart1: 'This income is {{stateName}}',
      warningPart2: ', so some inputs cannot be edited. If you need to edit them, you should cancel it and create a new income.'
    },
    editReconciliationModal: {
      editedMessage: 'Reconciliated payment updated',
      newAmountError: 'Amount provided exceeds total payment amount',
      title: 'Edit reconciliated payment'
    },
    export: {
      button: 'Export incomes',
      description: 'Select a date range',
      error: 'An error has occurred when exporting incomes',
      from: 'From',
      success: 'In a few minutes you will receive an email with incomes file.',
      title: 'Export incomes',
      to: 'To'
    },
    import: {
      button: 'Import incomes',
      description: 'Please follow these simple steps:<br><br><strong>1. Download the template:</strong> <CustomLink>Click here</CustomLink> to download the template in Excel format<br><br><strong>2. Complete the template:</strong> Fill in all the necessary fields with your incomes information.<br><br><strong>3. Upload the template:</strong> Once completed, export it as CSV and return here to upload the file.<br><br>If you have any trouble, you can send an email to help@zazume.com with your file filled and we will help you.',
      error: 'An error has occurred when importing incomes',
      success: 'In a few minutes you will receive a notification with the result of your import',
      title: 'Import incomes'
    },
    method: {
      bank_transfer: 'Bank transfer',
      credit_card: 'Credit card',
      direct_debit: 'Direct debit',
      methodUnavailable: 'Payment method temporarily unavailable'
    },
    newPayment: 'New payment',
    newReconciliationModal: {
      createdMessage: 'Reconciliation payment created',
      newAmountError: 'Amount provided exceeds total payment amount',
      title: 'Reconciliate payment'
    },
    paymentRequested: 'Payment requested with success',
    registerModal: {
      bodyTitle: 'Payment details',
      button: 'Save payment',
      form: {
        paidOn: {
          label: 'Paid on',
          placeholder: 'dd/mm/aaaa'
        }
      },
      messages: {
        register: {
          error: 'Register payment error',
          success: 'Register payment done'
        }
      },
      title: 'Register payment'
    },
    requestDelayInterestIncome: 'Request delay interest',
    requestModal: {
      bodyTitle: 'Payment details',
      button: 'Request payment',
      form: {
        amount: {
          label: 'Amount'
        },
        dueDate: {
          label: 'Due date',
          placeholder: 'dd/mm/aaaa'
        },
        dueDateDays: {
          label: 'Due date after',
          placeholder: 'Choose how many days'
        },
        makeFrom: {
          bank_transfer: 'Make bank transfer to',
          direct_debit: 'Make a direct debit order from'
        },
        method: {
          label: 'Payment method',
          placeholder: 'Select payment method'
        },
        ownerInfo: {
          ibanLabel: 'IBAN',
          ibanPlaceholder: 'Write owner\'s IBAN',
          idLabel: 'ID Card Number',
          idPlaceholder: 'Write owner\'s ID card number',
          nameLabel: 'Owner\'s name',
          namePlaceholder: 'Write owner\'s name'
        },
        title: {
          label: 'Add payment title'
        },
        type: {
          label: 'Payment type',
          placeholder: 'Select payment type'
        }
      },
      messages: {
        additionalGuarantee: {
          holdingDepositError: 'The holding deposit is being used as an additional guarantee. You must select at least one monthly rent.',
          holdingDepositLowerError: 'The amount must be higher than the holding deposit.'
        },
        holdingDeposit: {
          asAdditionalGuarantee: 'The holding deposit amount of {{amount}} is being used as an additional guarantee. This previous payment will be deducted from the additional guarantees created in this step.'
        },
        request: {
          error: 'Request payment error',
          success: 'Request payment done'
        },
        requestRecurrentIncome: {
          error: 'Request recurrent income error',
          success: 'Request recurrent income done'
        }
      },
      title: 'New payment',
      waringPopUp: {
        before: 'before creating a unit.',
        organizationSettings: 'Organization Settings',
        textAdmin: 'Required information is missing. Please, fill out in',
        textPm: 'Required information is missing. Please, contact with your account\'s admin to fill out all information in'
      }
    },
    requestPayment: 'Request payment',
    status: {
      cancelled: 'Cancelled',
      default: 'Default',
      exported: 'XML Exported',
      loss: 'Loss',
      paid: 'Paid',
      partial_loss: 'Partial loss',
      partially_paid: 'Partially paid',
      pending_approval: 'Pending approval',
      to_validate: 'To validate',
      waiting: 'Waiting'
    },
    table: {
      amount: 'Amount',
      buttons: {
        cancelPayment: 'Cancel payment',
        duplicatePayment: 'Duplicate payment'
      },
      dueDate: 'Due Date',
      empty: 'No payments yet :)',
      noPaymentsToShow: 'No payments to show',
      tenant: 'Tenant',
      type: 'Payment Type',
      unitId: 'Unit ID'
    },
    type: {
      additional_guarantees: 'Additional guarantees',
      application_payment: 'Application payment',
      attributed_to_expense: 'Attributed to expense',
      commercialization_fees: 'Commercialization fees',
      delay_interest: 'Delay interest',
      deposit: 'Deposit',
      holding_deposit: 'Holding deposit',
      insurance: 'Insurance',
      monthly_rent: 'Monthly rent',
      other: 'Other',
      supplies: 'Supplies'
    },
    warnings: {
      unitHasNoOwner: {
        1: 'There is no owner linked to this unit. You can choose him/her on',
        2: 'Unit Details',
        3: 'or create a new owner on',
        4: 'People page.'
      }
    }
  },
  payouts: {
    accountingNoteTable: {
      amount: 'Amount',
      date: 'Date',
      guaranteedPaymentTooltip: 'Guaranteed payment',
      status: 'Status',
      type: 'Type'
    },
    addPayout: 'Add pay-out',
    allocationPayments: {
      amount: 'Amount',
      percentage: 'Percentage',
      sectionTitle: 'Allocation payments',
      unavailableIban: 'Unavailable IBAN'
    },
    attachments: {
      addAttachment: 'Add attachment',
      title: 'Attachments'
    },
    bulkPayment: {
      success: 'Transfer done. We are verifying the payment.'
    },
    choosePeople: 'Choose people',
    concept: 'Concept',
    conceptPlaceholder: 'E.g. Owner pay-out from July',
    created: 'Created: {{date}}',
    createdBy: 'Created by: {{author}}',
    deleteModal: {
      description: 'Are you sure you want to delete this pay-out? This action can\'t be undone.',
      title: 'Delete Pay-out'
    },
    deleteToast: 'Pay-out deleted successfully',
    detail: {
      title: 'Pay-out detail'
    },
    editPayments: 'Edit payments',
    editedPayment: 'Payments modified',
    frequency: 'Frequency',
    listingEmptyMessage: 'No pay-outs created yet',
    payPayout: {
      description: 'Follow the instructions and do not close this modal',
      success: 'Pay-out paid successfully',
      title: 'Pay pay-out'
    },
    payments: {
      addPayment: 'Add payment',
      addedPayment: 'Payment added',
      deletedMessage: 'Payment deleted',
      help: 'To add a new payment'
    },
    paymentsNoAvailable: 'No payments availables',
    paymentsUnitNoAvailable: 'No payments availables, select a unit',
    payout: 'Pay-out',
    payoutDate: 'Pay-outs date',
    payoutDetail: 'Pay-out detail',
    payoutDueDate: 'Pay-out due date',
    payoutLinked: 'Pay-out linked',
    payoutSettled: 'Payments to be settled',
    payoutType: 'Pay-out type',
    payout_other: 'Pay-outs',
    recipient: 'Recipient',
    recipientTypes: {
      ownerAccount: 'Owners',
      tenant: 'Tenants'
    },
    reconcilePayouts: {
      button: 'Reconcile pay-outs',
      emptyDescription: 'Transfer done trough a multiple payment. We are verifying the payment.',
      error: 'Error in your bank account. Review your bank account balance and try again',
      modal: {
        downloadSEPA: {
          description: 'Download the SEPA file before close this window and follow the instructions.',
          instructions1: '1. Log in your bank or payment provider account',
          instructions2: '2. Choose multiple transfer option',
          instructions3: '3. Upload the SEPA file',
          instructions4: '4. Execute the SEPA transfer',
          title: 'Set the SEPA transfer'
        },
        reportSummary: {
          categoryErrors: {
            generalErrors: {
              description: 'There are some errors you need to solve before continue to the next step. You can also skip those payments deselecting them.',
              title: 'We found some errors you should check'
            },
            invalidIban: {
              description: 'Some of the pay-outs you have selected have the same IBAN, be careful not to duplicate them by mistake. If everything is okay you can continue.',
              title: 'These payments have the same IBAN account'
            },
            paidExpense: {
              description: 'Some of the pay-outs you have selected include paid expenses. Be careful, if you continue you will charge it twice. You can avoid it deselecting them.',
              title: 'Some of selected pay-outs include expenses that already have been paid'
            },
            unpaidIncome: {
              description: 'Some of the pay-outs you have selected include not paid incomes, if you continue you will advance this amount of money. You can also skip those payments deselecting them.',
              title: 'Some of the selected pay-outs include not paid incomes'
            }
          },
          description: 'Some payouts have problems, you need to fix it before continue',
          errors: {
            description: 'There are some errors you need to solve before continuing to the next step. You can also skip those payments deselecting them.',
            descriptionOrganization: 'There are some errors about your organization accounting details you need to solve before continue to the next step. ',
            descriptionSingle: 'There are some errors you need to solve before continuing to the next step.',
            main: {
              admin: {
                isAccountLinkedBankValid: 'Your linked bank account is not valid. Talk with your bank provider or with our customer service at info@zazume.com to solve it.',
                isAccountLinkedCurrencyValid: 'Your bank account doesn’t accept this currency. Talk with your bank provider or with our customer service at info@zazume.com to solve it.',
                isAccountLinkedIbanValid: 'Your linked bank account IBAN is not valid. Talk with your bank provider or with our customer service at info@zazume.com to solve it.',
                isAccountLinkedValid: 'Your bank account is not linked or synchronized. Go to Bank accounts page to solve it.',
                isBankNameValid: 'Your linked bank name is not valid. Talk with your bank provider or with our customer service at info@zazume.com to solve it.',
                isBankValid: 'Your bank is not valid. Talk with your bank provider or with our customer service at info@zazume.com to solve it.',
                isOrganizationBillingDataValid: 'Your Organization’s billing data is not updated or completed. Go to organization settings to solve it.',
                isOrganizationIbanValid: 'Your Organization’s IBAN number is not valid. Go to organization settings to solve it.',
                isOrganizationIdValid: 'Invalid organization',
                isPayoutListValid: 'The list of pay-outs are not correct'
              },
              manager: {
                isAccountLinkedBankValid: 'Your linked bank account is not valid. Talk with your admin to update your bank account credentials.',
                isAccountLinkedCurrencyValid: 'Your bank account doesn’t accept this currency. Talk with your bank provider or with our customer service at info@zazume.com to solve it.',
                isAccountLinkedIbanValid: 'Your linked bank account IBAN is not valid. Talk with your admin to update your bank account credentials.',
                isAccountLinkedValid: 'Your bank account is not linked or synchronized. Talk with your admin to update your bank account credentials.',
                isBankNameValid: 'Your linked bank name is not valid. Talk with your admin to update your bank account credentials.',
                isBankValid: 'Your bank is not valid. Talk with your admin to update your bank account credentials.',
                isOrganizationBillingDataValid: 'Your Organization’s billing data is not updated or completed. Talk with your admin to update the billing data on settings page. ',
                isOrganizationIbanValid: 'Your Organization’s IBAN number is not valid. Talk with your admin to update your IBAN number on settings page. ',
                isOrganizationIdValid: 'Invalid organization',
                isPayoutListValid: 'The list of pay-outs are not correct'
              }
            },
            payout: {
              global: 'Some owner’s account doesn’t have a valid name, billing address or IBAN number. Go to Owner details to solve it. For further information pass the mouse through the warning icon. ',
              hasOwnerAccountAddressValid: 'The owner account does not have a valid billing address',
              hasOwnerAccountBillingDataValid: 'The owner account does not have a billing information',
              hasOwnerAccountIbanValid: 'The owner account doesn\'t have a valid IBAN',
              hasOwnerAccountNameValid: 'The owner account doesn\'t have a valid name',
              hasTenantValidAddress: 'The tenant doesn\'t have a valid current address',
              hasTenantValidIban: 'The tenant does\'t have an IBAN',
              hasTenantValidName: 'The tenant doesn\'t have an valid name',
              hasUniqueOwnerAccountIban: 'The owner account IBAN is assigned to another account',
              isOwnerAccountValid: 'The owner account is invalid',
              isOwnershipDistributionValid: 'There are selected payouts with uncompleted ownership distribution, remove it from selection to proceed.',
              ownerHeaderIssues: 'Issues related with owner {{ownerName}}',
              payoutsBlocked: 'The unit has payments blocked.',
              withNegativeAmountError: 'There are selected payouts with a negative amount, remove it from selection to proceed.'
            },
            title: 'Ups! We found some errors you should check'
          },
          steps: {
            checkReport: {
              noPayoutErrors: 'Everything is fine, you can continue to the next step.',
              noPayoutsSelected: 'No pay-outs were selected, please go back to selection step.',
              reportError: 'Payouts report summary could not loaded, please try again.',
              title: 'Verify the selection'
            },
            confirmation: {
              description: 'Once you confirm the selected pay-outs you will be redirect to your bank account to proceed with the payment.',
              payoutsCount: '{{count}} selected payout to {{recipients}}',
              payoutsCount_other: '{{count}} selected payouts to {{recipients}}',
              payoutsToOwners: 'Pay-outs to owners',
              payoutsToTenants: 'Pay-outs to tenants',
              title: 'Confirmation',
              totalSettled: 'Total amount to be settled: {{total}}'
            },
            payment: {
              bankTransferButton: 'Make bank tranfer',
              bankTransferDescription: 'To continue through bank transfer payment you should continue with your linked bank account.',
              downloadSepaDescription: 'To continue with the direct debit payment you should download the XML file and upload it in your bank account.',
              methodError: 'Please select a valid payment method on previous step.',
              title: 'Payment'
            },
            selection: {
              description: 'Select a pending settlement to execute the bank transfer and reconcile it. Units with more than one owner will receive their respective percentage based on their share of ownership.',
              title: 'Select pay-outs'
            }
          },
          title: 'Invalid information',
          warnings: {
            description: 'Some of the pay-outs you have selected are not paid, if you continue you will advance this amount of money. You can also skip those payments deselecting them.',
            descriptionSingle: 'Some of the incomes inside the pay-out are not paid, if you continue you will advance this amount of money.',
            ibanDescription: 'Some of the pay-outs you have selected have the same IBAN, be careful not to duplicate them by mistake. If everything is correct you can continue.',
            ibanTitle: 'These payments have the same IBAN account',
            paidExpenseDescription: 'Some of the selected pay-outs include paid expenses. Verify if they have been paid before making the settlement so these expenses are not settled again.',
            paidExpenseTitle: 'Some selected payouts include paid expenses',
            title: 'Some of the selected payments are not paid'
          }
        },
        step1: {
          description: 'Select a pending pay-out to execute a bank or SEPA transfer to reconcile them.',
          title: 'Make a transfer to reconcile multiple pay-outs'
        },
        step2: {
          bankTransferMethod: 'Bank transfer',
          chooseBankAccount: 'Choose a bank account',
          chooseTransferMethod: 'Choose a transfer method',
          defaultBankAccount: 'Default bank account',
          description: 'Once you confirm the selected pay-outs you will be redirect to your bank account to proceed with the bank or SEPA transfer.',
          downloadSEPA: 'Download SEPA {{amount}}',
          makeATransfer: 'Make a {{amount}} transfer',
          ownerTransfers: 'Owner transfers',
          selectedPayouts: 'Selected pay-outs',
          sepaTransferMethod: 'SEPA transfer',
          tenantTransfers: 'Tenant transfers',
          title: 'Confirm selected pay-outs',
          totalAmount: 'Total amount'
        }
      }
    },
    settledPayments: 'Settled payments',
    settlementDate: 'Settlement date',
    statuses: {
      paid: 'Paid',
      partially_paid: 'Partially paid',
      to_validate: 'To validate',
      wait: 'Waiting'
    },
    statusesTooltip: {
      paid: 'Pay-out has been paid and reconciliated',
      partially_paid: 'Partially paid',
      to_validate: 'Transfer done. We are verifying the payment.',
      wait: 'To be settled'
    },
    table: {
      amount: 'Amount',
      concept: 'Concept',
      date: 'Date',
      noContentMessage: 'No pay-outs to reconcile',
      recipient: 'Recipient',
      selected: {
        plural: 'pay-outs selected {{total}}',
        singular: 'pay-out selected {{total}}'
      },
      status: 'Status',
      unitId: 'Unit Id'
    },
    toBeSettledAt: 'Starting from {{date}}',
    types: {
      recurrent: 'Recurrent pay-out',
      single: 'Single pay-out'
    },
    warnings: {
      existingNoPaidPayoutForThisUnit: 'There is an existing pay-out for this unit that has not been paid yet.'
    },
    wrongAmountError: 'Must be select a payment and total amount must be higger than 0'
  },
  pendingPayments: {
    table: {
      empty: 'To create a XML file select a creditor and a payment due date'
    }
  },
  people: {
    title: 'People'
  },
  portfolio: {
    cards: {
      available: 'Available',
      total: 'Total'
    },
    myProperties: 'My properties',
    shareRentability: 'Share profitability',
    title: 'Portfolio'
  },
  preScoring: {
    applicantQualification: 'Applicant qualification',
    furtherInformation: {
      presentation: {
        title: 'Presentation letter'
      },
      title: 'Further information'
    },
    guarantor: 'Guarantor',
    maxTenants: {
      name: 'Maximum number of tenants'
    },
    petsAllowed: 'Pets allowed?',
    petsType: {
      name: 'Pets type',
      types: {
        cat: 'Cat',
        dog: 'Dog',
        others: 'Others'
      }
    },
    rentalType: {
      name: 'Rental type',
      types: {
        longTerm: 'Long term rental',
        shortTerm: 'Half-stay rental'
      }
    },
    tenantProfile: {
      name: 'Tenant profile',
      types: {
        couple: 'Couple',
        family: 'Family',
        individual: 'Individual',
        roomates: 'Roommates',
        students: 'Students'
      }
    },
    whatsApp: {
      p2pDirectLink: 'Hello, {{tenantName}}! I\'m {{agentName}}, from Zazume. I am writing to you because we have received your application for the apartment in {{location}}. Here you can check it: {{unitUrl}}%0aThank you very much for contacting us.%0a%0a2 fundamental requirements for the application:%0a*Minimum stay of 12 months%0a*Demonstrable income*%0a%0aBefore the visit, the owner would like to know a little about the profile of their future tenants. Would you be so kind as to fill in this short questionnaire? {{prescoringUrl}}%0a%0aOnce you have completed the questionnaire you will be able to schedule a visit from the platform to check the availability that may fit you. Thank you!'
    }
  },
  products: {
    availability: 'Availability',
    edit: 'Edit product',
    price: 'Price',
    product: 'product',
    products: 'products',
    title: 'Products'
  },
  profile: {
    bio: 'Bio',
    cardPayments: 'Card payments',
    change: 'Change picture',
    email: 'Email',
    logout: 'Logout',
    modal: {
      account: {
        title: 'Account'
      },
      notifications: {
        description: 'Select your preferences by notifications type.',
        emailNotifications: 'Email notifications',
        pushNotifications: 'Push notifications',
        title: 'Notifications'
      },
      title: 'My account',
      updated: 'Profile successfully updated'
    },
    phoneNumber: 'Phone number',
    remove: 'Remove',
    role: 'Role',
    save: 'Save changes',
    surnames: 'Surnames',
    title: 'My profile',
    updateTitle: 'Profile details',
    upload: 'Upload picture',
    version: 'Version',
    videoUrl: 'Video URL'
  },
  providers: {
    common: {
      addedOn: 'Added on',
      address: 'Address',
      contact: 'Contact number',
      details: 'Provider details',
      edit: 'Edit provider',
      services: 'Services',
      taxName: 'Name or Tax name'
    },
    modals: {
      edition: {
        addButton: 'Add provider',
        services: 'Services offered',
        title: 'Add new provider'
      },
      remove: {
        description: 'This will remove the selected provider permanently. This action can\'t be undone.',
        successMessage: 'Provider removed successfully',
        title: 'Remove provider?'
      }
    },
    services: {
      antennaOperator: ' Antenna Operator',
      boilers: 'Boilers',
      carpentry: 'Carpentry',
      cee: 'Certificate of energy efficiency',
      cleaning: 'Cleaning',
      elevator: 'Elevator',
      fireExtinguishers: 'Fire Extinguishers',
      gardening: 'Gardening',
      handyman: 'Handyman',
      insurances: 'Insurances',
      intercom: 'Intercom',
      locksmith: 'Locksmith',
      others: 'Others',
      painter: 'Painter',
      personalShopper: 'Personal Shopper',
      pestControl: 'Pest Control',
      photography: 'Photography',
      plumbing: 'Plumbing',
      realStateAgent: 'Real State Agent',
      repairSkylights: 'Repair Skylights',
      supplies: 'Supplies',
      swimmingPools: 'Swimming Pools'
    },
    table: {
      add: 'Add new provider',
      emptyState: 'No providers added yet',
      headers: {
        name: 'Name',
        phone: 'Phone',
        services: 'Services'
      },
      options: {
        edit: 'Edit provider'
      }
    },
    title: 'Providers'
  },
  reconciliation: {
    newPayment: {
      button: 'Create',
      options: {
        manually: 'Add payment manually',
        withBankTransaction: 'Select payment from bank account movements'
      },
      title: 'Add payment'
    },
    newReconciliation: {
      button: 'Create',
      message: 'There are no linked bank accounts. <1>Link one from bank accounts</1> and reconcile payments with bank transactions',
      options: {
        loss: 'Add loss amount',
        manually: 'Add reconciliation manually',
        withBankTransaction: 'Reconciliation from bank account movements'
      },
      title: 'Add reconciliation'
    },
    paymentToBankTranstactions: {
      step1: {
        description: 'Select a bank movement to link to the chosen payment',
        table: {
          selectedRows: {
            plural: 'pending bank transactions selected',
            singular: 'pending bank transaction selected'
          }
        },
        title: 'Pending bank movements'
      },
      step2: {
        cta: 'Reconcile payment',
        descriptions: {
          line1_plural: 'Do you want to link these bank movements...',
          line1_singular: 'Do you want to link this bank movement...',
          line2: '... to this payment?'
        },
        messages: {
          error: 'Bank movement doesn’t fit with the total amount of this payment',
          paid: 'Congrats!  This bank movement cover the total amount of this payment. Your status will be paid',
          partiallyPaid: 'Bank movement doesn’t fit with the total amount of this payment. Your status will be partially paid'
        },
        title: 'Reconcile payments'
      }
    }
  },
  recurrent: {
    commonForm: {
      breakdown: 'Next charge breakdown',
      linkedToLease: 'Linked to a lease',
      nextCharge: {
        errors: {
          lastChargeDateExceeded: 'The next charge date is greater than the recurrence end date. Please reduce the number of cycles.'
        },
        nextChargeAmount: 'Next charge amount (VAT not included)',
        nextChargeAmountWithVAT: 'Next charge amount',
        nextChargeDate: 'Next charge amount will be on',
        skippedCycles: 'How many {{frequency}} do you want to exempt?',
        title: 'Next charge',
        unitTimeFromFrecuency: {
          annually: 'years',
          bimonthly: 'bimonthly months',
          monthly: 'months',
          quarterly: 'quarters',
          semiyearly: 'years',
          weekly: 'weeks'
        }
      },
      repeatTimes: 'For how many times?',
      repeatTimesWithFrequency: {
        annually: 'For how many years?',
        bimonthly: 'For how many bimonthly months?',
        monthly: 'For how many months?',
        quarterly: 'For how many quarters?',
        semiyearly: 'For how many semi years?',
        weekly: 'For how many weeks?'
      },
      status: {
        active: 'Active',
        ended: 'Ended'
      }
    },
    expense: {
      addRecurrentExpense: 'Add recurrent expense',
      cancelModal: {
        actionButton: 'Cancel recurrent expense',
        description: 'This recurrent expense will be cancelled. We will stop creating them from today. Expenses that had been created until this moment will not be deleted. This action cannot be undone.',
        title: 'Do you want to cancel this recurrent expense?',
        toast: 'Recurrent expense canceled'
      },
      detail: {
        generatedExpenses: 'Generated expenses',
        nextCharges: 'Next charges',
        nextChargesTitle: 'Next charges ({{max}} maximum)',
        title: 'Recurrent expense detail'
      },
      editModal: {
        messages: {
          error: 'Request recurrent expense error',
          success: 'Recurrent expense edited'
        }
      },
      editRecurrentExpense: 'Edit recurrent expense',
      form: {
        endsOn: 'Ends on',
        errorMessages: {
          invalidBeneficiary: 'Beneficiary and payer must be different'
        },
        firstChargeDate: 'Starts on',
        frequency: 'Frequency',
        frequencyPlaceholder: 'Select a schedule frequency',
        title: 'Scheduler expense'
      },
      infoMessages: {
        linkedToLease: 'This recurrent expense has been created from a contract, <1>so some inputs cannot be edited.</1>',
        nonLinkedToLease: 'This is a recurrent expense, so some inputs cannot be edited. If you want to edit them, you should cancel the recurrent expense and create it again. <1>Changes will be applied on next generation expenses from today.</1>'
      },
      pageTitle: 'Recurrent expenses',
      table: {
        cancel: 'Cancel recurrent expense',
        linkedToLeaseTooltip: 'This is an automatic recurrent expense based on the current lease. It cannot be deleted or edited.',
        listingEmptyMessage: 'There is no recurrent expenses scheduled.',
        tooltip: 'Recurrent expense'
      }
    },
    frequency: {
      annually: 'Annually',
      bimonthly: 'Bimonthly',
      each: {
        annually: '/year',
        bimonthly: '/two months',
        monthly: '/month',
        quarterly: '/quarter',
        semiyearly: '/half year',
        weekly: '/week'
      },
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      semiyearly: 'Semiyearly',
      weekly: 'Weekly'
    },
    income: {
      addRecurrentIncome: 'Add recurrent income',
      cancelModal: {
        actionButton: 'Cancel recurrent income',
        description: 'This recurrent income will be cancelled. We will stop requesting them from today. Incomes that had been created until this moment will not be deleted. This action cannot be undone.',
        title: 'Do you want to cancel this recurrent income?',
        toast: 'Recurrent income canceled'
      },
      detail: {
        breakdown: {
          fee: 'Fee',
          principal: 'Principal'
        },
        generatedIncomes: 'Generated incomes',
        nextCharges: 'Next charges',
        nextChargesTitle: 'Next charges ({{max}} maximum)',
        title: 'Recurrent income detail'

      },
      editRecurrentIncome: 'Edit recurrent income',
      form: {
        cycles: 'Cycles',
        endsOn: 'Ends on',
        firstChargeDate: 'Starts on',
        frequency: 'Frequency',
        frequencyPlaceholder: 'Select a scheduler frequency',
        nextCharge: 'Next charge',
        nextDueDate: 'Next due date',
        title: 'Scheduler income'
      },
      infoMessages: {
        linkedToLease: 'This recurrent income has been created from a contract, <1>so some inputs cannot be edited.</1>',
        nonLinkedToLease: 'This is a recurrent income, so some inputs cannot be edited. If you want to edit them, you should cancel the recurrent income and create it again. <1>Changes will be applied on next generation incomes from today.</1>'
      },
      pageTitle: 'Recurrent incomes',
      table: {
        cancel: 'Cancel recurrent income',
        linkedToLeaseTooltip: 'This is an automatic recurrent income based on the current lease. It cannot be deleted or edited.',
        listingEmptyMessage: 'There is no recurrent incomes scheduled.',
        tooltip: 'Recurrent income'
      }
    },
    payout: {
      actionButton: 'Recurrent payouts',
      cancelModal: {
        actionButton: 'Cancel recurrent payout',
        description: 'This recurrent payout will be cancelled. We will stop creating them from today. Payouts that had been created until this moment will not be deleted. This action cannot be undone.',
        title: 'Do you want to cancel this recurrent payout?',
        toast: 'Recurrent payout canceled'
      },
      detail: {
        generatedPayouts: 'Generated payouts',
        title: 'Recurrent payout detail'
      },
      pageTitle: 'Recurrent payout',
      table: {
        cancel: 'Cancel recurrent payout',
        linkedToLeaseTooltip: 'This is an automatic recurrent pay-out based on the current lease. It cannot be deleted or edited.',
        listingEmptyMessage: 'There is no recurrent payouts scheduled.',
        nextDueDate: 'Next due date',
        tooltip: 'Recurrent payout'
      }
    },
    table: {
      frequency: 'Frequency',
      nextPayment: 'Next Generation',
      payer: 'Payer'
    }
  },
  review: {
    bookingDate: '{{day}} at {{time}}',
    commentField: 'Care to share more about it?',
    ratingField: 'How would you rate the service?',

    selectRating: 'Please select a rating',
    submit: 'Submit review'
  },
  reviewPlatform: {
    requestPlatformRating: 'Request {{platform}} review ',
    requestPlatformRatingSuccess: '{{platform}} review requested'
  },
  reviewViewing: {
    button: 'Send review',
    message: 'Add a message',
    messagePlaceholder: 'Anything else you want to tell us?',
    ratingField: 'How would you rate the viewing?'
  },
  search: {
    byRecentlyInvited: 'Recently Invited',
    product: 'product',
    products: 'products',
    results: 'Search results',
    title: 'Search {{role}}'
  },
  sepa: {
    filters: {
      clear: 'Clear'
    },
    status: {
      pending: 'Pending',
      uploaded: 'Uploaded'
    },
    table: {
      buttons: {
        delete: 'Delete file',
        uploaded: 'Mark as uploaded'
      },
      deleteModal: {
        description: 'Are you sure you want to delete it? This action can’t be undone.'
      },
      empty: 'No XML file exported',
      status: {
        download: 'Download XML file',
        uploaded: 'Uploaded file'
      },
      updateModal: {
        description: 'Are you sure you want to mark it as uploaded? This action can’t be undone.'
      }
    },
    viewerModal: {
      dueDate: 'Payment due date',
      title: 'XML File details'
    }
  },
  serviceDetail: {
    serviceHighlights: 'Service highlights',
    title: 'Service detail'
  },
  services: {
    availabilityType: {
      week: 'Week',
      weekend: 'Weekend',
      workweek: 'Workweek'
    },
    cancellationPolicy: 'Cancellation policy',
    cancellations: 'Cancellations',
    categories: {
      beauty: 'Beauty',
      care: 'Care',
      cleaning: 'Cleaning',
      generic: 'Generic',
      laundry: 'Laundry',
      maintenance: 'Maintenance'
    },
    configuration: 'Configuration',
    playbook: 'Playbook',
    priceFree: 'Free',
    products: 'Products',
    servicePlaybook: 'Service playbook'
  },
  settings: {
    accounting: {
      ledgerAccount: {
        subtitle: 'Fill the ledger account for each type of income and expense. This will be used to generate the accounting entries.',
        title: 'Ledger account',
        update: {
          error: 'Error updating ledger account',
          success: 'Ledger account updated successfully'
        }
      }
    },
    accountingData: {
      accountingHelp: 'The following settings will determine the date of generation of recurrent expenses/incomes and payouts related to a lease.',
      accountingPayoutBehaviorHelp: 'The following settings will determine the behaviour of generation of recurrent payouts related to a lease.',
      delayInterest: {
        help: 'The following settings will determine the interest applied to late payments when requested.',
        minAmount: 'Minimum amount',
        percentage: 'Percentage',
        title: 'Delay interest'
      },
      includeAllIncomes: 'Include all incomes in payout generation',
      includePaidOrGuarenteedIncomes: 'Include paid or guaranteed incomes in payout generation',
      incomeDayGeneracion: 'Income day generation',
      payoutDayGeneracion: 'Payout day generation',
      subtitle: 'Recurrent payments',
      title: 'Accounting'
    },
    addIntegration: 'Add integration',
    bankAccounts: {
      accountName: 'Account name',
      addBankAccount: 'Add bank account',
      alertExpired: 'Credentials have expired. Please, resync them. Otherwise we cannot show you an updated view of your bank movements.',
      credentialsExpireOn: 'Credentials expire on',
      deleteAccount: {
        description: 'Are you sure you want to delete this account?. This action can\'t be undone.',
        removeTransactions: 'I want to remove all bank transactions related with this bank account',
        title: 'Delete account'
      },
      expireOn: 'Credentials expire on: ',
      lastSynced: 'Last synced',
      notSyncing: 'Not syncing',
      notifications: {
        accountDeleted: 'Account deleted correctly!',
        aliasUpdated: 'Account alias updated!',
        errorSync: 'Error trying to sync, try again',
        successSync: 'Synchronized successfully'
      },
      reSyncCredentials: 'Re-sync credentials',
      selectYourBank: {
        subtitle: 'Select a bank to link to the chosen payment',
        title: 'Select your bank'
      },
      subtitle: 'Set the credentials of your bank accounts to view your bank transfers',
      title: 'Bank accounts',
      warningExpired: 'Credentials expire on {{daysCount}} days. Please, resync them. Otherwise we cannot show you an updated view of your bank movements.'
    },
    billingData: {
      billing: {
        bankAccounts: {
          create: 'Add bank account',
          createModal: {
            title: 'Add bank account'
          },
          deleteButton: 'Delete bank account',
          deleteModal: {
            description: 'This will permanently remove the bank account. Don’t worry, any payments linked to this bank account will retain the same bank account information unless you edit it manually.\nThis action can’t be undone.',
            title: 'Do you want to delete this bank account?'
          },
          editButton: 'Edit bank account',
          editModal: {
            form: {
              alias: 'Bank account name',
              setAsDefault: 'Set as default bank account'
            },
            title: 'Edit bank account'
          },
          setAsDefault: 'Set as default',
          title: 'Bank accounts'
        },
        title: 'Billing'
      },
      forms: {
        legalName: 'Legal name',
        save: 'Save billing data',
        successMessage: 'Billing data succesfully updated'
      },
      invoice: {
        addYear: 'Add year',
        clickHere: 'click here',
        create: 'Create invoice serie',
        createSerieModal: {
          cta: 'Add new serie',
          title: 'Create invoice serie'
        },
        cta: 'To add an invoice serie',
        editSerie: 'Edit serie',
        includeYear: 'Include year in billing number',
        includeYearTooltip: 'The current year will be added to the invoice, and each year, the invoice number will be restored to 1',
        nextInvoiceNumber: 'Next invoice number',
        nextInvoiceNumberWillBe: 'Next invoice number will be',
        separator: 'Separator',
        serie: 'Serie',
        subtitle: 'Create your invoice series to enum them.',
        title: 'Invoices series',
        updateSerieModal: {
          cancelButton: 'No, restore it',
          cta: 'Yes, apply changes',
          title: 'Edit serie',
          warning1: 'Editing this invoice series will not change the existing invoices. Changes will be applied on new invoices.',
          warning2: 'BE AWARE - ',
          warning3: 'The number of each invoice must be unique in the fiscal year, correlative and consistent with the date of issue of the invoice. Changes on series can lead to mistakes when ordering or presenting them.'
        }
      },
      title: 'Billing data',
      vatNumber: 'VAT Number'
    },
    integrationName: '{{integrationName}} integration',
    lease: {
      leaseExtension: {
        notifications: {
          activeNotificationLabel: 'Notify end of contract (and extension)',
          subtitle: 'Notification will reach team members and contract signers.',
          title: 'Notifications'
        },
        title: 'Lease extension'
      },
      longTerm: {
        leaseExtension: {
          activeExtension: 'Add extension to the contract',
          maxMonthsExtensionLabel: 'Max. extension months',
          monthsEndContractLabel: 'End of contract (months)',
          monthsEndExtensionLabel: 'End of extension (months)',
          monthsExtensionLabel: 'Months of extension'
        },
        subtitle: 'Contracts whose duration exceeds 11 months.',
        title: 'Long term lease',
        updateSuccess: 'Updated successfully'
      },
      midTerm: {
        leaseExtension: {
          activeExtension: 'Add extension to the contract',
          maxMonthsExtensionLabel: 'Max. extension months',
          monthsExtensionLabel: 'Months of extension',
          title: 'Lease extension'
        },
        subtitle: 'Contracts whose duration does not exceed 11 months.',
        title: 'Mid-term lease',
        updateSuccess: 'Updated successfully'
      }
    },
    messagingIntegration: {
      title: 'Messaging'
    },
    modifyAccessToken: 'Modify access token',
    nonPayments: {
      automatizationSection: {
        daysSinceFirstDefault: 'How many days must pass from the first default to create a default?',
        daysSinceNextDefault: 'How many days must pass in order to add it to the default?',
        options: {
          createAutomatically: 'Yes, create them automatically',
          createManually: 'No, I prefer to create them manually'
        },
        selectionLabel: 'Do you want to automate the creation of defaults?',
        title: 'Automatize non-payment creation'
      },
      nextPaymentsSection: {
        options: {
          includeAutomatically: 'Yes, include them automatically',
          includeManually: 'No, I prefer to include late payments manually'
        },
        selectionLabel: 'Once the default is created, if new late payments appear, do you want them to be included automatically?',
        title: 'Future non-payments'
      },
      title: 'Non-payments settings'
    },
    organization: {
      signer: {
        description: 'Set up a signer to sign legal documents (such as commercialisation fee and holding deposit)',
        title: 'Organization signer'
      }
    },
    paymentData: {
      annualRentPercentage: '{{value}}% annual rent',
      comercializationFee: 'Comercialization fee',
      commercializationService: 'Commercialization service',
      creditorIdentifier: 'Creditor’s identifier',
      creditorIdentifierTooltip: 'It\'s a unique reference number for collecting payments by SEPA Direct Debit. We calculated it automatically based on ID Card Number or CIF. ',
      fees: 'Fees',
      feesHelp: 'The following settings will appear by default in each new unit details.',
      iban: 'IBAN number',
      oneMonthRent: '1 month rent',
      other: 'Other...',
      otherWithValue: 'Other: {{value}}{{unit}}',
      propertyManagementFee: 'Property management fee',
      serviceType: {
        manageCurrentTenants: 'Property Management',
        manageNewTenants: 'Commercialization',
        title: 'Initial service type intention'
      },
      tenPercentAnnualRent: '10% annual rent'
    },
    portalIntegrations: {
      enable: 'Enable synchronization',
      errors: {
        maxUnitsPublished: 'You can\'t have more properties published than you can publish. Please ',
        maxUnitsPublishedBold: 'unpublish enough properties first, or upgrade the number properties you can publish in the portal.',
        maxUnitsPublishedExceed: 'Exceed in publishing units',
        maxUnitsPublishedMinValue: 'At least a unit must be able to publish'
      },
      idealista: {
        alreadyExists: 'There is already an organization with this customer code',
        help: 'You can find this code in Idealista',
        helpMaxUnitsPublished: 'You can find it in the details of your plan in Idealista',
        label: 'Customer code',
        requiredPortalId: 'A valid customer code is required'
      },
      inbox: {
        copied: 'Copied to clipboard!',
        description: 'Redirect your portal emails to this inbox and Zazume will be able to generate your applicants for you. In a few steps you can connect your email with Zazume',
        here: 'here',
        title: 'Bring your {{portals}} leads to Zazume automatically'
      },
      inmofactory: {
        agencyId: 'Agency Id',
        agencyIdPlaceholder: 'For example: 123456',
        agentId: 'Agent Id',
        agentIdPlaceholder: 'For example: 123456',
        alreadyExists: 'There is already an organization with this api key',
        help: 'You can find this code in {{portal}}',
        helpMaxUnitsPublished: 'You can find it in the details of your plan in {{portal}}',
        label: 'Api key',
        notEnabled: '{{portal}} is not available to sync with Inmofactory',
        providedByInmofactory: 'This information is provided by {{portal}}',
        requiredAgencyId: 'A valid agency Id key required',
        requiredAgentId: 'A valid agent Id key required',
        requiredApiKey: 'A valid api key required'
      },
      lastSynced: 'Last synced',
      maxUnitsPublished: 'Number of properties you can publish',
      messages: {
        saved: 'Saved succesfully',
        success: 'Synchronization enabled succesfully'
      },
      neverSynced: 'Never was synced',
      saveAndConnect: 'Save and connect',
      subtitle: 'Connect your third-party portals accounts with Zazume to publish and distribute your units',
      synced: 'Synced',
      title: 'Integrations with portals',
      unitsPublished: 'Units published',
      useOrganizationContact: 'Use organization contact in publication',
      warning: 'You have reached the maximum number of properties published in this portal with your plan'
    },
    supportEmail: {
      contactEmail: 'Contact email',
      help: 'Configure an email to receive incidents or onboarding process notifications',
      title: 'Support email'
    },
    tabs: {
      accounting: 'Accounting',
      billing: 'Billing',
      general: 'General',
      integrations: 'Integrations',
      lease: 'Lease'
    },
    title: 'Organization settings',
    updatedMessage: 'Organization settings updated succesfully',
    zendesk: {
      helpCenterEnabled: 'Connect with help centers'
    }
  },
  signatures: {
    addAnotherSigner: 'Add another signer',
    editSigner: 'Edit signer',
    rejectWithEmailError: {
      description: 'In order to reject this document, you must enter a valid email. Try entering your own email and once the error is solved you can reject the document.',
      title: 'The document could not be rejected'
    },
    sendToSignAgain: 'Send to sign again',
    signerCount: 'Signer {{number}}',
    signerEmailError: '<1>{{email}}</1> email is invalid'
  },
  signup: {
    text: 'Hello {{name}}! Last step before logging into your account, please create a password.'
  },
  tasks: {
    assignStaff: 'Assign to Staff',
    assignUnit: 'Assign to unit',
    assignZazu: 'Assign to Zazu',
    assignedTo: 'Assigned to',
    attach: 'Attach a file',
    categories: {
      checkout: 'Checkout',
      deposits: 'Deposits',
      generic: 'Generic',
      incident: 'Incident',
      offboarding: 'Offboarding',
      onboarding_move_in: 'Move in',
      onboarding_protection_insurance: 'Protection Insurance',
      onboarding_viewing: 'Viewing',
      rent_advance: 'Rent Advance',
      service_booking: 'Service booking'
    },
    checklist: 'Checklist',
    close: 'Close task',
    closedTask: 'Task closed',
    createToast: 'Task created successfully',
    dashboard: {
      view: 'View all tasks'
    },
    deleteTask: 'Delete task',
    deleteToast: 'Task deleted successfully',
    description: 'Description',
    detail: {
      title: 'Task detail'
    },
    edit: {
      title: 'Edit task'
    },
    editDetails: 'Edit details',
    editTask: 'Edit task',
    empty: 'No scheduled tasks',
    emptyState: 'To add tasks to this section',
    incident: {
      required: 'An incident is required'
    },
    newChecklistModal: {
      addDescription: 'Add a description',
      externalLinkPlaceholder: 'Write or paste the link here',
      externalLinkToggle: 'Add an external link',
      teamMemberPlaceholder: 'Choose a team member',
      teamMemberToggle: 'Assign to a team member'
    },
    newTask: {
      add: 'Add task',
      addChecklist: 'Add subtask',
      description: 'Description not set yet',
      descriptionPlaceholder: 'Add the task description',
      linkError: 'The link must include http:// or https://',
      title: 'New task',
      titlePlaceholder: 'Task subject',
      today: 'Today'
    },
    newTaskModalSelector: {
      confirm: 'Create new task',
      createFromTemplate: 'Create task from template',
      createFromblank: 'Create new task',
      templateSelect: {
        label: 'Select a template',
        placeholder: 'Choose a template'
      }
    },
    noDescriptionPlaceholder: 'Task with no description',
    postpone: 'Snooze/Postpone',
    readMore: 'Read more',
    relatedTasks: 'Related tasks',
    reopen: 'Reopen task',
    reopenToast: 'Task has been opened successfully',
    reschedule: 'Reschedule',
    rescheduleTask: 'Reschedule task',
    rescheduleToast: 'Task rescheduled to {{date}}',
    scheduledTasks: 'Scheduled tasks',
    setAsCompleted: 'Set as completed',
    statuses: {
      completed: 'Completed',
      pending: 'Pending'
    },
    submitBtn: {
      createBtn: 'Create task',
      updateBtn: 'Update task'
    },
    tasksAndEvents: 'Tasks and events',
    time: {
      afternoon: 'Afternoon - 17:00pm',
      midday: 'Midday - 12:00pm',
      morning: 'Morning - 9:00am'
    },
    toggleLabel: 'External link',
    unassigned: 'Unassigned',
    updateToast: 'Task updated',
    viewing: {
      byApplicant: 'by applicant',
      byOrganization: 'by organization',
      calendar: 'Download calendar file',
      cancel: 'Cancel viewing',
      cancelDescription: 'Are you sure you want to cancel this viewing?',
      cancelled: 'Cancelled',
      confirmButton: 'Yes, cancel viewing',
      descriptionTitle: 'Viewing details',
      goBack: 'Go back',
      location: 'View location in maps',
      reschedule: 'Reschedule viewing',
      title: 'Viewing',
      waitingReschedule: 'Waiting to reschedule'
    },
    waitingReschedule: 'Waiting to reschedule'
  },
  taxes: {
    areTaxesIncluded: 'Are taxes included in the amount?',
    areTaxesIncludedInDiscountAmount: 'Are taxes included in the discounted amount?',
    retentions: 'Retentions',
    retentionsAmount: 'Retentions ({{amount}}%)',
    title: 'Taxes',
    vat: 'VAT',
    vat0InfoAlert: 'VAT equal to 0 means a VAT-exempt operation, it is within the scope of application of the tax, but it is exempt from its payment. If the operation is not subject to VAT, that is, it is not applicable to the operation, do not complete this field.',
    vatAmount: 'VAT ({{amount}}%)'
  },
  team: {
    access: {
      all: 'All workspaces',
      many: '{{count}} workspaces',
      one: '1 workspace'
    },
    editModal: {
      title: 'Edit {{email}}'
    },
    invite: 'Invite a member',
    inviteModal: {
      categories: 'Choose categories',
      mandatory: 'Field mandatory',
      send: 'Send invite',
      tenantLanguage: 'Language',
      title: 'Invite a new member',
      workspaces: 'Workspaces Access (Optional)'
    },
    noRoleSelected: 'No role selected',
    pending: 'Pending invite',
    removeDescription: 'This will remove the selected team member. This action can\'t be undone.',
    removeTitle: 'Remove team member?',
    removed: 'Team member removed',
    roles: {
      admin: 'Admin',
      agent: 'Agent',
      buildingManager: 'Building manager',
      commercial: 'Sales',
      managerOwner: 'Manager Owner',
      managerOwnerBasic: 'Basic Owner',
      managerOwnerMiddle: 'Advance Owner',
      owner: 'Owner',
      ownerBasic: 'Basic Owner',
      ownerManager: 'Manager Owner',
      ownerMiddle: 'Advance Owner',
      smallOwner: 'Small Owner',
      staff: 'Staff',
      tenant: 'Tenant',
      viewer: 'Viewer',
      zazume: 'Zazume'
    },
    table: {
      emptyState: 'No users have been created yet.',
      headers: {
        access: 'Access',
        name: 'Name',
        role: 'Role'
      },
      options: {
        remove: 'Remove',
        view: 'View member'
      }
    },
    tags: {
      cleaning: 'cleaning',
      concierge: 'concierge',
      maintenance: 'maintenance'
    },
    title: 'Team members'
  },
  templates: {
    addTemplate: 'Add template',
    created: 'Template created with success',
    deleted: 'Template deleted with success',
    details: 'Template details',
    editTemplate: 'Edit template',
    edited: 'Template edited with success',
    emptyState: 'No templates were created yet',
    filter: {
      type: 'Document type'
    },
    language: 'Language',
    mergeTags: {
      address: '{{entity}} address',
      annualRent: 'Annual rent',
      bank: '{{entity}} bank',
      daysBonification: 'Days bonification to transfer and cleaning',
      effectiveDate: '{{entity}} effective date',
      email: '{{entity}} email',
      endingDate: '{{entity}} ending date',
      entryMonth: 'Entry month',
      entryMonthRentAmount: 'Entry month rent amount',
      iban: '{{entity}} IBAN',
      id: '{{entity}} ID',
      name: '{{entity}} name',
      nationality: '{{entity}} nationality',
      phone: '{{entity}} phone',
      tenantsCount: 'Tenants count'
    },
    name: 'Template name',
    newTemplate: 'New template',
    placesholders: {
      language: 'Language',
      name: 'Give a name to your template',
      type: 'Select a document type'
    },
    removeMessage: 'This will remove the selected template permanently. This action can\'t be undone.',
    removeTitle: 'Remove template?',
    tabs: {
      documents: 'Documents'
    },
    template: 'Template',
    title: 'Templates',
    type: 'Document type',
    types: {
      commercialization: 'Commercialisation',
      leasing: 'Leasing',
      management: 'Management',
      other: 'Other'
    }
  },
  tenants: {
    detail: {
      aboutTheApplicant: {
        editApplicant: 'Edit applicant',
        employmentAndEconomicSituation: {
          employmentRole: {
            employee: 'Employee',
            freelance: 'Freelance',
            official: 'Official',
            pensions: 'Pensions',
            student: 'Student',
            temporalEmployee: 'Temporal employee',
            withoutIncomes: 'Without incomes'
          },
          employmentSituation: 'Employment situation',
          guarantor: 'Guarantor',
          inputPlaceholder: '€ Total net income of all tenants',
          selectAnEmploymentSituation: 'Select an employment situation',
          title: 'Employment and economic situation',
          totalNetIncome: 'Total net income'
        },
        rentalDetails: {
          contractType: {
            longTerm: 'Long term',
            shortTerm: 'Short term'
          },
          entryDate: 'Entry date',
          rentalType: 'Rental type',
          selectARentalType: 'Select a rental type',
          title: 'Rental details'
        },
        tenantsAndPetInformation: {
          adults: 'Adults',
          children: 'Children',
          doesTheApplicantHavePets: 'Does the applicant have pets?',
          petType: {
            cat: 'Cat',
            dog: 'Dog',
            others: 'Others'
          },
          pets: 'Pets',
          roomateRelation: {
            couple: 'Couple',
            family: 'Family',
            individual: 'Individual',
            roomates: 'Roomates',
            students: 'Students'
          },
          selectATenantProfile: 'Select a tenant profile',
          tenantProfile: 'Tenant profile',
          tenants: 'Tenants',
          title: 'Tenants and pet information',
          totalTenants: 'Total tenants'
        },
        title: 'Applicant prequalification'
      },
      assignProperty: 'Assign property',
      assignUnit: 'Assign unit',
      changeUnit: 'Change unit',
      edit: 'Edit Personal details',
      editNofificationSettings: 'Edit notification settings',
      editPreScoring: 'Edit applicant qualification',
      editTenantModalBtn: 'Update tenant',
      editTenantModalTitle: 'Edit tenant',
      editTenantNotificationSettingsModalTitle: 'Edit notification settings',
      editTenantPreScoringModalBtn: 'Update prequalification',
      editTenantPreScoringModalTitle: 'Edit applicant prequalification',
      emailNotifications: 'Email notifications',
      emptyUnits: 'Empty units',
      endDate: 'End date',
      inviteSent: 'Invite sent',
      inviteTip: '{{name}} doesn’t have access to the platform yet',
      inviteTipDescription: 'Make sure they have by sending an invite via email',
      notificationSettings: 'Notification Settings',
      personalDetails: 'Personal details',
      preScoringLinkButton: 'Prequalification link',
      preScoringLinkButtonSuccess: 'Link copied to clipboard',
      pushNotifications: 'Push notifications',
      removeTenantModalText: 'This will remove the selected tenant permanently. This action can’t be undone.',
      removeTenantModalTitle: 'Remove tenant?',
      removeUnit: 'Remove unit',
      resendInvite: 'Resend invite',
      searchProperties: 'Search properties',
      searchUnits: 'Search units',
      sendInvite: 'Send invite',
      startDate: 'Start date',
      suggestedProperties: 'Suggested properties',
      suggestedUnits: 'Suggested units',
      tab: {
        about: 'About',
        bookings: 'Booked services',
        docs: 'Docs',
        notificationSettings: 'Notifications'
      },
      tenantAlreadyAssigned: 'Tenant already assigned',
      title: 'Tenant detail',
      unassignUnitText: 'This will remove the tenant from the selected unit. You can reassign them at any time.',
      unassignUnitTitle: 'Remove unit?',
      unitAssignSuccess: 'Unit assigned successfully',
      unitAssigned: 'Unit assigned',
      unitUnassignSuccess: 'Unit unassigned successfully',
      updatedTenant: 'Tenant updated successfully',
      updatedTenantPreScoring: 'PreScoring updated successfully'
    },
    empty: 'No tenants added yet',
    form: {
      contactNumber: 'Contact number',
      emailError: 'Not a valid email: name@email.com',
      emailNotifications: 'Send email notifications',
      mandatory: 'Mandatory field',
      noWorkspace: 'No workspace selected',
      pushNotifications: 'Send push notifications',
      short: 'Field too short',
      tenantLanguage: 'Language',
      tenantName: 'Tenant name'
    },
    globalNotifications: 'Global notifications',
    invitationAccepted: 'Invitation accepted',
    invitationNotSent: 'Invitation not sent',
    invitationPending: 'Invitation pending',
    list: {
      addTenantBtn: 'Add a tenant',
      addTenantModalBtn: 'Add tenant',
      addTenantModalTitle: 'Add a new tenant',
      appAccess: 'App access',
      invitationNotAllowed: 'Not available',
      invitationNotAllowedTooltip: 'To send an invitation is mandatory to have an application or unit linked',
      staffAssigned: 'Staff assigned',
      successfullAdd: 'Tenant successfully added',
      tenantDetails: 'Tenant details',
      tenantRemoved: 'Tenant removed successfully',
      title: 'Tenants',
      unassigned: 'Unassigned'
    },
    types: {
      applicant: 'Applicant',
      living: 'Living',
      none: 'None'
    }
  },
  units: {
    acquisitionConditions: {
      acquisitionAt: 'Acquisition date',
      acquisitionType: {
        inheritance: 'Lucrative (Inheritance, legacy, donation, etc.)',
        sale: 'Onerous (purchase, exchange, etc.)'
      },
      mortgage: {
        amount: 'Total amount',
        amountPlaceholder: 'Ex. 300000',
        durationInMonths: 'How many months?',
        durationInMonthsPlaceholder: 'Ex. 180',
        firstChargeAt: 'First Charge',
        interestRate: 'Interest Rate',
        otherExpenses: 'Other Expenses',
        otherExpensesPlaceholder: 'Ex. 12000',
        title: 'Mortgage'
      },
      otherExpenses: 'Acquisition expenses',
      otherExpensesPlaceholder: 'Ex. 12000',
      ownership: 'What percentage of the property do you own?',
      price: 'Acquisition amount',
      pricePlaceholder: 'Ex. 360000',
      taxes: 'Acquisition taxes',
      taxesPlaceholder: 'Ex. 12000',
      title: 'Acquisition details',
      type: 'How do you acquire the property?'

    },
    address: 'Address',
    alarm: 'Alarm',
    availabilityDate: 'Availability date',
    availabilitySet: 'Set the unit availability',
    baths: 'Baths',
    block: 'Block',
    building: 'Building',
    buildingFacade: 'Building Facade',
    bulkActions: {
      assignPeople: {
        confirmationTitle: 'Do you want to assign {{name}} as a {{role}} on these units?',
        description: 'You are about to assign {{name}} as {{role}} in <1>{{unitsCount}}</1>.',
        success: 'Assignation request sent',
        title: 'Assign people'
      }
    },
    cadastralData: {
      cadastralOffice: 'Electronic Office of the Cadastre',
      cadastralValue: 'Cadastral Value',
      constructionValue: 'Construction Value',
      constructionValuePlaceholder: 'Ex. 250000',
      entryDoorMaterial: 'Entry door material',
      facadeAndStructure: 'Facade and structure',
      facadeMaterial: 'Building\'s facade material',
      introduction: 'To complete the information requested in this section, log in with your ID at the ',
      landValue: 'Land Value',
      landValuePlaceholder: 'Ex. 90000',
      location: 'Location',
      quality: 'Quality',
      reference: 'Cadastral reference number',
      referencePlaceholder: 'Ex. EGS736SGTEY36',
      restaurationYear: 'In which year?',
      restored: 'Restored',
      title: 'Cadastral Data',
      windowsMaterial: 'Windows material',
      windowsOrientation: 'Windows orientation',
      woodenStructure: 'Wooden structure'
    },
    characteristics: {
      bath: 'bath',
      bath_plural: 'baths',
      bathrooms: 'Bathrooms',
      bed: 'bed',
      bed_plural: 'beds',
      bedrooms: 'Bedrooms',
      checkbox: {
        airConditioning: 'Air conditioning',
        alarm: 'Alarm',
        balcony: 'Balcony',
        checkboxTitle: 'This unit includes:',
        checkboxTitle2: 'Other characteristics of this unit:',
        concierge: 'Concierge',
        equipedKitchen: 'Equiped kitchen',
        fridge: 'Fridge',
        garage: 'Garage',
        garden: 'Garden',
        heating: 'Heating',
        homeAppliances: 'Home appliances',
        internet: 'Internet',
        jacuzzi: 'Jacuzzi',
        lift: 'Lift',
        microwaves: 'Microwaves',
        oven: 'Oven',
        parking: 'Parking',
        parquet: 'Parquet',
        petsAllowed: 'Pets allowed',
        playground: 'Playground',
        security24h: '24h Security',
        storageRoom: 'Storage Room',
        swimmingPool: 'Swimming pool',
        terrace: 'Terrace',
        tv: 'Tv',
        wardrobe: 'Wardrobe',
        washingMachine: 'Washing machine'
      },
      condition: 'Condition',
      conditionPlaceholder: 'Select the unit condition',
      conditions: {
        new: 'New',
        ruin: 'Ruin',
        used: 'Used',
        very_good: 'Very good'
      },
      constructionYear: 'Construction Year',
      direction: 'Direction',
      directions: {
        exterior: 'Exterior',
        interior: 'Interior'
      },
      emissionsConsumptionRating: 'Emissions consumption rating',
      emissionsConsumptionUnitOfMeasure: 'CO2/m² year',
      emissionsConsumptionValue: 'Emissions consumption value',
      energyCertificate: 'Energy certificate',
      energyEfficiencyRating: 'Power efficiency rating',
      equipment: 'Equipment',
      equipmentOptions: {
        furnished: 'Furnished',
        notFurnished: 'Not furnished'
      },
      floor: {
        ground: 'Ground floor',
        label: 'Floor',
        middle: 'Middle floor',
        not_applicable: 'Not applicable',
        top: 'Top floor'
      },
      fuelType: {
        butane: 'Butane',
        electricity: 'Electricity',
        gas: 'Gas',
        gasOil: 'Gas-oil',
        label: 'Fuel type',
        propane: 'Propane',
        solar: 'Solar'
      },
      heater: 'Heater',
      heatingType: {
        central: 'Central',
        individual: 'Individual',
        label: 'Heating type',
        none: 'No heating'
      },
      mainUse: 'Select the main use of this unit',
      newDevelopment: 'New development',
      orientation: {
        east: 'East',
        north: 'North',
        orientation: 'Orientation',
        south: 'South',
        west: 'West'
      },
      otherCharacteristics: 'Other characteristics of this unit',
      powerConsumptionUnitOfMeasure: 'kWh/m² year',
      powerConsumptionValue: 'Power consumption value',
      propertyType: 'Property type',
      referenceIndex: 'Reference index',
      referenceIndexToolTip: 'You can get it for free by filling out the official form of the housing agency of Catalonia ',
      referenceIndexUnit: '€/m²',

      residentialPropertyTypes: {
        apartment: 'Apartment',
        chalet: 'Chalet',
        country_house: 'Country house',
        detached_chalet: 'Detached chalet',
        detached_house: 'Detached house',
        duplex: 'Duplex',
        duplex_apartment: 'Duplex apartment',
        duplex_flat: 'Duplex flat',
        flat: 'Flat',
        ground_house: 'Ground house',
        house: 'House',
        independent_chalet: 'Independent chalet',
        independent_house: 'Independent house',
        loft: 'Loft',
        mansion: 'Mansion',
        penthouse: 'Penthouse',
        rustic_house: 'Rustic house',
        semidetached_chalet: 'Semi-detached chalet',
        semidetached_house: 'Semi-detached house',
        studio: 'Studio',
        town_house: 'Town house',
        undefined: '-'
      },
      residentialType: 'Residential type',
      selectMainUse: 'Select the main use',
      surfaceArea: 'Surface area',
      title: 'Characteristics',
      unitIncludes: 'This unit includes'
    },
    city: 'City',
    confirmationModal: {
      description: 'This will remove the selected unit permanently, unassigning all tenants related to it. This action can’t be undone.',
      title: 'Remove unit?',
      toast: 'This unit has applications and cannot be removed'
    },
    consent: {
      filter: 'Consent',
      tag: 'No consent',
      toggle: 'Is there consent to publish?'
    },
    detail: {
      about: {
        addMultimedia: 'Add multimedia',
        addPhotos: 'Add photos',
        cadastralTitle: 'Cadastral data',
        characteristicsTitle: 'Unit characteristics',
        cupsElectricity: 'Electricity CUPS',
        cupsElectricityTooltip: '20 or 22 digit alphanumeric code that appears on the energy bill. It does not vary from one invoice to another and in Spain it begins with ES',
        cupsGas: 'Gas CUPS',
        cupsGasTooltip: '20 or 22 digit alphanumeric code that appears on the gas bill. It does not vary from one invoice to another and in Spain it begins with ES',
        editVirtualTour: 'Edit virtual tour',
        guaranteesTitle: 'Guarantees',
        infoTitle: 'Basic Information',
        insuranceAndSupplies: 'Insurances and supplies',
        locationTitle: 'Location',
        paymentMethodTitle: 'Payment Method',
        photoDeleteDescription: 'This will remove the selected photo permanently. This action can’t be undone.',
        photoDeleteDescriptionAll: 'This will remove all the photos permanently. This action can’t be undone.',
        photoDeleteTitle: 'Remove photo?',
        photoDeleteTitleAll: 'Remove all photos?',
        photoSelectPlace: 'Select a place...',
        photoUpdatedSucessfully: 'Photo updated successfully',
        photosModalDescription: 'Select the photos you want to upload to this unit.',
        photosModalTitle: 'Upload new photo',
        photosSort: {
          deleteAll: 'Delete all',
          drag: 'You can drag the pictures to order as you want.',
          dropdown: 'Edit photos',
          photo: 'photo',
          photos: 'Photos ({{count}})',
          photosLowerCase: 'photos',
          title: 'Edit photos'
        },
        photosTag: {
          appraisalplan: 'Appraisal Plan',
          archive: 'Archive',
          atmosphere: 'Atmosphere',
          balcony: 'Balcony',
          basement: 'Basement',
          bathroom: 'Bathroom',
          bedroom: 'Bedroom',
          buildingwork: 'Building work',
          cellar: 'Cellar',
          communalareas: 'Communal Areas',
          corridor: 'Corridor',
          details: 'Details',
          dining_room: 'Dining room',
          energycertificate: 'Energy Certificate',
          facade: 'Facade',
          garage: 'Garage',
          garden: 'Garden',
          gateway: 'Gateway',
          hall: 'Hall',
          kitchen: 'Kitchen',
          land: 'Land',
          lifts: 'Lifts',
          living: 'Living',
          loft: 'Loft',
          mates: 'Mates',
          meeting_room: 'Meeting Room',
          office: 'Office',
          open_plan: 'Open plan',
          patio: 'Patio',
          penthouse: 'Penthouse',
          plan: 'Plan',
          pool: 'Pool',
          porch: 'Porch',
          pressphoto: 'Press photo',
          reception: 'Reception',
          room: 'Room',
          shop_window: 'Shop window',
          staircase: 'Staircase',
          storage: 'Storage',
          storage_space: 'Storage space',
          studio: 'Studio',
          surroundings: 'Surroundings',
          terrace: 'Terrace',
          unknown: 'Unknown',
          views: 'Views',
          waitingroom: 'Waiting Room',
          walk_in_wardrobe: 'Walk in Wardrobe'
        },
        photosTitle: 'Photos',
        rentTitle: 'Rent',
        rentalConditionsTitle: 'Rental conditions',
        saleConditionsTitle: 'Sale conditions',
        unitTypeTitle: 'Unit type',
        videoSection: {
          addVideos: 'Add videos',
          dropdown: 'Edit videos',
          title: 'Videos ({{count}})',
          videoDeleteDescription: 'This will remove the selected video permanently. This action can’t be undone.',
          videoDeleteTitle: 'Remove video?'
        },
        videosModalDescription: 'Select the videos you want to upload to this unit.',
        videosModalTitle: 'Upload new video',

        viewLess: 'View less details ',
        viewMore: 'View more details',
        virtualTour: {
          delete: 'Delete virtual tour',
          deleted: 'Virtual tour successfully removed',
          description: 'Adding a virtual tour allow applicants to virtually walk through their future home as if they were there.',
          invalidUrl: 'You must enter a valid url',
          label: 'Add virtual tour URL',
          placeholder: 'Insert your virtual tour URL',
          smallTitle: 'Virtual tour',
          title: 'Edit virtual tour',
          updated: 'Virtual tour successfully updated'
        }
      },
      assign: 'Assign {{role}}',
      assignSuccess: '{{role}} assigned successfully',
      bathroom: 'bathroom',
      bathrooms: 'bathrooms',
      bedroom: 'bedroom',
      bedrooms: 'bedrooms',
      blockPayouts: {
        alert: 'The payouts for this unit are blocked',
        block: 'Block payouts',
        unblock: 'Unblock payouts'
      },
      change: 'Change {{role}}',
      changeOwnership: 'Change ownership',
      contract: {
        availabilityDates: 'Availability dates',
        basicTitle: 'Basic information',
        contractTitle: 'Contract',
        currentContract: 'Current contract',
        signers: 'Signers',
        viewOld: 'View old contracts'
      },
      edit: 'Edit Unit',
      editQualification: {
        contractTypePlaceholder: 'Select a rental type',
        employmentSituation: 'Employment and economic situation',
        ownerAllowPets: 'Does the owner allow pets?',
        rentalDetails: 'Rental details',
        tenantsPetInformation: 'Tenants and pet information',
        title: 'Edit applicant qualification',
        updateCTA: 'Update qualification'
      },
      id: 'ID: {{unitId}}',
      inventory: {
        addItem: 'Add item',
        allItems: 'All items',
        documents: 'Inventory',
        editItem: 'Edit item',
        itemAdded: 'Item added successfully',
        itemEdited: 'Item edited successfully',
        itemNotAdded: 'Cannot add the item. Try later.',
        itemNotEdited: 'Cannot edit the item. Try later.',
        itemNotRemoved: 'Cannot remove the item. Try later.',
        itemRemoved: 'Item removed successfully',
        modal: {
          input: {
            images: {
              helper: 'Only JPG or PNG files with max size of 2MB',
              label: 'Images'
            },
            name: 'Name',
            notes: 'Notes',
            place: {
              label: 'Place',
              options: {
                attic: 'Attic',
                balcony: 'Balcony',
                basement: 'Basement',
                bathroom: 'Bathroom',
                bedroom: 'Bedroom',
                cellar: 'Cellar',
                chimney: 'Chimney',
                dining_room: 'Dining room',
                garage: 'Garage',
                garden: 'Garden',
                kitchen: 'Kitchen',
                living_room: 'Living room',
                other: 'Other',
                pool: 'Pool',
                roof: 'Roof',
                room: 'Room',
                sitting_room: 'Sitting room',
                staircase: 'Staircase',
                study: 'Study',
                toilet: 'Toilet',
                window: 'Window'
              },
              placeholder: 'Select one'
            },
            price: 'Price',
            quantity: 'Quantity',
            status: {
              label: 'Status',
              options: {
                damaged: 'Damaged',
                new: 'New',
                used: 'Used'
              }
            }
          },
          modalTitle: 'Add new item',
          title: 'Item detail'
        },
        removeItem: 'Remove item',
        removeModalContent: 'This will remove the selected item permanently. This action can’t be undone.',
        table: {
          empty: 'There are no items in inventory :)',
          headers: {
            name: 'Name',
            place: 'Place',
            quantity: 'Quantity'
          }
        },
        title: 'Inventory list',
        unit: 'unit',
        unitCount: '{{count}} unit',
        unitCount_other: '{{count}} units',
        units: 'units'
      },
      lease: {
        attachDocument: {
          files: {
            description: 'Upload the attach document to sign. Once it has been done, send it to recipients to sign it.',
            fileType: 'Only PDF files with max size of {{maxFileSizeMB}}MB',
            title: 'Document'
          },
          notToSignButton: 'Save document',
          signers: {
            addNewSigner: 'Add new signer',
            hasBeSigned: 'Has this document to be signed?',
            title: 'Signers'
          },
          subtitle: 'Attach document',
          title: 'Attach document',
          toSignButton: 'Send document to sign',
          toast: 'Document attached'
        },
        break: {
          action: 'Break lease',
          modal: {
            confirmation: {
              cta: 'Yes, I want',
              description: 'Once you start the process, this action cannot be undone.',
              title: 'Do you want to break the current lease?'
            },
            contractTitle: 'Definitive contract document',
            cta: 'Send document to sign',
            endingLease: 'Ending lease',
            endingLeaseDate: 'Ending lease date',
            endingLeaseDateTitle: 'Choose when will be the last day of this lease',
            keyDeliveryTitle: 'Key delivery',
            signers: 'Signers',
            success: 'Contract broke',
            title: 'Break the lease',
            uploadContractTitle: 'Upload the contract',
            uploadDocumentDescription: 'Upload and select if this document has to be signed.\nOnce it has been done, this will be send to the recipients to sign it.\n',
            uploadDocumentDescription2: 'It will be attached with the current lease.'
          }
        },
        breakRequested: {
          alert: 'The tenant(s) have requested to terminate the current lease on <1>{{date}}</1>.'
        },
        checkout: {
          endingLeaseDate: 'Ending lease date:',
          title: 'Check-out'
        },
        currentLease: {
          detailsModal: {
            basicInformation: {
              cpiNotification: 'The updated price will be applied from <strong>{{date}}</strong>. All previous payments will be <strong>{{price}}</strong>.',
              finish: 'Finish',
              price: 'Price',
              start: 'Start',
              title: 'Basic information'
            },
            breakContract: {
              title: 'Break contract'
            },
            buttonNewAttachDocument: 'Attach new document',
            documents: {
              tag: {
                expired_signature: 'Expired signature',
                rejected: 'rejected',
                signed: 'signed',
                waiting: 'waiting'
              },
              title: 'Documents'
            },
            expiresAt: 'Expires at',
            insuranceContract: {
              title: 'Insurance contract'
            },
            tenants: {
              admin: 'Admin',
              coTenants: 'Co-tenants',
              title: 'Tenants'
            },
            title: 'Lease'
          },
          title: 'Current Lease',
          viewDetails: 'View Details'
        },
        editLease: {
          modal: {
            disclaimer: 'The details of the contract should specify what appears in the written and signed document. We recommend that when modifying any information in form, you shoudl also add an annex signed by both parties. So the change is also valid for legal purposes.',
            onSuccess: 'Lease edited successfully',
            saveButton: 'Save lease',
            title: 'Edit lease'
          }
        },
        empyState: 'There is no lease yet',
        existingLease: {
          button: 'Add lease',
          dates: {
            end: 'Finish rental lease',
            start: 'Start rental lease',
            title: 'Lease dates'
          },
          files: {
            description: 'Upload the rental lease already signed by the tenant and co-tenants, to have it available via Zazume.',
            fileType: 'Only PDF files with max size of {{maxFileSizeMB}}MB',
            title: 'Rental lease'
          },
          managementContractMessages: {
            error: {
              message1: 'The lease is set up so that the monthly rental payment is directed to the Organization, however there is no management contract created. Edit to whom the rental is directed or upload a management contract to continue.'
            },
            info: {
              message1: 'There is a management contract created for this unit that will be applied, with a service of <1>{{serviceName}}</1> and a fee of <2>{{managementFee}}</2>'
            },
            warning: {
              message1: 'The lease is set up so that the monthly rental payment is directed to the owner, however there is a management contract created. Edit who the rental is directed to if necessary or, if not, ignore this message.',
              message2: 'This property has a draft management contract, remember that the contract must be uploaded and signed by all parties.',
              message3: 'This property has a management contract pending signature, remember that the contract must be uploaded and signed by all parties.'
            }
          },
          maxMonthsExtension: 'Maximum months of extension',
          monthsExtension: 'Months of extension',
          notify: {
            endLease: 'End of lease (months)',
            endLeaseExtension: 'End of extension (months)',
            label: 'Notify end of lease (and extension)'
          },
          rental: {
            question: {
              negativeAnswer: 'No, let me change them',
              positiveAnswer: 'Yes, I want to use default conditions',
              title: 'Do you want to use this unit\'s default rental conditions'
            },
            title: 'Rental conditions'
          },
          subtitle: 'Lease detail',
          tenants: {
            button: 'Add new co-tenant',
            deleteModal: {
              description: 'Co-Tenant is being removed from the lease defintion',
              title: 'Co-Tenant is going to be removed'
            },
            descriptionAdmin: 'This signer cannot be deleted or edited',
            selectAdmnin: 'Select as admin tenant',
            titleAdmin: 'Tenant admin',
            titleCoTenant: 'Co-Tenant'
          },
          title: 'Register existing lease',
          toast: 'Lease registered',
          withLeaseExtensionLabel: 'Add lease extension'
        },
        extraEmptyStateMessage: {
          invited: {
            item1: 'Add new tenants',
            item2: ' sending them an invitation or remove them clicking on the card.'
          }
        },
        historical: {
          end: 'finish',
          hideTable: 'Hide old contracts',
          price: 'price',
          showTable: 'Show old contracts',
          start: 'start'
        },
        modal: {
          buttons: {
            currentLease: 'Register existing lease',
            newLease: 'New lease on application'
          },
          subtitle: 'You can either add a new lease by creating an application or registering an existing one.',
          title: 'Add lease'
        },
        newLease: 'Add lease',
        servicesContract: {
          addServiceTypeSelectorModal: {
            subtitle: 'Choose which service you want to config on this unit',
            title: 'Add service'
          },
          emptyState: 'To add a new service',
          emptyStateWihoutAction: 'There\'s no active contracts yet',
          infoModal: {
            message: 'There is already an active or draft management contract for this unit. If you want to create a new management contract, you must first break the existing contract.',
            title: 'You cannot create a new management contract'
          },
          title: 'Services contract'
        },
        warning: 'New lease can not be registered without owner. Please add owner to continue.'
      },
      notAssigned: 'Not assigned',
      ownershipPercentage: 'Ownership %',
      people: {
        accountManager: 'Account manager',
        addAccountManager: 'Add account manager',
        addAgent: 'Add agent',
        addIncidentsManager: 'Add incident manager',
        addOwner: 'Add owner',
        addStaff: 'Add staff',
        addTenant: 'Add tenant',
        addUnitManager: 'Add unit manager',
        agentTitle: 'Agent',
        changeIncidentsManager: 'Change incident manager',
        changeOwner: 'Change owner',
        changeStaff: 'Change staff',
        changeTenant: 'Change tenant',
        incidentsManager: 'Incident manager',
        ownerLeadTitle: 'Owner Lead',
        ownerTitle: 'Owners',
        ownershipPercentage: '{{value}}% Ownership',
        staffTitle: 'Staff',
        tenantsTitle: 'Tenants',
        unitManager: 'Unit manager'
      },
      peopleTooltipDescription: {
        accountManager: 'Sales person who will be in charge of any type of negotiation on the services and/or rates of Zazume.',
        agent: 'Person who will be in charge of making viewings or has provided this property.',
        incidentsManager: 'Person who will be in charge of attending to possible incidents that arise in your home.',
        unitManager: 'Person who will be in charge of managing your property, especially during the rental process of your home.'
      },
      portals: {
        addressVisibility: {
          hideAddress: 'Hide address',
          showFullAddress: 'Show street and number',
          showFullAddressAndComplements: 'Show complete address',
          showOnlyTheStreet: 'Show only the street',
          title: 'Address visibility'
        },
        description: {
          descriptionDefaultValue: 'New construction apartment, three bedrooms and two baths...',
          title: 'Description',
          warning: 'This field is required for publish in portals'
        },
        errorModal: {
          message: 'A unit with viewing availability cannot be published without first setting up the applicant\'s qualification data. Please fill out this information in the \'About\' tab to create availability',
          title: 'Unit cannot be published'
        },
        footerTitle: 'Do you need check the integrations?',
        footerTitleLink: 'Go to integrations',
        modalSync: {
          actionOptionNo: 'No, create it',
          actionOptionYes: 'Yes, update it',
          actionTitle: 'Do you want to update an existing published property?',
          buttonCTA: 'Sync now',
          errors: {
            referenceAlreadyExists: 'This reference code already exists, please enter other custom reference code.'
          },
          idealista: {
            body: 'You are ready to start to sync this unit information with your {{name}} account. If this property exists on {{name}} and you want to update it you will need the reference property code. Otherwise, another property will be created on {{name}}, losing its statistics.'
          },
          inmofactory: {
            body: 'You are now ready to start synchronizing this unit information with your {{name}} account. If this property already exists in {{name}}, go to {{name}} and unpublish and delete it.',
            bodyInfoMessage: 'Fotocasa, Habitaclia and Pisos.com share the same property reference, updating one will also change the other.',
            bodyWarn: 'This is very important because {{name}} only allows an unique property reference.',
            inputHelperText: 'Remember to use a reference that does not exist in {{name}}.'
          },
          inputLabel: 'Property reference code',
          noInputHelperText: 'Enter the custom reference code that best fits to you.',
          propertyStateLabel: 'Property state label',
          states: {
            available: 'Available',
            rented: 'Rented',
            reserved: 'Reserved'
          },
          title: 'Sync with {{name}}',
          yesInputHelperText: 'You can find this information on {{name}} in the list of published property, we will ask you for this code just one time.'
        },
        syncNever: 'Never was Synced',
        syncStopped: 'Sync stopped',
        syncSynced: 'Synced {{lastTimeAgo}}',
        syncSyncing: 'Syncing',
        table: {
          empty: 'There are no portals to sync',
          emptyPropertyManager: {
            boldDescription: 'organization administrator',
            description: 'There are no portals to sync, contact your'
          },
          headers: {
            lastSynced: 'Last synced',
            name: 'Name',
            propertyReference: 'Reference',
            synchronized: 'Synchronized'
          },
          invalidToSync: 'You cannot sync and publish until you complete the <0>information</0> required by the portal',
          invalidToSyncExceed: 'You can\'t have more properties published than you can publish. Please unpublish enough properties first, or upgrade the number of properties you can publish in the portal.'
        },
        title: 'Portals',
        titleHelperText: 'This unit syncs with these portals',
        warning: 'This field is required for publish in portals'
      },
      portalsOwner: {
        contractSerivice: 'To publish and/or manage your apartment on different portals, it is necessary to contract a tenant search service plan.',
        paymentSuccessMessage: 'Your subscription to the "peer to peer" service is activated. You can cancel it whenever you want on the <1><2>Subscription Manager</2></1>',
        portalsTable: {
          title: 'This unit syncs with these portals'
        },
        portalsTitle: 'Publication on portals',
        portalsUpdate: {
          firstPublish: {
            cta: 'Publish on portals',
            text: 'Click on the following button to publish your property on the best portals'
          },
          publish: {
            cta: 'Publish property',
            text: 'Do you want to publish your property on portals again?'
          },
          unpublish: {
            cta: 'Remove ad',
            text: 'Do you want to remove your property ad from portals?'
          }
        },
        unitValidation: {
          availability: 'Visits availability. <1><2>Complete the information by clicking here</2></1>.',
          billingData: 'To publish your property you need to complete the billing information. <1><2>Complete it by clicking here</2></1>.',
          information: 'To publish your property it is necessary to complete its information. <1><2>Complete it by clicking here</2></1>.',
          photos: 'To publish your home it is necessary to have a minimum of 12 to 16 photos. <1><2>Add them by clicking here</2></1>.'
        }
      },
      readOnly: 'We apologise. You don\'t have permission to edit this unit. If you need to edit this unit, please contact your organization administrator.',
      relatedIncidents: 'Related incidents',
      remove: 'Remove',
      removeUnitText: 'This will remove the selected unit permanently. This action can’t be undone.',
      removeUnitTitle: 'Remove unit?',
      seeValuation: 'See valuation',
      tab: {
        about: 'About',
        docs: 'Docs',
        inventory: 'Inventory',
        lease: 'Lease',
        people: 'People',
        portals: 'Portals'
      },
      tags: {
        empty: 'Empty',
        living: 'Living'
      },
      title: 'Unit detail',
      titleRoom: 'Room detail',
      unassignUserTextNotUndone: 'This will remove the selected {{role}} from the unit. This action can’t be undone.',
      unassignUserTextUndone: 'This will remove the selected {{role}} from the unit. You can reassign them at any time.',
      unassignUserTitle: 'Remove {{role}}?',
      unitDetails: 'Unit details',
      upcomingLease: {
        title: 'Upcoming Lease'
      },
      updateUnitTitle: 'Update a unit'
    },
    door: 'Door',
    emissionsConsumptionRatingOptions: {
      a: 'A',
      b: 'B',
      c: 'C',
      d: 'D',
      e: 'E',
      f: 'F',
      g: 'G'
    },
    empty: 'No units created yet',
    energyCertificateOptions: {
      exempt: 'Exempt',
      inProcess: 'In process',
      yes: 'Yes'
    },
    energyEfficiency: 'Energy efficiency rating',
    energyEfficiencyRatingOptions: {
      a: 'A',
      b: 'B',
      c: 'C',
      d: 'D',
      e: 'E',
      f: 'F',
      g: 'G'
    },
    entryDoor: 'Entry door',
    externalId: 'External ID',
    externalIdPlaceholder: 'Your Unit identifier',
    fantasy: {
      tag: 'Fantasy',
      toggle: 'Is this a fantasy unit?'
    },
    fees: {
      changeManagementFeeWarning: 'It seems that you have changed the service fee. Changes will be applied just for this unit. No current payment will be modified',
      commercializationService: 'Commercialization service:',
      customFee: 'Custom fee',
      customSettingsMessage: 'If not, you can custom it in each new unit.',
      feesFromOrganization: 'Organization settings fee',
      fillOrganizationSettings: 'Organization settings fee information is missing. Please, fill out this data in',
      managementService: 'Management service:',
      missingOrganizationSettingsMessage: 'Organization settings fee information is missing. Please, contact with your account’s admin to fill out this data. If not, you can custom it in each new unit.',
      title: 'Fees'
    },
    floor: 'Floor',
    garage: 'Garage',
    id: 'Unit ID',
    idPlaceholder: 'Name your unit',
    imageTitle: 'Hero image',
    importer: {
      portal: {
        consent: 'Consent to publish',
        help: 'This reference can be found in the URL of detail page of a Portal (Idealista/Fotocasa)',
        portal: 'Portal',
        reference: 'Property Reference'
      },
      title: 'Import from External Portals'
    },
    insurance: {
      company: 'Insurance company',
      details: 'Insurance details',
      phoneNumber: 'Phone number',
      policy: 'Policy number'
    },
    lift: 'Lift',
    list: {
      addUnit: 'Add a unit',
      addUnitModalTitle: 'Add a new unit',
      addUnitModelBtn: 'Add unit',
      emptyUnit: 'Empty',
      removeUnitSuccess: 'Unit removed successfully',
      title: 'Units',
      unassignedUnit: 'Unassigned'
    },
    location: 'Location',
    managerTypes: {
      accountManager: 'Account manager',
      agent: 'Agent',
      incidentsManager: 'Incidents manager',
      manager: 'Unit manager'
    },
    neighborhood: 'Neighborhood',
    notes: {
      cancel: 'Cancel',
      edit: 'Edit note',
      lastUpdated: 'Last updated',
      save: 'Save note',
      success: 'Note saved successfully',
      title: 'Notes'
    },
    occupation: {
      aboutPeople: 'About people living in the house',
      ageRangeLabel: 'Age range of those currently living in the property',
      detail: {
        ageRange: 'Age range',
        currentlyLiving: 'People currently living',
        genders: 'Genders',
        howMany: 'How many roommates',
        occupation: 'Occupation',
        ownerLiving: 'Owner living in the house',
        people: 'People living in the house',
        title: 'About flatmates'
      },
      genders: {
        all: 'All genders',
        female: 'Only female(s)',
        label: 'Gender of the people who currently live there',
        male: 'Only male(s)'
      },
      isOccupiedLabel: 'Does anyone currently live in the property?',
      isOwnerResidentLabel: 'Does the owner live in the house?',
      numberOfPeopleLabel: 'Number of people who will share the property (including tenants who are missing)',
      tenantsOccupation: {
        employed: 'Employed',
        label: 'Occupation of those living in the property',
        student: 'Student(s)'
      },
      title: 'About flatmates'
    },
    optionalsTitle: 'All about this unit',
    orientation: 'Windows orientation',
    propertyType: 'Property type',
    propertyTypePlaceholder: 'Select a property type',
    propertyTypes: {
      local: 'Local',
      office: 'Office',
      parking: 'Parking',
      residential: 'Residential',
      room: 'Room'
    },
    publishedStatus: {
      published: 'Published',
      publishedFotocasa: 'Published on Fotocasa',
      publishedHabitaclia: 'Published on Habitaclia',
      publishedIdealista: 'Published on Idealista',
      publishedPisosCom: 'Published on Pisos.com',
      publishedZazume: 'Published on Zazume',
      title: 'Published status',
      unpublished: 'Unpublished'
    },
    quality: 'Quality',
    ref: 'Ref. Number',
    rentControlled: 'Rent-controlled',
    rentalConditions: {
      availabilityDate: 'Available since',
      dueDate: 'Due date',
      dueDateBefore: 'Before',
      dueDateEachMonth: 'of each month',
      guarantees: {
        additionalGuarantee: 'Additional guarantee',
        additionalGuaranteeLabel: 'Deposit amount',
        additionalGuaranteeOptions: {
          none: 'None',
          one: '1 monthly rent',
          other: 'Other...',
          two: '2 monthly rents'
        },
        additionalGuaranteeTooltip: 'It\'s optional. The law establishes this amount cannot exceed more than 2 monthly rents.',
        deposit: 'Deposit',
        depositAmount: 'Deposit amount',
        depositTooltip: 'The law in Spain says you must lodge a month deposit.',
        holdingDeposit: 'Holding deposit',
        holdingDepositAmount: 'Holding deposit amount',
        modal: {
          title: 'Which amount?'
        },
        title: 'Guarantees'
      },
      monthlyRent: 'Monthly rent',
      paymentMethod: {
        bankTransfer: 'Make bank transfer to',
        creditorIdentifier: 'Creditor identifier',
        creditorIdentifierPlaceholder: 'Automatically filled',
        creditorIdentifierTooltip: 'It\'s a unique reference number for collecting payments by SEPA Direct Debit. We calculated it automatically based on ID Card Number or CIF.',
        defaultPaymentMethod: 'Default payment method',
        directDebitOrderFrom: 'Make a direct debit order from',
        directDebitOrderFromOptions: {
          organization: 'Organization account',
          owner: 'Owner\'s account'
        },
        directDebitOrderFromPlaceholder: 'Select creditor',
        method: 'Method',
        ownersAddress: 'Owner\'s current address',
        ownersAddressPlaceholder: 'Write owner\'s address',
        ownersIdNumber: 'ID card number',
        ownersIdNumberPlaceholder: 'Write owner\'s ID card number',
        ownersName: 'Owner\'s name',
        ownersNamePlaceholder: 'Write owner\'s name',
        paymentMethodOptions: {
          bank_transfer: 'Bank transfer',
          direct_debit: 'Direct debit'
        },
        paymentMethodPlaceholder: 'Select payment method',
        selectBankAccount: 'Select a bank account',
        selectBankAccountPlaceholder: 'Select an organization bank account',
        title: 'Payment method'
      },
      paymentsDueDate: 'All payments due date',
      paymentsDueDateInfo: 'The due date applies to all payments you set up in this step.',
      rent: {
        checkbox: 'Update only if CPI is positive (rent will never go down)',
        dueDate: 'Due date (last day of the month to pay the rent)',
        dueDatePlaceholder: 'Select due date',
        monthlyRent: 'Monthly Rent',
        rentUpdate: 'Rent update',
        rentUpdateOptions: {
          automatically: 'Automatically with the CPI',
          doNotUpdate: 'Do not update'
        },
        rentUpdatePlaceholder: 'Select an option',
        title: 'Rent'
      },
      selectOwner: {
        error: 'You must select an owner',
        selectOwner: 'Select an owner'
      },
      tenantSearchServiceFee: {
        amount: 'Service fee amount (50% monthly rent)',
        subtitle: {
          0: 'Service fee',
          1: 'Taxes'
        },
        title: 'Tenant search service fee'
      },
      title: 'Rental Conditions'
    },
    rentalType: 'Unit rental type',
    rentalTypePlaceholder: 'Select a rental type',
    rentalTypesOptions: {
      long: 'Long-term rental',
      mid: 'Mid-term rental'
    },
    rentalTypesValues: {
      long: 'Long-term',
      mid: 'Mid-term'
    },
    restaurationYear: 'Restoration year',
    restored: 'Restored',
    roomCharacteristics: {
      ageRangeLooking: 'Age range you are looking for',
      beds: {
        double: 'Double bed',
        individual: 'Individual bed',
        noBed: 'No bed',
        twoBeds: 'Two beds'
      },
      detail: {
        balcony: 'Balcony',
        bed: 'Bed',
        fittedWardrobe: 'Fitted wardrobe',
        furnished: 'Furnished room',
        otherFeatures: 'Other room features',
        privateBathroom: 'Private bathroom',
        roomSize: 'Room size',
        title: 'Room characteristics',
        views: 'Views'
      },
      features: {
        balcony: 'Balcony',
        fittedWardrobe: 'Fitted wardrobe',
        furnished: 'Furnished',
        privateBathroom: 'Private bathroom'
      },
      gender: {
        female: 'Female',
        male: 'Male',
        notSpecified: 'It doesn’t matter'
      },
      lookingFor: 'You are looking for...',
      occupation: {
        label: 'Occupation:',
        notSpecified: 'It doesn’t matter',
        student: 'Student',
        worker: 'Worker'
      },
      otherRoomFeatures: 'Other room features:',
      preferences: {
        couplesAllowed: 'Couples allowed',
        label: 'Preferences:',
        lgbt: 'LGBT+ friendly',
        minorsAllowed: 'Minors allowed',
        petsAllowed: 'Pets allowed',
        smokingAllowed: 'Smoking allowed'
      },
      roomFeatures: 'Room features',
      roomSizeLabel: 'Room size m²',
      roomViewsLabel: 'Room views',
      title: 'Room characteristics',
      typeOfBedLabel: 'Type of bed',
      views: {
        courtyard: 'Courtyard view',
        noWindow: 'Without window',
        street: 'Street view'
      }
    },
    roomWithId: 'Room {{id}}',
    rooms: 'Rooms',
    saleConditions: {
      profitability: 'Profitability',
      profitabilityValue: '{{profitability}}%',
      sale: 'Sale',
      sellingPrice: 'Selling price',
      title: 'Sale conditions'
    },
    security: '24h Security',
    selectBuildingPlaceholder: 'Select Building',
    selectPlaceholder: 'Select a workspace',
    storage: 'Storage room',
    surface: 'Surface area',
    tags: {
      entryDoorMaterial: {
        fiberglass: 'Fiberglass',
        steel: 'Steel',
        wood: 'Wood'
      },
      facadeMaterial: {
        brick: 'Brick',
        concrete: 'Concrete',
        mortar: 'Mortar',
        stone: 'Stone',
        tile: 'Tile',
        wood: 'Wood'
      },
      location: {
        privateUrbanization: 'Private Urbanization',
        urban: 'Urban'
      },
      mainUse: {
        commercial: 'Commercial',
        residential: 'Residential'
      },
      quality: {
        luxury: 'Luxury',
        normal: 'Normal',
        simple: 'Simple'
      },
      windowsMaterial: {
        aluminum: 'Aluminum',
        composite: 'Composite',
        fiberglass: 'Fiberglass',
        vinyl: 'Vinyl',
        wood: 'Wood',
        woodclad: 'Wood-clad'
      },
      windowsOrientation: {
        inside: 'Inside',
        outside: 'Outside'
      }
    },
    toggleBuilding: 'Belongs to a building?',
    toggleRentControlled: 'Is this a rent-controlled unit?',
    tooltips: {
      agentLimitedEdit: 'This property was created by someone else. Therefore you do not have full editing permissions.'
    },
    unitWithId: 'Unit {{id}}',
    use: 'Use',
    warning: {
      noBillingData: {
        admin: {
          cta: 'Go to Organization Settings',
          message: 'Required information is missing. Please, fill out in <strong>Organization Settings</strong> before creating a unit.'
        },
        other: {
          message: 'Required information is missing. Please, contact with your account\'s admin to fill out all information in <strong>Organization Settings</strong> before creating a unit.'
        },
        owner: {
          cta: 'Go to configuration',
          message: 'To continue, you must first complete your <strong>billing information</strong><br/><br/> You can complete this information on the <strong>Settings</strong> page.'
        }
      }
    },
    windowsMaterial: 'Windows material',
    woodStructure: 'Wood structure',
    workspace: 'Workspace',
    workspacePlaceholder: 'Select a workspace',
    zip: 'ZIP Code'
  },
  users: {
    roles: {
      accountManager: 'Account manager',
      agent: 'Agent',
      applicationManager: 'Application manager',
      incidentsManager: 'Incident manager',
      ownerAccount: 'Owner',
      ownerAccountCount: '{{count}} owner',
      ownerAccountCount_other: '{{count}} owners',
      ownerAccount_other: 'Owners',
      staff: 'Staff',
      tenant: 'Tenant',
      tenantCount: '{{count}} tenant',
      tenantCount_other: '{{count}} tenants',
      tenant_other: 'Tenants',
      unitManager: 'Unit manager',
      zazume: 'Zazume'
    }
  },
  valuations: {
    additionalCharacteristics: 'Additional characteristics',
    comparableUnits: 'Comparable units',
    details: {
      averageListing: 'Average no. of listings per property',
      averageListingValue: '{{count}} listing',
      averageListingValue_other: '{{count}} listings',
      averagePrice: 'Average price/m2',
      averageRent: 'Average rent (from units around)',
      averageTime: 'Average time to rent',
      averageTimeValue: '{{count}} day',
      averageTimeValue_other: '{{count}} days',
      created: 'Created: {{date}}',
      disclaimerMessage: 'This valuation is merely indicative in nature and in no case constitutes an official appraisal. Zazume does not assume any responsibility for the use of this information or for the decisions made based on it.',
      estimatedPrices: 'Estimated prices',
      fairRent: 'Fair rent',
      fastRent: 'Fast rent',
      otherValuation: 'Other valuations',
      outOfMarket: 'Out of market',
      title: 'Valuation details',
      unitsRented: 'Units rented (last 6 months)',
      unitsRentedValue: '{{count}} unit',
      unitsRentedValue_other: '{{count}} units'
    },
    emptyState: 'No valuations created yet',
    errors: {
      cityNotAvailable: 'City provided is not available for valuation'
    },
    newValuation: 'New valuation',
    promoteToUnit: 'Promote to unit',
    reports: 'Reports',
    reportsDetail: {
      addCTA: 'Add CTA at report last page',
      create: 'Create report',
      createNew: 'Create new report',
      ctaNameLabel: 'Text area',
      ctaNamePlaceholder: 'This text will appear above the URL',
      ctaUrlLabel: 'URL',
      ctaUrlPlaceholder: 'Copy and paste the URL',
      deleteMessage: 'This will remove the selected report permanently. This action can\'t be undone.',
      deleteReport: 'Delete report?',
      deletedReport: 'Report deleted successfully',
      editReport: 'Edit report',
      emptyState: 'There is no report yet',
      estimatedValuation: 'Estimated valuation',
      presentation: 'Presentation',
      presentationPlaceholder: 'Write a presentation about the organization',
      sendReport: 'Send report',
      sendReportDescription: 'Before sending the report we need some information',
      sendReportEmailPlaceholder: 'name@mail.com',
      sendReportNamePlaceholder: 'Write the name without surnames Ex: Pedro',
      title: 'Report',
      update: 'Update report',
      withAgent: 'Include agent information',
      withCTA: 'Include call to action',
      withCharacteristics: 'Include unit characteristic to the report',
      withComparables: 'Include comparable units to the report',
      withEstimation: 'Include estimated valuations to the report'
    },
    title: 'Valuations',
    unit: {
      otherCharacteristics: 'Other characteristics',
      unitCharacteristics: 'Unit characteristics'
    },
    valuateUnit: 'Valuate unit',
    valuatedOn: 'Valuated on',
    valuation: 'Valuation'
  },
  walkthrough: {
    progress: 'profile completed',
    selfManagement: {
      cards: {
        addTenants: 'Add your tenants',
        billingData: 'Fill in your billing information',
        connectBankAccount: 'Connect your bank account',
        unitDetails: 'Complete your unit details',
        uploadCurrentContract: 'Upload the current rental contract'
      }
    },
    steps: {
      addTenants: 'Add your tenants',
      billingData: 'Fill in your billing information',
      connectBankAccount: 'Connect your bank account',
      createExpense: 'Create your first expense',
      prequalification: 'Add tenant qualification criteria',
      unitDetails: 'Complete your unit details',
      unitDocuments: 'Upload the documents of your unit',
      uploadCurrentContract: 'Upload the current rental contract'
    },
    tenantSearch: {
      cards: {
        billingData: 'Fill in your billing information',
        prequalification: 'Add tenant qualification criteria',
        unitDetails: 'Complete your unit details',
        unitDocuments: 'Upload the documents of your unit'
      }
    },
    title: 'Complete your profile'
  },
  whatsapp: {
    message: 'Hello, {{name}}'
  },
  workspaceServices: {
    addAService: 'Add a service',
    addService: 'Add service',
    hideServiceModal: {
      button: 'Hide',
      text: 'This will make the service unavailable to book and hidden from your tenant’s app immediately. You can publish it again at any time.',
      title: 'Hide service?'
    },
    playbook: {
      description: 'Description',
      editTask: 'Edit task',
      externalLink: 'External link',
      link: 'Link'
    },
    publishServiceModal: {
      button: 'Publish',
      text: 'This will make the service available to book from your tenant’s app immediately. You can hide it again at any time.',
      title: 'Publish service?'
    },
    searchServices: 'Search services',
    serviceHidden: 'Hidden in the app',
    servicePublished: 'Published',
    title: 'Offered services'
  },
  workspaces: {
    add: 'Add a new workspace',
    addCta: 'Add workspace',
    addWorkspaceCardLabel: 'Add a workspace',
    address: 'Address',
    all: 'See all workspaces',
    confirmationModal: {
      description: 'This will remove the selected workspace permanently. This action can’t be undone.',
      title: 'Remove Workspace?',
      toast: 'This workspace has units and/or tenants assigned and cannot be removed',
      workspaceDeleted: 'Workspace deleted successfully'
    },
    deleteCta: 'Delete workspace',
    description: 'Description',
    edit: 'Edit workspace',
    editCta: 'Save changes',
    name: 'Give it a name',
    title: 'Workspaces',
    units: 'units'
  },
  xmlExports: {
    delete: {
      description: 'Delete XML Export?',
      title: 'This will remove the selected XML export and update the linked payouts to wait state. This action can\'t be undone.'
    },
    downloadCTA: 'Download XML file',
    file: 'XML file',
    linkedDescription: 'Those are the pay-outs linked with this XML file',
    linkedTitle: 'Linked payments',
    processedPayout: 'Payout processed',
    title: 'XML Exports'
  },
  zazume: {
    add: 'Add Zazume user',
    removeDescription: 'This will remove the selected zazume user. This action can\'t be undone.',
    removeTitle: 'Remove Zazume user?',
    removed: 'Zazume user removed',
    title: 'Zazume users'
  },
  zazumeCommercializationServices: {
    peer_to_peer: {
      name: 'Peer to peer'
    },
    tenant_search: {
      name: 'Tenant search'
    }
  },
  zazumeServices: {
    contractPlanModal: {
      acceptConditions: {
        acceptConditions: 'I accept the service terms',
        secureTransaction: 'Secure transaction',
        serviceConditions: 'Service conditions:',
        serviceIncludes: '<1>The <2>{{service}}</2> service</1> <1>includes:</1>',
        serviceName: '{{service}} service',
        title: 'Do you want to contract the {{service}} service?'
      },
      selectPlan: {
        benefitsTitle: 'This plan includes:',
        description: 'In order to publish your property in the best portals you need to choose a tenant search plan.',
        selectService: 'Select a service',
        selectUnit: 'Select the property you would like to rent',
        title: 'Contract a tenant search service'
      },
      tenantSearchConfirmation: {
        description: 'A member of our team will contact you in the next 24/48 hours to start publishing your property and find the best tenant!',
        title: 'We have received your request correctly'
      }
    },
    free: 'FREE',
    full_coverage: {
      benefits: [
        'Real-time control and visibility of payments',
        'Communication with the tenant',
        'Incident management',
        'Expense financing up to €3000 (12 months)',
        'Includes non-payment insurance',
        'Vandalism coverage: €6000',
        'Legal coverage for non-payment: €15000',
        'Guaranteed rent collection on the 10th of each month',
        '25% discount on tenant search fees',
        'Receive your rent even if the property remains vacant'
      ],
      name: 'Full Coverage',
      title: 'Full',
      titleHighlight: 'coverage'
    },
    guaranteed_rental: {
      benefits: [
        'Real-time control and visibility of payments',
        'Communication with the tenant',
        'Incident management',
        'Expense financing up to €2000 (9 months)',
        'Includes non-payment insurance',
        'Vandalism coverage: €6000',
        'Legal coverage for non-payment: €15000',
        'Guaranteed rent collection on the 10th of each month'
      ],
      name: 'Guaranteed Management',
      title: 'Guaranteed',
      titleHighlight: 'Management'
    },
    guaranteed_rental_self_management: {
      benefits: [
        'Real-time control and visibility of payments',
        'Communication with the tenant',
        'Expense financing up to €2000 (9 months)',
        'Includes non-payment insurance',
        'Vandalism coverage: €6000',
        'Legal coverage for non-payment: €15000',
        'Guaranteed rent collection on the 10th of each month'
      ],
      name: 'Guaranteed Rental',
      title: 'Guaranteed',
      titleHighlight: 'Rental'
    },
    monthly: 'monthly',
    moreInformation: 'More information',
    mostPopular: 'Most popular',
    noManagement: 'I don\'t wanna management',
    noSmallPrint: 'No small print',
    ofTheServie: '{{quota}}% + VAT (of service included)',
    peer_to_peer: {
      benefits: [
        'Access to the cloud platform',
        'Publication in the best portals',
        'Digital management of visits',
        'Tenant scoring and verification',
        'Document management and digital signature',
        'Online and no bureaucracy: from the publishing to the signing of the contract'
      ],
      conditions: [
        '• Subscription of €59/week for each property.',
        '• You can unsubscribe at any time on the Subscription Manager.'
      ],
      name: 'Peer to peer',
      price: '<1>starting at</1> <2>{{price}}</2>',
      title: 'Peer',
      titleHighlight: 'to peer'
    },
    real_estate_opportunities: {
      banner: {
        benefits: [
          'Opportunities in all provinces of Spain',
          'Rental price estimations and profitability',
          'Economic and financial data to help you decide',
          '26,487 investment opportunities are waiting for you!'
        ],
        cta: 'I want unlimited access',
        title: 'Get unlimited access to all investment opportunities'
      },
      benefits: [
        'Alerts via email or WhatsApp',
        'Access to all spanish market',
        'Economic and financial data in every opportunity',
        'Save your opportunities with your adjustments in the financial calculator',
        'Advanced filters to dig into the opportunities'
      ],
      conditions: [
        '• Subscription of €75/month',
        '• You can unsubscribe at any time on the Subscription Manager.'
      ],
      name: 'Real Estate Opportunities',
      price: '<1>{{price}}</1><2>/month</2>',
      title: 'Do you want to unlock more investment opportunities?'
    },
    receivedEachMonth: 'You\'ll receive each month',
    rental_management: {
      benefits: [
        'Real-time control and visibility of payments',
        'Communication with the tenant',
        'Incident management',
        'Expense financing up to €1000 (3 months)'
      ],
      name: 'Rental Management',
      title: 'Rental',
      titleHighlight: 'management'
    },
    self_management: {
      benefits: [
        'Property commercialization',
        'Publication in the best portals',
        'Viewings management',
        'Payments management',
        'Incomes and expenses register',
        'Connect you bank account',
        'Payments reconciliation',
        'Document management and digital signature'
      ],
      name: 'Self-mangement',
      title: '',
      titleHighlight: 'Self-management'
    },
    showAllServices: 'Show all services',
    showSuggestedServices: 'Show suggested services',
    tenant_search: {
      benefits: [
        'Premium positioning in portals',
        'Property valuation',
        'Professional pictures',
        'Real estate advice from start to finish',
        'We find the best tenants for you',
        'We guarantee you 12 months, if the tenant leaves before the first year, we will find you another one for free',
        'You will not have to pay the fees in advance, we will deduct it from the first month\'s rent'
      ],
      conditions: [
        '• Single payment when renting your property.',
        '• It includes a 12-month guarantee, if the tenant leaves before the first year you will not have to pay it again.',
        '• You will not have to advance money, we will subtract the month of fees from the tenant\'s first month\'s rent.',
        '• If you choose this plan, one of our sales representatives will contact you in the next 24/48 hours to prepare everything.'
      ],
      name: 'Tenant Search',
      tag: 'Most popular',
      title: 'Tenant',
      titleHighlight: 'screening'
    },
    theSafest: 'The safest'
  }
};
