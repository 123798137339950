import { AdminRoute } from '#/components/routes/AdminRoute';
import { PrivateRoute } from '#/components/routes/PrivateRoute';
import { LazyLoader } from '#/utils/LazyLoader';
import { useI18n } from '#hooks/useI18n';
import { useURLParams } from '#hooks/useURLParams';
import React, { FC } from 'react';
import { Navigate, Route } from 'react-router-dom';
import ProviderDetailPage from '../../pages/people/provider/ProviderDetail.page';
import { BaseRoutes } from './BaseRoutes';
import { Anonymous } from './routes/Anonymous';
import { Private } from './routes/Private';
import { InvestmentAlerts } from '#/pages/investment/InvestmentAlerts.page';

const Team = LazyLoader(() => import('../../pages/team/Team.page'));
const Onboarding = LazyLoader(() => import('../../pages/onboarding/Onboarding.page'));
const ArchivedApplications = LazyLoader(() => import('../../pages/onboarding/Archived.page'));
const OnboardingDetail = LazyLoader(() => import('../../pages/onboarding/OnboardingDetail.page'));
const ArchivedApplicationDetail = LazyLoader(() => import('../../pages/onboarding/ArchivedDetail.page'));
const OrganizationSettings = LazyLoader(() => import('../../pages/organizationSettings/OrganizationSettings.page'));
const Profile = LazyLoader(() => import('../../pages/Profile.page'));
const Help = LazyLoader(() => import('../../pages/Help.page'));

const Admin = LazyLoader(() => import('../../pages/admin/Admin.page'));
const AdminTenants = LazyLoader(() => import('../../pages/admin/AdminTenants.page'));
const ServiceDetail = LazyLoader(() => import('../../pages/admin/services/ServiceDetail.page'));
const Services = LazyLoader(() => import('../../pages/admin/services/Services.page'));

const TestPage = LazyLoader(() => import('../../pages/admin/Test.page'));

const Accounting = LazyLoader(() => import('../../pages/accounting/Accounting.page'));
const AutoReconciliations = LazyLoader(() => import('../../pages/bankAccounts/autoReconciliations/AutoReconciliations.page'));
const BankAccounts = LazyLoader(() => import('../../pages/bankAccounts/BankAccounts.page'));
const Bookings = LazyLoader(() => import('../../pages/workspaces/bookings/Bookings.page'));
const BookingDetail = LazyLoader(() => import('../../pages/workspaces/bookings/BookingDetail.page'));
const Calendar = LazyLoader(() => import('../../pages/calendar/Calendar.page'));
const Copilot = LazyLoader(() => import('../../pages/copilot/Copilot.page'));
const NewCopilotConversation = LazyLoader(() => import('../../pages/copilot/NewCopilotConversation.page'));
const Dashboard = LazyLoader(() => import('../../pages/workspaces/dashboard/Dashboard.page'));
const Expenses = LazyLoader(() => import('../../pages/accounting/expenses/Expenses.page'));
const ExpenseDetail = LazyLoader(() => import('../../pages/accounting/expenses/ExpenseDetail.page'));
const Payouts = LazyLoader(() => import('../../pages/accounting/payouts/Payout.page'));
const PayoutDetail = LazyLoader(() => import('../../pages/accounting/payouts/PayoutDetail.page'));
const Invoices = LazyLoader(() => import('../../pages/accounting/invoices/Invoices.page'));
const NonPayments = LazyLoader(() => import('../../pages/accounting/nonPayments/NonPayments.page'));
const NonPaymentDetail = LazyLoader(() => import('../../pages/accounting/nonPayments/detail/NonPaymentDetail.page'));
const Invitations = LazyLoader(() => import('../../pages/invitation/Invitations.page'));

const Facilities = LazyLoader(() => import('../../pages/workspaces/Facilities.page'));
const OrganizationServices = LazyLoader(() => import('../../pages/workspaces/services/OrganizationServices.page'));
const Incidents = LazyLoader(() => import('../../pages/workspaces/incidents/Incidents.page'));
const IncidentsNew = LazyLoader(() => import('../../pages/workspaces/incidents/NewIncidents.page'));
const IncidentDetail = LazyLoader(() => import('../../pages/workspaces/incidents/IncidentDetail.page'));
const Investment = LazyLoader(() => import('../../pages/investment/Investment.page'));
const InvestmentDetail = LazyLoader(() => import('../../pages/investment/InvestmentDetail.page'));
const Staff = LazyLoader(() => import('../../pages/workspaces/Staff.page'));
const Tenants = LazyLoader(() => import('../../pages/workspaces/tenants/Tenants.page'));
const TenantDetail = LazyLoader(() => import('../../pages/workspaces/tenants/TenantDetail.page'));
const Units = LazyLoader(() => import('../../pages/workspaces/units/Units.page'));
const UnitDetail = LazyLoader(() => import('../../pages/workspaces/units/UnitDetail.page'));
const BuildingSettings = LazyLoader(() => import('../../pages/workspaces/settings/BuildingSettings.page'));
const Buildings = LazyLoader(() => import('../../pages/workspaces/building/Buildings.page'));
const Workspaces = LazyLoader(() => import('../../pages/workspaces/workspaces/Workspaces.page'));
const OwnerAccount = LazyLoader(() => import('../../pages/people/ownerAccount/OwnerAccount.page'));
const OwnerAccountSettings = LazyLoader(() => import('../../pages/ownerAccountSettings/OwnerAccountSettings.page'));
const OwnerAccountBillingSettings = LazyLoader(() => import('../../pages/ownerAccountSettings/billing/BillingSettings.page'));
const OwnerAccountIntegrationsSettings = LazyLoader(() => import('../../pages/ownerAccountSettings/integrations/OwnerAccountIntegrationsSettings.page'));

const OwnerService = LazyLoader(() => import('../../pages/ownerServices/OwnerService.page'));
const OwnerServices = LazyLoader(() => import('../../pages/ownerServices/service/Services.page'));
const OwnerBookings = LazyLoader(() => import('../../pages/ownerServices/booking/Bookings.page'));

const AgentAccountSettings = LazyLoader(() => import('../../pages/agentAccountSettings/AgentAccountSettings.page'));
const AgentAccountBillingSettings = LazyLoader(() => import('../../pages/agentAccountSettings/billing/AgentBillingSettings.page'));

const Entity = LazyLoader(() => import('../../pages/people/entity/Entity.page'));
const Agent = LazyLoader(() => import('../../pages/people/agent/Agents.page'));
const Provider = LazyLoader(() => import('../../pages/people/provider/Providers.page'));
const OwnerAccountDetail = LazyLoader(() => import('../../pages/people/ownerAccount/OwnerAccountDetail.page'));
const EntityDetail = LazyLoader(() => import('../../pages/people/entity/EntityDetail.page'));
const AgentDetail = LazyLoader(() => import('../../pages/people/agent/AgentDetail.page'));
const OwnerLeadDetail = LazyLoader(() => import('../../pages/people/ownerLead/OwnerLeadDetail.page'));
const OwnerTaxesPage = LazyLoader(() => import('../../pages/taxes/OwnerTaxes.page'));
const Portfolio = LazyLoader(() => import('../../pages/portfolio/Portfolio.page'));
const People = LazyLoader(() => import('../../pages/people/People.page'));
const Payments = LazyLoader(() => import('../../pages/accounting/payments/Payments.page'));
const PaymentsDetail = LazyLoader(() => import('../../pages/accounting/payments/detail/PaymentDetail.page'));
const PaymentsDirectDebitOrders = LazyLoader(() => import('../../pages/accounting/payments/directDebitOrders/DirectDebitOrders.page'));
const PendingPayments = LazyLoader(() => import('../../pages/accounting/payments/directDebitOrders/pendingPayments/PendingPayments.page'));
const PaymentsExports = LazyLoader(() => import('../../pages/accounting/payments/directDebitOrders/paymentExports/PaymentExports.page'));
const GeneralSettings = LazyLoader(() => import('../../pages/organizationSettings/general/GeneralSettings.page'));
const IntegrationsSettings = LazyLoader(() => import('../../pages/organizationSettings/integrations/IntegrationsSettings.page'));
const BillingSettings = LazyLoader(() => import('../../pages/organizationSettings/billing/BillingSettings.page'));
const AccountingSettings = LazyLoader(() => import('../../pages/organizationSettings/accounting/Accounting.page'));
const LeaseSettings = LazyLoader(() => import('../../pages/organizationSettings/lease/Lease.page'));
const RecurrentIncomes = LazyLoader(() => import('../../pages/accounting/recurrentIncomes/RecurrentIncomes.page'));
const RecurrentIncomeDetail = LazyLoader(() => import('../../pages/accounting/recurrentIncomes/detail/RecurrentIncomeDetail.page'));
const RecurrentExpenses = LazyLoader(() => import('../../pages/accounting/recurrentExpenses/RecurrentExpenses.page'));
const RecurrentExpenseDetail = LazyLoader(() => import('../../pages/accounting/recurrentExpenses/detail/RecurrentExpenseDetail.page'));
const RecurrentPayouts = LazyLoader(() => import('../../pages/accounting/recurrentPayouts/RecurrentPayouts.page'));
const RecurrentPayoutDetail = LazyLoader(() => import('../../pages/accounting/recurrentPayouts/detail/RecurrentPayoutDetail.page'));
const XMLExports = LazyLoader(() => import('../../pages/accounting/xmlExports/XMLExports.page'));
const Templates = LazyLoader(() => import('../../pages/templates/Templates.page'));
const DocumentTemplates = LazyLoader(() => import('../../pages/templates/documentTemplates/DocumentTemplates.page'));
const TasksTemplates = LazyLoader(() => import('../../pages/templates/taskTemplates/TaskTemplates.page'));
const Valuations = LazyLoader(() => import('../../pages/valuations/Valuations.page'));
const ValuationDetails = LazyLoader(() => import('../../pages/valuations/ValuationDetail.page'));
const Notifications = LazyLoader(() => import('../../pages/notifications/Notifications.page'));
const NotificationDetailPage = LazyLoader(() => import('../../pages/notifications/NotificationDetail.page'));
const CheckoutSuccessPage = LazyLoader(() => import('../../pages/checkout/CheckoutSuccess.page'));
const OwnerBookingDetail = LazyLoader(() => import('../../pages/ownerServices/booking/OwnerBookingDetail.page'));
const OwnerProductDetail = LazyLoader(() => import('../../pages/ownerServices/service/OwnerProductDetail.page'));
const DraftsPage = LazyLoader(() => import('../../pages/drafts/Drafts.page'));
const DraftsDocumentsPage = LazyLoader(() => import('../../pages/drafts/documents/DraftsDocuments.page'));
const RentAdvance = LazyLoader(() => import('../../pages/accounting/rentAdvance/RentAdvance.page'));

const Tasks = LazyLoader(() => import('../../pages/workspaces/tasks/Tasks.page'));
const TaskDetailPage = LazyLoader(() => import('../../pages/tasks/taskDetail/TaskDetail.page'));
const TaskEditPage = LazyLoader(() => import('../../pages/tasks/taskEdit/TaskEdit.page'));
const TaskNewPage = LazyLoader(() => import('../../pages/tasks/newTask/NewTask.page'));

export const PrivateRoutes: FC = (props) => {
  const { t } = useI18n();
  const { redirectPath } = useURLParams();

  return <BaseRoutes>
    <PrivateRoute path={Private.TEAM.route} element={<Team/>} {...props}/>

    <PrivateRoute path={Private.ONBOARDING.route} element={<Onboarding/>} title={t('onboarding.title')} {...props}/>
    <PrivateRoute path={Private.ARCHIVED_APPLICATIONS.route} element={<ArchivedApplications/>} title={t('archived.title')} {...props}/>
    <PrivateRoute path={Private.ONBOARDING_DETAIL.route} element={<OnboardingDetail/>} title={t('onboarding.detail.title')} {...props}/>
    <PrivateRoute path={Private.APPLICATION_DETAIL.route} element={<OnboardingDetail/>} title={t('onboarding.detail.title')} {...props}/>
    <PrivateRoute path={Private.ARCHIVED_DETAIL.route} element={<ArchivedApplicationDetail/>} title={t('archived.detail.title')} {...props}/>
    <PrivateRoute path={Private.SERVICES.route} element={<Services/>} title={t('organizationServices.title')} {...props}/>
    <PrivateRoute path={Private.SERVICES_EDIT.route} element={<ServiceDetail/>} title={t('organizationServices.detail.title')} {...props}/>
    <PrivateRoute path="/settings" element={<OrganizationSettings/>} title={t('settings.title')} withRoles={['admin']} {...props}>
      <PrivateRoute path="/" element={<Navigate to={Private.SETTINGS.route}/>} title={t('settings.title')} {...props}/>
      <PrivateRoute path={Private.SETTINGS.indent} element={<GeneralSettings/>} title={`${t('settings.title')} - ${t('settings.tabs.general')}`} {...props}/>
      <PrivateRoute path={Private.SETTINGS_BILLING.indent} element={<BillingSettings/>} title={`${t('settings.title')} - ${t('settings.tabs.billing')}`} {...props}/>
      <PrivateRoute path={Private.SETTINGS_INTEGRATIONS.indent} element={<IntegrationsSettings/>} title={`${t('settings.title')} - ${t('settings.tabs.integrations')}`} {...props}/>
      <PrivateRoute path={Private.SETTINGS_ACCOUNTING.indent} element={<AccountingSettings/>} title={`${t('settings.title')} - ${t('settings.tabs.accounting')}`} {...props}/>
      <PrivateRoute path={Private.SETTINGS_LEASE.indent} element={<LeaseSettings/>} title={`${t('settings.title')} - ${t('settings.tabs.lease')}`} {...props}/>
    </PrivateRoute>
    <PrivateRoute path={Private.HELP.route} element={<Help/>} {...props}/>
    <PrivateRoute path={Private.PROFILE.route} element={<Profile/>} {...props}/>

    <PrivateRoute path={Private.AGENT_ACCOUNT_SETTINGS.base} element={<AgentAccountSettings/>} title={t('ownerAccountSettings.title')} {...props}>
      <PrivateRoute path="/" element={<Navigate to={Private.AGENT_ACCOUNT_SETTINGS.route}/>} title={`${t('ownerAccountSettings.title')} - ${t('settings.billingData.billing.title')}`} {...props}/>
      <PrivateRoute path={Private.AGENT_ACCOUNT_SETTINGS.indent} element={<AgentAccountBillingSettings/>} title={`${t('ownerAccountSettings.title')} - ${t('settings.billingData.billing.title')}`} {...props}/>
    </PrivateRoute>

    <PrivateRoute path={Private.HOME.route} element={<Navigate to={Private.DASHBOARD.route}/>} {...props}/>
    <PrivateRoute path={Private.DASHBOARD.route} element={<Dashboard/>} title={t('asideNavigation.dashboard')} {...props}/>
    <PrivateRoute path="/dashboard/:workspace" element={<Dashboard/>} title={t('asideNavigation.dashboard')} {...props}/>

    <PrivateRoute path={Private.TASKS.route} element={<Tasks/>} title={t('asideNavigation.taskManager')} {...props}/>
    <PrivateRoute path={Private.TASKS_NEW.route} element={<TaskNewPage/>} title={t('tasks.newTask.title')} {...props}/>
    <PrivateRoute path={Private.TASKS_DETAIL.route} element={<TaskDetailPage/>} title={t('asideNavigation.tasks')} {...props}/>
    <PrivateRoute path={Private.TASKS_EDIT.route} element={<TaskEditPage/>} title={t('tasks.edit.title')} {...props}/>

    <PrivateRoute path="/facilities" element={<Facilities/>} {...props}/>
    <PrivateRoute path={Private.INCIDENTS.route} element={<Incidents/>} title={t('incidents.title')} {...props}/>
    <PrivateRoute path={Private.INCIDENTS_NEW.route} element={<IncidentsNew/>} title={t('incidents.new.title')} {...props}/>
    <PrivateRoute path="/incidents/:incidentId" element={<IncidentDetail/>} title={t('incidents.detail.title')} {...props}/>
    <PrivateRoute path="/bank-accounts" element={<BankAccounts/>} title={t('bankAccounts.title')} {...props}/>
    <PrivateRoute path={Private.AUTO_RECONCILIATIONS.route} element={<AutoReconciliations/>} title={`${t('bankAccounts.title')} - ${t('autoReconciliations.title')}`} {...props}/>
    <PrivateRoute path="/bookings" element={<Bookings/>} title={t('bookings.title')} {...props}/>
    <PrivateRoute path="/bookings/:bookingId" element={<BookingDetail/>} title={t('bookings.detail.title')} {...props}/>
    <PrivateRoute path="/organization-services" element={<OrganizationServices/>} title={t('organizationServices.title')} {...props}/>
    <PrivateRoute path="/staff" element={<Staff/>} {...props}/>
    <PrivateRoute path="/workspaces" element={<Workspaces/>} title={t('workspaces.title')} {...props}/>
    <PrivateRoute path="/buildings" element={<Buildings/>} title={t('buildings.title')} {...props}/>

    <PrivateRoute path={Private.BUILDINGS_EDIT.route} element={<BuildingSettings/>} {...props}/>
    <PrivateRoute path={Private.UNITS_DETAILS.route} element={<UnitDetail/>} {...props}/>
    <PrivateRoute path="/portfolio" element={<Portfolio/>} title={t('general.unit_plural')} {...props}>
      <PrivateRoute path={Private.UNITS.indent} element={<Units/>} title={t('general.unit_plural')} {...props}/>
      <PrivateRoute path="/workspace" element={<Workspaces/>} title={t('workspaces.title')} {...props}/>
      <PrivateRoute path="/buildings" element={<Buildings/>} title={t('buildings.title')} {...props}/>
      <PrivateRoute path="*" element={<Navigate to={Private.UNITS.route} replace={true}/>} title={t('general.unit_plural')} {...props}/>
    </PrivateRoute>

    <PrivateRoute path={Private.TENANTS_DETAILS.route} element={<TenantDetail/>} title={t('tenants.detail.title')} {...props}/>
    <PrivateRoute path={Private.OWNER_ACCOUNT_DETAILS.route} element={<OwnerAccountDetail/>} title={t('ownerAccount.detail.title')} {...props}/>
    <PrivateRoute path={Private.AGENT_DETAILS.route} element={<AgentDetail/>} title={t('agents.detail.title')} {...props}/>
    <PrivateRoute path={Private.ENTITY_DETAILS.route} element={<EntityDetail/>} title={t('ownerAccount.detail.title')} {...props}/>

    <PrivateRoute path="/owner-account-settings" element={<OwnerAccountSettings/>} title={t('ownerAccountSettings.title')} {...props}>
      <PrivateRoute path="/" element={<Navigate to={Private.OWNER_ACCOUNT_SETTINGS.route}/>} title={`${t('ownerAccountSettings.title')} - ${t('settings.billingData.billing.title')}`} {...props}/>
      <PrivateRoute path={Private.OWNER_ACCOUNT_SETTINGS.indent} element={<OwnerAccountBillingSettings/>} title={`${t('ownerAccountSettings.title')} - ${t('settings.billingData.billing.title')}`} {...props}/>
      <PrivateRoute path={Private.OWNER_ACCOUNT_SETTINGS_INTEGRATIONS.indent} element={<OwnerAccountIntegrationsSettings/>} title={`${t('settings.title')} - ${t('settings.tabs.integrations')}`} {...props}/>
    </PrivateRoute>
    <PrivateRoute path={Private.OWNER_LEAD_DETAILS.route} element={<OwnerLeadDetail/>} title={t('ownerLead.details.title')} {...props}/>

    <PrivateRoute path={Private.PROVIDER_DETAILS.route} element={<ProviderDetailPage/>} title={t('providers.common.details')} {...props}/>
    <PrivateRoute path="/people" element={<People/>} title={t('tenants.list.title')} {...props}>
      <PrivateRoute path="/" element={<Navigate to={Private.TENANTS.route}/>} title={t('tenants.list.title')} {...props}/>
      <PrivateRoute path={Private.TENANTS.indent} element={<Tenants/>} title={t('tenants.list.title')} {...props}/>
      <PrivateRoute path={Private.OWNER_ACCOUNTS.indent} element={<OwnerAccount/>} title={t('ownerAccount.title')} {...props}/>
      <PrivateRoute path={Private.ENTITIES.indent} element={<Entity/>} title={t('general.entities')} {...props}/>
      <PrivateRoute path={Private.AGENTS.indent} element={<Agent/>} title={t('agents.title')} {...props}/>
      <PrivateRoute path={Private.PROVIDERS.indent} element={<Provider/>} title={t('providers.title')} {...props}/>
    </PrivateRoute>

    <PrivateRoute path="/owner-services" element={<OwnerService/>} title={t('general.services')} {...props} >
      <PrivateRoute path="/" element={<Navigate to={Private.OWNER_SERVICES.route}/>} title={t('general.services')} {...props}/>
      <PrivateRoute path={Private.OWNER_SERVICES.indent} element={<OwnerServices/>} title={t('general.services')} {...props}/>
      <PrivateRoute path={Private.OWNER_BOOKINGS.indent} element={<OwnerBookings/>} title={t('general.bookings')} {...props}/>
    </PrivateRoute>

    <PrivateRoute path={Private.OWNER_BOOKING_DETAIL.route} element={<OwnerBookingDetail/>} title={t('ownerAccount.detail.title')} {...props}/>
    <PrivateRoute path={Private.OWNER_PRODUCT_DETAIL.route} element={<OwnerProductDetail/>} title={t('ownerAccount.detail.title')} {...props}/>

    <PrivateRoute path={Private.OWNER_TAXES.route} element={<OwnerTaxesPage/>} title={t('ownerTaxes.title')} {...props}/>

    <PrivateRoute path={Private.INVOICES.route} element={<Invoices/>} title={t('invoices.title')} withRoles={['admin', 'buildingManager', 'ownerBasic', 'ownerMiddle', 'ownerManager']} {...props}/>

    <PrivateRoute path={Private.NON_PAYMENTS.route} element={<NonPayments/>} title={t('nonPayment.title')} withRoles={['admin', 'buildingManager']} {...props}/>
    <PrivateRoute path={Private.NON_PAYMENTS_DETAIL.route} element={<NonPaymentDetail/>} title={t('nonPayment.title')} withRoles={['admin', 'buildingManager']} {...props}/>

    <PrivateRoute path={Private.ACCOUNTING.route} element={<Accounting/>} title={t('accounting.tabs.income')} {...props}>
      <PrivateRoute path="/" element={<Navigate to={Private.INCOME.route}/>} title={t('accounting.tabs.income')} {...props}/>
      <PrivateRoute path={Private.INCOME.indent} element={<Payments/>} title={t('accounting.tabs.income')} {...props}/>
      <PrivateRoute path={Private.EXPENSES.indent} element={<Expenses/>} title={t('accounting.tabs.expenses')} {...props}/>
      <PrivateRoute path={Private.PAYOUTS.indent} element={<Payouts/>} title={t('accounting.tabs.payouts')} {...props}/>
      <PrivateRoute path={Private.RENT_ADVANCE.indent} element={<RentAdvance/>} title={t('accounting.tabs.rentAdvance')} {...props}/>
    </PrivateRoute>
    <PrivateRoute path={Private.INCOME_DETAILS.route} element={<PaymentsDetail/>} title={t('payments.detail.title')} {...props}/>
    <PrivateRoute path={Private.INCOME_DIRECT_DEBIT_ORDERS.route} element={<PaymentsDirectDebitOrders/>} title={t('payments.directDebit.title')} {...props}>
      <PrivateRoute path={Private.INCOME_DIRECT_DEBIT_ORDERS.indent} element={<PendingPayments/>} title={t('payments.directDebit.pendingPayments.title')}/>
      <PrivateRoute path={Private.INCOME_DIRECT_DEBIT_ORDERS.exportsIndent} element={<PaymentsExports/>} title={t('payments.directDebit.exports.title')}/>
    </PrivateRoute>
    <PrivateRoute path={Private.EXPENSES_DETAILS.route} element={<ExpenseDetail/>} title={t('expenses.detail.title')} {...props}/>
    <PrivateRoute path={Private.PAYOUT_DETAILS.route} element={<PayoutDetail/>} title={t('payouts.detail.title')} {...props}/>
    <PrivateRoute path={Private.RECURRENT_INCOME.route} element={<RecurrentIncomes/>} title={`${t('payments.detail.title')} - ${t('recurrent.income.pageTitle')}`} {...props}/>
    <PrivateRoute path={Private.RECURRENT_INCOME_DETAILS.route} element={<RecurrentIncomeDetail/>} title={t('recurrent.income.detail.title')} {...props}/>
    <PrivateRoute path={Private.RECURRENT_EXPENSE.route} element={<RecurrentExpenses/>} title={`${t('expenses.detail.title')} - ${t('recurrent.expense.pageTitle')}`} {...props}/>
    <PrivateRoute path={Private.RECURRENT_EXPENSE_DETAILS.route} element={<RecurrentExpenseDetail/>} title={t('recurrent.expense.detail.title')} {...props}/>
    <PrivateRoute path={Private.RECURRENT_PAYOUT.route} element={<RecurrentPayouts/>} title={`${t('payouts.detail.title')} - ${t('recurrent.payout.pageTitle')}`} {...props}/>
    <PrivateRoute path={Private.RECURRENT_PAYOUT_DETAILS.route} element={<RecurrentPayoutDetail/>} title={t('recurrent.payout.detail.title')} {...props}/>
    <PrivateRoute path={Private.XML_EXPORTS.route} element={<XMLExports/>} title={t('xmlExports.title')} {...props}/>

    <PrivateRoute path={Private.TEMPLATES.route} element={<Templates/>} title={t('templates.title')} withRoles={['admin']} {...props}>
      <PrivateRoute path="/" element={<Navigate to={Private.DOCUMENT_TEMPLATES.route}/>} title={t('templates.title')} {...props}/>
      <PrivateRoute path={Private.DOCUMENT_TEMPLATES.indent} element={<DocumentTemplates/>} title={t('templates.title')} {...props}/>
      <PrivateRoute path={Private.TASKS_TEMPLATES.indent} element={<TasksTemplates/>} title={t('templates.title')} {...props}/>
    </PrivateRoute>

    <PrivateRoute path={Private.VALUATIONS.route} element={<Valuations/>} title={t('valuations.title')} withRoles={['admin', 'buildingManager', 'commercial', 'agent', 'ownerBasic', 'ownerManager','ownerManager']} {...props}/>
    <PrivateRoute path={Private.VALUATION_DETAILS.route} element={<ValuationDetails/>} title={t('valuations.details.title')} withRoles={['admin', 'buildingManager', 'commercial', 'agent', 'ownerBasic', 'ownerManager', 'ownerMiddle']} {...props}/>

    <PrivateRoute path={Private.NOTIFICATIONS.route} element={<Notifications/>} title={t('notifications.title')} {...props}/>
    <PrivateRoute path={Private.NOTIFICATIONS_DETAIL.route} element={<NotificationDetailPage/>} title={t('notifications.title')} {...props}/>
    <PrivateRoute path={Private.DRAFTS.route} element={<DraftsPage/>} title={t('drafts.title')} withRoles={['admin', 'buildingManager']} {...props}>
      <PrivateRoute path="/" element={<Navigate to={Private.DRAFTS_DOCUMENTS.route}/>} title={t('drafts.documents.title')} {...props}/>
      <PrivateRoute path={Private.DRAFTS_DOCUMENTS.indent} element={<DraftsDocumentsPage/>} title={t('drafts.documents.title')} {...props}/>
    </PrivateRoute>
    <PrivateRoute path={Private.DRAFTS_DOCUMENTS_DETAILS.route} element={<DraftsDocumentsPage/>} {...props}/>
    <PrivateRoute path={Private.CHECKOUT_SUCCESS.route} element={<CheckoutSuccessPage/>} title={t('checkout.title')} {...props}/>

    <PrivateRoute path={Private.CALENDAR.route} element={<Calendar/>} title={t('calendar.title')} {...props}/>
    <PrivateRoute path={Private.INVESTMENT.route} element={<Investment/>} title={t('investment.title')} {...props}/>
    <PrivateRoute path={Private.INVESTMENT_ALERTS.route} element={<InvestmentAlerts/>} title={t('investment.title')} {...props}/>
    <PrivateRoute path={Private.INVESTMENT_DETAIL.route} element={<InvestmentDetail/>} title={t('investment.title')} {...props}/>

    <PrivateRoute path={Private.INVITATIONS.route} element={<Invitations/>} title={t('invitations.title')} withRoles={['ownerManager', 'ownerBasic', 'ownerMiddle']} onlyDefaultOrganization {...props}/>

    <PrivateRoute path={Private.COPILOT.route} element={<Copilot/>} title="Zazume Copilot" withRoles={['admin', 'buildingManager', 'ownerBasic', 'ownerManager', 'ownerMiddle']} onlyDefaultOrganization {...props}/>
    <PrivateRoute path={Private.COPILOT.createChat} element={<NewCopilotConversation/>} title="Zazume Copilot" withRoles={['admin', 'buildingManager']} onlyDefaultOrganization {...props}/>

    <AdminRoute path="/admin" element={<Admin/>} {...props}/>
    <AdminRoute path="/admin/organization" element={<Admin/>} {...props}/>
    <AdminRoute path="/admin/tenants" element={<AdminTenants/>} {...props}/>
    <AdminRoute path="/admin/test" element={<TestPage/>} {...props}/>

    <Route path={Anonymous.LOGIN.route} element={<Navigate to={redirectPath || '/'}/>} {...props}/>
    <Route path={Anonymous.SINGLE_SIGN_ON_LOGIN.route} element={<Navigate to={redirectPath || '/'}/>} {...props}/>
  </BaseRoutes>;
};
