import { BaseRoute } from '#/containers/router/routes/types';
import { IdentifierDTO } from '@zazume/zzm-base';

const PrivateRoutesIdentifiers = <const>[
  'ACCOUNTING',
  'ADMIN',
  'AGENT_ACCOUNT_SETTINGS',
  'AGENT_DETAILS',
  'AGENTS',
  'APPLICATION_DETAIL',
  'APPLICATION',
  'ARCHIVED_APPLICATIONS',
  'ARCHIVED_DETAIL',
  'AUTO_RECONCILIATIONS',
  'AUTO_RECONCILIATIONS',
  'BANK_ACCOUNTS',
  'BOOKINGS',
  'BUILDINGS_EDIT',
  'BUILDINGS',
  'CALENDAR',
  'CHECKOUT_SUCCESS',
  'COPILOT',
  'DASHBOARD',
  'DOCUMENT_TEMPLATES',
  'DRAFTS_DOCUMENTS_DETAILS',
  'DRAFTS_DOCUMENTS',
  'DRAFTS',
  'ENTITIES',
  'ENTITY_DETAILS',
  'EXPENSES_DETAILS',
  'EXPENSES',
  'HELP',
  'HOME',
  'INCIDENT_DETAIL',
  'INCIDENTS_NEW',
  'INCIDENTS',
  'INCOME_DETAILS',
  'INCOME_DIRECT_DEBIT_ORDERS',
  'INCOME',
  'INVOICES',
  'INVITATIONS',
  'INVESTMENT',
  'INVESTMENT_ALERTS',
  'INVESTMENT_DETAIL',
  'NON_PAYMENTS',
  'NON_PAYMENTS_DETAIL',
  'NOTIFICATIONS_DETAIL',
  'NOTIFICATIONS',
  'ONBOARDING_DETAIL',
  'ONBOARDING',
  'ORGANIZATION_SERVICES',
  'OWNER_ACCOUNT_DETAILS',
  'OWNER_ACCOUNT_SETTINGS_INTEGRATIONS',
  'OWNER_ACCOUNT_SETTINGS',
  'OWNER_ACCOUNTS',
  'OWNER_BOOKING_DETAIL',
  'OWNER_BOOKINGS',
  'OWNER_LEAD_DETAILS',
  'OWNER_LEADS',
  'OWNER_PRODUCT_DETAIL',
  'OWNER_SERVICES',
  'OWNER_TAXES',
  'PAYOUT_DETAILS',
  'PAYOUTS',
  'PROFILE',
  'PROVIDER_DETAILS',
  'PROVIDERS',
  'RECURRENT_EXPENSE_DETAILS',
  'RECURRENT_EXPENSE',
  'RECURRENT_INCOME_DETAILS',
  'RECURRENT_INCOME',
  'RECURRENT_PAYOUT_DETAILS',
  'RECURRENT_PAYOUT',
  'SERVICES_EDIT',
  'SERVICES',
  'SETTINGS_ACCOUNTING',
  'SETTINGS_BILLING',
  'SETTINGS_INTEGRATIONS',
  'SETTINGS_LEASE',
  'SETTINGS',
  'TASKS_DETAIL',
  'TASKS_EDIT',
  'TASKS_NEW',
  'TASKS_TEMPLATES',
  'TASKS',
  'TEAM',
  'TEMPLATES',
  'TENANTS_DETAILS',
  'TENANTS',
  'UNITS_DETAILS',
  'UNITS',
  'VALUATION_DETAILS',
  'VALUATIONS',
  'WORKSPACES',
  'XML_EXPORTS',
  'RENT_ADVANCE'
];
type PrivateRoutesType = typeof PrivateRoutesIdentifiers[number];

export const Private = {
  ACCOUNTING: {
    route: '/accounting'
  },
  ADMIN: {
    route: '/admin'
  },
  AGENT_ACCOUNT_SETTINGS: {
    base: '/agent-account-settings',
    route: '/agent-account-settings/billing',
    indent: '/billing'
  },
  AGENTS: {
    route: '/people/agents',
    indent: '/agents'
  },
  AGENT_DETAILS: {
    route: '/people/agents/:agentId',
    to: (agentId: IdentifierDTO) => `/people/agents/${agentId}`
  },
  APPLICATION: {
    route: '/application'
  },
  APPLICATION_DETAIL: {
    route: '/application/:onboardingId',
    to: (applicationId: IdentifierDTO) => `/application/${applicationId}`,
    toEditConditions: (applicationId: IdentifierDTO) => `/application/${applicationId}?m=edit-conditions`,
    toEditDates: (applicationId: IdentifierDTO) => `/application/${applicationId}?m=edit-dates`
  },
  ARCHIVED_APPLICATIONS: {
    route: '/onboarding/archived'
  },
  ARCHIVED_DETAIL: {
    route: '/onboarding/archived/:archivedApplicationId',
    to: (applicationId: IdentifierDTO) => `/onboarding/archived/${applicationId}`
  },
  AUTO_RECONCILIATIONS: {
    route: '/bank-accounts/auto-reconciliations',
    indent: '/auto-reconciliations'
  },
  BANK_ACCOUNTS: {
    route: '/bank-accounts'
  },
  BOOKINGS: {
    route: '/bookings'
  },
  BUILDINGS: {
    route: '/portfolio/buildings'
  },
  BUILDINGS_EDIT: {
    route: '/portfolio/buildings/:buildingId',
    to: (buildingId: IdentifierDTO) => `/portfolio/buildings/${buildingId}`
  },
  CALENDAR: {
    route: '/calendar'
  },
  CHECKOUT_SUCCESS: {
    route: '/checkout/success'
  },
  COPILOT: {
    route: '/copilot',
    toChat: (chatId: IdentifierDTO) => `/copilot?chatId=${chatId}`,
    createChat: '/copilot/new'
  },
  DASHBOARD: {
    route: '/dashboard'
  },
  DOCUMENT_TEMPLATES: {
    route: '/templates/document',
    indent: '/document'
  },
  DRAFTS: {
    route: '/drafts'
  },
  DRAFTS_DOCUMENTS: {
    route: '/drafts/documents',
    indent: '/documents',
    to: (documentId: IdentifierDTO) => `/drafts/documents/${documentId}`
  },
  DRAFTS_DOCUMENTS_DETAILS: {
    route: '/drafts/documents/:documentId',
    to: (documentId: IdentifierDTO) => `/drafts/documents/${documentId}?m=edit-draft-document`
  },
  ENTITIES: {
    route: '/people/entities',
    indent: '/entities'
  },
  ENTITY_DETAILS: {
    route: '/people/entities/:entityId',
    to: (entityId: IdentifierDTO) => `/people/entities/${entityId}`
  },
  EXPENSES: {
    route: '/accounting/expenses',
    indent: '/expenses'
  },
  EXPENSES_DETAILS: {
    route: '/accounting/expenses/:expenseId',
    to: (expenseId: IdentifierDTO) => `/accounting/expenses/${expenseId}`
  },
  HELP: {
    route: '/help'
  },
  HOME: {
    route: '/'
  },
  INCIDENTS: {
    route: '/incidents'
  },
  INCIDENTS_NEW: {
    route: '/incidents/new',
    addToUnit: (unitId: IdentifierDTO) => `/incidents/new?m=add-incident&u=${unitId}`,
    add: '/incidents/new?m=add-incident'
  },
  INCIDENT_DETAIL: {
    route: '/incidents/:incidentId',
    to: (incidentId: IdentifierDTO) => `/incidents/${incidentId}`
  },
  INCOME: {
    route: '/accounting/income',
    indent: '/income'
  },
  INCOME_DETAILS: {
    route: '/accounting/income/:paymentId',
    to: (paymentId: IdentifierDTO) => `/accounting/income/${paymentId}`
  },
  INCOME_DIRECT_DEBIT_ORDERS: {
    exports: '/accounting/income/direct-debit/exports',
    exportsIndent: '/exports',
    indent: '/',
    route: '/accounting/income/direct-debit'
  },
  INVESTMENT: {
    route: '/investment'
  },
  INVESTMENT_ALERTS: {
    route: '/investment/alerts'
  },
  INVESTMENT_DETAIL: {
    route: '/investment/:id',
    to: (id: IdentifierDTO) => `/investment/${id}`
  },
  INVOICES: {
    route: '/invoices'
  },
  INVITATIONS: {
    route: '/invitations'
  },
  NON_PAYMENTS: {
    route: '/non-payments'
  },
  NON_PAYMENTS_DETAIL: {
    route: '/non-payments/:nonPaymentId',
    to: (nonPaymentId: IdentifierDTO) => `/non-payments/${nonPaymentId}`
  },
  NOTIFICATIONS: {
    route: '/notifications',
    toPage: (pageNumber: number) => `/notifications?page=${pageNumber}`
  },
  NOTIFICATIONS_DETAIL: {
    route: '/notifications/:notificationId',
    to: (notificationId: IdentifierDTO) => `/notifications/${notificationId}`
  },
  /**
   * @deprecated use Application
   */
  ONBOARDING: {
    route: '/onboarding'
  },
  /**
   * @deprecated use Application details
   */
  ONBOARDING_DETAIL: {
    route: '/onboarding/:onboardingId',
    to: (onboardingId: IdentifierDTO) => `/onboarding/${onboardingId}`
  },
  ORGANIZATION_SERVICES: {
    route: '/organization-services'
  },
  OWNER_ACCOUNTS: {
    route: '/people/owners',
    indent: '/owners',
    toCreate: '/people/owners?m=create'
  },
  OWNER_ACCOUNT_DETAILS: {
    route: '/people/owners/:ownerAccountId',
    to: (ownerAccountId: IdentifierDTO) => `/people/owners/${ownerAccountId}`
  },
  OWNER_ACCOUNT_SETTINGS: {
    route: '/owner-account-settings/billing',
    indent: '/billing'
  },
  OWNER_ACCOUNT_SETTINGS_INTEGRATIONS: {
    route: '/owner-account-settings/integrations',
    indent: '/integrations'
  },
  OWNER_BOOKINGS: {
    route: '/owner-services/bookings',
    indent: '/bookings'
  },
  OWNER_BOOKING_DETAIL: {
    route: '/owner-services/bookings/:bookingId',
    to: (bookingId: IdentifierDTO) => `/owner-services/bookings/${bookingId}`
  },
  OWNER_LEADS: {
    route: '/people/owner-leads',
    indent: '/owner-leads'
  },
  OWNER_LEAD_DETAILS: {
    route: '/people/owner-leads/:ownerLeadId',
    to: (ownerLeadId: IdentifierDTO) => `/people/owner-leads/${ownerLeadId}`
  },
  OWNER_PRODUCT_DETAIL: {
    route: '/owner-services/products/:productId',
    to: (productId: IdentifierDTO) => `/owner-services/products/${productId}`
  },
  OWNER_SERVICES: {
    route: '/owner-services/services',
    indent: '/services'
  },
  OWNER_TAXES: {
    route: '/taxes',
    toStatement: (unitId: IdentifierDTO, year: string) => `/taxes?unitId=${unitId}&year=${year}`
  },
  PAYOUTS: {
    route: '/accounting/payouts',
    indent: '/payouts'
  },
  PAYOUT_DETAILS: {
    route: '/accounting/payouts/:payoutId',
    to: (payoutId: IdentifierDTO) => `/accounting/payouts/${payoutId}`
  },
  PROFILE: {
    route: '/profile'
  },
  PROVIDERS: {
    route: '/people/providers',
    indent: '/providers'
  },
  PROVIDER_DETAILS: {
    route: '/people/providers/:providerId',
    to: (providerId: IdentifierDTO) => `/people/providers/${providerId}`
  },
  RECURRENT_EXPENSE: {
    route: '/accounting/recurrent-expense',
    indent: '/recurrent-expense'
  },
  RECURRENT_EXPENSE_DETAILS: {
    route: '/accounting/recurrent-expense/:recurrentExpenseId',
    to: (recurrentExpenseId: IdentifierDTO) => `/accounting/recurrent-expense/${recurrentExpenseId}`
  },
  RECURRENT_INCOME: {
    route: '/accounting/recurrent-income',
    indent: '/recurrent-income'
  },
  RECURRENT_INCOME_DETAILS: {
    route: '/accounting/recurrent-income/:recurrentIncomeId',
    to: (recurrentIncomeId: IdentifierDTO) => `/accounting/recurrent-income/${recurrentIncomeId}`
  },
  RECURRENT_PAYOUT: {
    route: '/accounting/recurrent-payouts',
    indent: '/recurrent-payouts'
  },
  RECURRENT_PAYOUT_DETAILS: {
    route: '/accounting/recurrent-payouts/:recurrentPayoutId',
    to: (recurrentPayoutId: IdentifierDTO) => `/accounting/recurrent-payouts/${recurrentPayoutId}`
  },
  SERVICES: {
    route: '/services'
  },
  SERVICES_EDIT: {
    route: '/services/edit/:serviceId',
    to: (serviceId: IdentifierDTO) => `/services/edit/${serviceId}`
  },
  SETTINGS: {
    route: '/settings/general',
    indent: '/general'
  },
  SETTINGS_ACCOUNTING: {
    route: '/settings/accounting',
    indent: '/accounting'
  },
  SETTINGS_BILLING: {
    route: '/settings/billing',
    indent: '/billing'
  },
  SETTINGS_INTEGRATIONS: {
    route: '/settings/integrations',
    indent: '/integrations'
  },
  SETTINGS_LEASE: {
    route: '/settings/lease',
    indent: '/lease'
  },
  TASKS: {
    allOpen: '/tasks?status=open&staffId=all',
    route: '/tasks',
    toCreate: '/tasks/new',
    toCreateWithIncident: (incidentId: IdentifierDTO) => `/tasks/new?incident=${incidentId}`,
    toCreateWithTemplate: (templateId: IdentifierDTO) => `/tasks/new?template=${templateId}`,
    withClosedFilter: '/tasks?status=closed&staffId=all'
  },
  TASKS_NEW: {
    route: '/tasks/new'
  },
  TASKS_DETAIL: {
    route: '/tasks/:taskId',
    to: (taskId: IdentifierDTO) => `/tasks/${taskId}`
  },
  TASKS_EDIT: {
    route: '/tasks/edit/:taskId',
    to: (taskId: IdentifierDTO) => `/tasks/edit/${taskId}`
  },
  TASKS_TEMPLATES: {
    route: '/templates/tasks',
    indent: '/tasks'
  },
  TEAM: {
    route: '/team'
  },
  TEMPLATES: {
    route: '/templates'
  },
  TENANTS: {
    route: '/people/tenants',
    indent: '/tenants'
  },
  TENANTS_DETAILS: {
    route: '/people/tenants/:tenantId',
    to: (tenantId: IdentifierDTO) => `/people/tenants/${tenantId}`
  },
  UNITS: {
    route: '/portfolio/units',
    indent: '/units',
    toCreate: '/portfolio/units?m=create-unit'
  },
  UNITS_DETAILS: {
    route: '/portfolio/units/:unitId',
    to: (unitId: IdentifierDTO) => `/portfolio/units/${unitId}`,
    toPeopleTab: (unitId: IdentifierDTO) => `/portfolio/units/${unitId}?tab=people`,
    toLeasesTab: (unitId: IdentifierDTO) => `/portfolio/units/${unitId}?tab=Lease`,
    toCreateAvailabilityOwnerPortals: (unitId: IdentifierDTO) => `/portfolio/units/${unitId}?tab=ownerPortals&m=create-availability`,
    toEdit: (unitId: IdentifierDTO) => `/portfolio/units/${unitId}?m=edit-unit`,
    toEditPrequalification: (unitId: IdentifierDTO) => `/portfolio/units/${unitId}?m=edit-prequalification`,
    toUploadPhotos: (unitId: IdentifierDTO) => `/portfolio/units/${unitId}?tab=about&m=upload-unit-photos`
  },
  VALUATIONS: {
    route: '/valuations'
  },
  VALUATION_DETAILS: {
    route: '/valuations/:valuationId',
    to: (valuationId: IdentifierDTO) => `/valuations/${valuationId}`
  },
  WORKSPACES: {
    route: '/portfolio/workspace'
  },
  XML_EXPORTS: {
    route: '/accounting/xml-exports',
    indent: '/xml-exports'
  },
  RENT_ADVANCE: {
    route: '/accounting/rent-advance',
    indent: '/rent-advance'
  }
} satisfies Record<PrivateRoutesType, BaseRoute & any>;
