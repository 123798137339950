import { useI18n } from '#hooks/useI18n';
import React, { FC } from 'react';
import { Avatar } from '../../components/avatar/Avatar';
import styled from '@emotion/styled';
import { useAuth, useIsDefaultOrganization } from '../../contexts/AuthProvider';
import { Dropdown, Regular, useModal } from '@zazume/zzm-base';
import { API } from '../../lib/network/API';
import { useBrand } from '../../hooks/useBrand';
import { Private } from '../router/routes/Private';
import { isLocalEnvironment } from '../../lib/Config';
import { ProfileModal } from './components/profile/ProfileModal';

const Header = styled(Avatar)`
  margin-left: 2px;
  cursor: pointer;
`;


export const ProfileNavigation: FC = () => {
  const { t } = useI18n();
  const { open: openModal, RenderModal, close } = useModal({ modalSize: 's' });
  const { user, logout, refetch, organization, isOwner } = useAuth();
  const isDefaultOrganization = useIsDefaultOrganization();
  const { brand } = useBrand();

  if (!user || !organization){
    return null;
  }

  const header = <Header user={user}/>;

  const getVersion = () => {
    return <Regular variant="XS" color="Gray600">{`${t('profile.version')} ${import.meta.env.REACT_APP_VERSION}`}</Regular>;
  };

  const onUpdate = () => {
    refetch();
  };

  const openStripe = async () => {
    const session = await API.checkout.getPortalSession(window.location.href);
    window.location.href = session.url;
  };

  const test = async () => {
    const stripePriceId = 'price_1N8s9VG9NH1yPruxmNuCtxJy';
    const returnUrl = `${brand!.dashboardURL}${Private.CHECKOUT_SUCCESS.route}`;
    const session = await API.checkout.getCheckoutSession(stripePriceId, returnUrl, window.location.href, '');
    localStorage.setItem('stripeCheckoutSessionId', session.id);
    window.location.href = session.url;
  };

  return <>
    <Dropdown data-id="profile-menu" header={header} position="right" topValue={60} rightValue={20}>
      <Dropdown.Item onClick={()=>openModal()}>
        {t('profile.title')}
      </Dropdown.Item>
      {(isOwner && isDefaultOrganization) && <Dropdown.Item onClick={() => openStripe()}>
        {t('profile.cardPayments')}
      </Dropdown.Item>}
      {(isOwner && isDefaultOrganization && isLocalEnvironment) && <Dropdown.Item onClick={() => test()}>
        {'TEST'}
      </Dropdown.Item>}
      <Dropdown.Item onClick={() => logout()}>
        {t('profile.logout')}
      </Dropdown.Item>
      <Dropdown.Item>{getVersion()}</Dropdown.Item>
    </Dropdown>
    <RenderModal>
      <ProfileModal user={user} onUpdate={onUpdate} close={close}/>
    </RenderModal>
  </>;
};
