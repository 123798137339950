import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';
import { Private } from '#/containers/router/routes/Private';
import { CanInDefaultOrganization } from '#/components/roles/CanInDefaultOrganization';
import { getEnvironment } from '#lib/Config';
import { UserRolesTypes } from '#/models';

interface Props extends SectionProps {
}

export const Investment: FC<Props> = ({ onClickItem, isMobile }) => {
  const { t } = useI18n();
  const roles: UserRolesTypes[] = ['buildingManager', 'admin'];
  //TODO: Change when it's tested in staging
  if ('stage' === getEnvironment()) {
    roles.push('ownerManager');
  }
  return <CanInDefaultOrganization>
    <Can hasRoles={roles}>
      <NavigationLink to={Private.INVESTMENT.route} icon="wallet" title={t('asideNavigation.investment')} onClick={onClickItem} isMobile={isMobile}/>
    </Can>
  </CanInDefaultOrganization>;
};
