import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { DEFAULT_STARTING_PAGE } from '.';
import { getFullUrl } from '../utils/stringsHelper';

interface UseURLParamsResponse {
  getParam: (key: string) => string | null;
  setParam: (param: string, value: string) => void;
  deleteParam: (key: string) => void;
  setMultipleParams: (params: ParamsObject) => void;
  deleteMultipleParams: (keys: string[]) => void;
  redirectPath: string | null;
}

interface ParamsObject {
  [key: string]: string | undefined
}

export const useURLParams = (): UseURLParamsResponse => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const currentURLParams = useMemo(() => new URLSearchParams(search), [search]);

  const getParam = (key: string) =>
    currentURLParams.get(key);

  const resetPaginationParam = (key: string) => {
    if (key !== 'page' && getParam('page')) {
      currentURLParams.set('page', DEFAULT_STARTING_PAGE.toString());
    }
  };

  const deleteParam = (key: string) => {
    resetPaginationParam(key);
    currentURLParams.delete(key);
    const newURL = getFullUrl(pathname, currentURLParams.toString());
    navigate(newURL, { replace: true });
  };

  const deleteMultipleParams = (keys: string[]) => {
    keys.forEach((key) => currentURLParams.delete(key));
    const newURL = getFullUrl(pathname, currentURLParams.toString());
    navigate(newURL, { replace: true });
  };

  const setParam = (key: string, value: string) => {
    resetPaginationParam(key);
    currentURLParams.set(key, value);
    const newURL = getFullUrl(pathname, currentURLParams.toString());
    navigate(newURL, { replace: true });
  };

  const setMultipleParams = (params: ParamsObject) => {
    const keys = Object.keys(params);

    if (!keys.includes('page') && getParam('page')) {
      currentURLParams.set('page', DEFAULT_STARTING_PAGE.toString());
    }

    keys.forEach(key => {
      const value = params[key];
      value
        ? currentURLParams.set(key, value)
        : currentURLParams.delete(key);
    });

    const newURL = getFullUrl(pathname, currentURLParams.toString());
    navigate(newURL, { replace: true });
  };

  const redirectPath = getParam('redirect')
    ? search.replace('?redirect=', '')
    : null;

  return {
    getParam,
    setParam,
    setMultipleParams,
    deleteParam,
    deleteMultipleParams,
    redirectPath
  };
};
