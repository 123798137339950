import React from 'react';
import styled from '@emotion/styled';
import { FCC, ResponsiveFilters } from '@zazume/zzm-base';

const Container = styled.div`
  flex-grow: 1;
  min-width: 2.625rem;
  overflow-y: visible;
`;

interface FiltersContainerProps {
  //TODO Remove conditional, make it mandatory
  title?: string;
}

export const FiltersContainer: FCC<FiltersContainerProps> = ({ title = '', children, ...props }) => {
  return <Container {...props}>
    <ResponsiveFilters title={title}>
      {children}
    </ResponsiveFilters>
  </Container>;
};
