import { Unit } from '#/models';
import { useSession } from '#hooks/session/useSession';
import { useGet } from '#/hooks';
import { ActiveProductTypeBooking } from '#lib/network/api/bookings/models/ActiveProductTypeBooking';
import { InvestmentOpportunityKeys } from '#hooks/api/investmentOpportunities/InvestmentOpportunities';
import { API } from '#lib/network/API';

export const usePurchase = () => {
  const { isOwner, isAgent } = useSession();
  const { data, isLoading } = useGet<ActiveProductTypeBooking>(InvestmentOpportunityKeys.activeBooking(), API.bookings.findActiveProductType('real_estate_opportunities'));
  const canPurchase = (isOwner || isAgent) && data && !data.isActive;

  return {
    canPurchase
  };
};
