import { Bold, Flex, Regular, Semibold, ZazumeModal, Lock, Divider, CheckboxBase, IdentifierDTO } from '@zazume/zzm-base';
import { Trans } from 'react-i18next';
import { currencySymbols } from '@zazume/zzm-utils';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useI18n } from '#hooks/useI18n';
import { useStripe } from '#/hooks';
import { useNetworkExceptions } from '#lib/network/helpers';
import { I18NValues } from '#lib/i18n/generatedKeys';
import { BenefitsList } from '#/pages/workspaces/units/components/contractTenantSearchPlanModal/steps/components/BenefitsList';
import { Config } from '#lib/Config';

const SecureTag = styled.div(({ theme }) => css`
  display: flex;
  gap: ${theme.spacing.xs};
  padding: ${theme.spacing.s2};
  background-color: ${theme.colors.PrimaryLight4};
  border-radius: ${theme.radius.md};
`);

interface PurchaseInvestmentProductProps {
  onClose: () => void;
}

export const PurchaseInvestmentProduct = ({ onClose }: PurchaseInvestmentProductProps) => {
  const { t } = useI18n();
  const { goToPayment, isLoading } = useStripe();
  const { handleNetworkExceptions } = useNetworkExceptions();

  const { register, watch } = useForm();
  const acceptConditions = watch('acceptConditions');

  const back = () => {
    onClose();
  };

  const onNext = async () => {
    const metadata = { unitId: Config.UNIT_ID_WILDCARD, description: 'Zazume' };
    await goToPayment(Config.PRODUCTS_EXTERNAL_ID.REAL_ESTATE_OPPORTUNITIES_PRICE_ID, metadata);
    return;
  };

  const price = '75';
  const selectedPlan = 'real_estate_opportunities';
  const serviceName = t(`zazumeServices.${selectedPlan}.name` as I18NValues);
  const currency = currencySymbols.eur.symbol;

  const benefits = t(`zazumeServices.${selectedPlan}.benefits` as I18NValues, { returnObjects: true });
  const serviceConditions = t(`zazumeServices.${selectedPlan}.conditions` as I18NValues, { returnObjects: true }) as any as string[];

  return (
    <ZazumeModal title={t(`zazumeServices.${selectedPlan}.title`)}>
      <Flex direction="column" gap="lg">
        <Semibold variant="L" color="Gray600">{t('zazumeServices.contractPlanModal.acceptConditions.serviceName', { service: serviceName })}</Semibold>
        <Flex direction="column" gap="xs">
          <div>
            <Trans
              i18nKey="zazumeServices.real_estate_opportunities.price"
              components={{ 1: <Bold variant="XXXXL" as="span" color="Primary"/>, 2: <Regular color="Primary" as="span" variant="S"/> }}
              values={{ price: price + currency }}
            />
          </div>
          <SecureTag>
            <Lock color="Primary" size={16}/>
            <Semibold color="Primary" variant="S">{t('zazumeServices.contractPlanModal.acceptConditions.secureTransaction')}</Semibold>
          </SecureTag>
          <BenefitsList
            benefits={benefits as any as string[]}
            title={<Trans
              i18nKey="zazumeServices.contractPlanModal.acceptConditions.serviceIncludes"
              components={{ 1: <Regular color="Gray500" as="span" variant="S"/>, 2: <Bold variant="S" as="span" color="Gray500"/> }}
              values={{ service: serviceName }}
            />}
          />
          <Divider/>
          <Flex direction="column" gap="s3">
            <Bold color="Gray500">{t('zazumeServices.contractPlanModal.acceptConditions.serviceConditions')}</Bold>
            {serviceConditions.map(condition => <Regular color="Gray500" variant="S">{condition}</Regular>)}
          </Flex>
        </Flex>
        <CheckboxBase type="checkbox" name="acceptConditions" ref={register}>
          <Regular variant="S" color="Gray500">{t('zazumeServices.contractPlanModal.acceptConditions.acceptConditions')}</Regular>
        </CheckboxBase>
      </Flex>
      <ZazumeModal.Footer
        cancelButtonText={t('buttons.back')}
        acceptButtonText={t('buttons.pay')}
        onCancel={back}
        acceptButtonType="button"
        isAcceptDisabled={!acceptConditions || isLoading}
        isSubmitting={isLoading}
        onConfirm={onNext}
      />
    </ZazumeModal>
  );
};
